<pi-card-wrapper #cardWrapper [instance]="instance" [isAskAiVisible]="false" [isReadonly]="true" [isToggleEnabled]="isFeedbackActionsSectionAvailable" [suggestion]="suggestion" borderStyle="solid">
  <div pi-feedback-card-content>
    <ng-content></ng-content>
  </div>

  <pi-card-action-bar
    [goal]="parent"
    [instance]="instance"
    [isFeedbackActionsSectionAvailable]="isFeedbackActionsSectionAvailable"
    [suggestion]="suggestion"
    [suggestionFeedbackSubType]="suggestionFeedbackSubType"
    [suggestionFeedbackType]="suggestionFeedbackType">
    <pi-suggestion-action-bar-main-actions
      [instance]="instance"
      [isAddBtnAvailable]="false"
      [isCopyBtnAvailable]="false"
      [isEditBtnAvailable]="false"
      [isImproveBtnAvailable]="false"
      [isRefreshBtnAvailable]="false"
      [isRemoveBtnAvailable]="false"
      [suggestion]="suggestion"
      pi-action-bar-main-actions>
    </pi-suggestion-action-bar-main-actions>
  </pi-card-action-bar>
</pi-card-wrapper>
