<div class="metric-progress-bar" [attr.aria-label]="progressLabel">
  <ui-progress *ngIf="!uiHideBar" [uiPercent]="percent" [uiShowInfo]="false" [uiSize]="'small'" [uiStrokeColor]="color" [uiStrokeWidth]="5"></ui-progress>

  <div class="progress-label sp-ml-2" *ngIf="!uiHideProgress">
    <span class="progress-value">{{ progressToShow | number }}</span
    >%
  </div>
</div>

<div
  class="delta sp-ml-2"
  *ngIf="!uiHideDelta"
  [attr.aria-label]="deltaLabel"
  [ngClass]="{ positive: delta >= 0.005, negative: delta <= -0.005 }"
  [uiTooltipOrigin]="deltaElement.elementRef"
  [uiTooltipTitle]="deltaPopoverContent"
  ui-tooltip>
  <div #deltaElement="nzElement" nz-element>{{ deltaSignToShow }}{{ deltaValueToShow | number }}%</div>
  <i ui-icon uiType="up"></i>
</div>

<ng-template #deltaPopoverContent>
  <span class="tooltip-content">{{ "last 14 days" | localize }}</span>
</ng-template>
