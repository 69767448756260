export const setNavWidth = (sizePx: { expanded: string; collapsed: string }): void => {
  document.body.style.setProperty("--left-nav-width", sizePx.expanded);
  document.body.style.setProperty("--left-nav-hidden-width", sizePx.collapsed);
};

export function getSessionAndOkrViewState(isOkrGridEnabled: boolean): { session: string; okrView: string } {
  return {
    session: isOkrGridEnabled ? "gtmhub.goals.grid" : "gtmhub.goals.all",
    okrView: isOkrGridEnabled ? "gtmhub.okrView.grid" : "gtmhub.okrView.all",
  };
}
