<ui-tree-indent
  [treeSize]="treeSize"
  [uiCheckable]="nzCheckable"
  [uiIsEnd]="isEnd"
  [uiIsStart]="isStart"
  [uiSelectMode]="nzSelectMode"
  [uiTreeLevel]="nzTreeNode.level - uiOffset < 0 ? 0 : nzTreeNode.level - uiOffset"
  aria-hidden="true"></ui-tree-indent>
<ui-tree-node-switcher
  *ngIf="nzShowExpand"
  [context]="nzTreeNode"
  [isExpanded]="isExpanded"
  [isLeaf]="isLeaf"
  [isLoading]="isLoading"
  [treeSize]="treeSize"
  [uiExpandedIcon]="nzExpandedIcon"
  [uiSelectMode]="nzSelectMode"
  [uiShowExpand]="nzShowExpand"
  [uiShowLine]="nzShowLine"
  (click)="clickExpand($event)"></ui-tree-node-switcher>
<ui-tree-node-checkbox
  *ngIf="nzCheckable"
  [isChecked]="isChecked"
  [isDisableCheckbox]="isDisableCheckbox"
  [isDisabled]="isDisabled"
  [isHalfChecked]="isHalfChecked"
  [treeSize]="treeSize"
  [uiSelectMode]="nzSelectMode"
  (checkChange)="clickCheckBox($event)"
  (click)="clickCheckBox($event)"
  (keydown.enter)="clickCheckBox($event)"
  builtin></ui-tree-node-checkbox>
<ui-tree-node-title
  [context]="nzTreeNode"
  [draggable]="nzDraggable"
  [dragPosition]="dragPos"
  [icon]="icon"
  [isDisabled]="isDisabled"
  [isExpanded]="isExpanded"
  [isLeaf]="isLeaf"
  [isLoading]="isLoading"
  [isMatched]="isMatched"
  [isSelected]="isSelected"
  [searchValue]="nzSearchValue"
  [selectMode]="nzSelectMode"
  [showIcon]="nzShowIcon"
  [showIndicator]="showIndicator"
  [title]="title"
  [treeSize]="treeSize"
  [treeTemplate]="nzTreeTemplate"
  (click)="clickSelect($event)"
  (contextmenu)="contextMenu($event)"
  (dblclick)="dblClick($event)"></ui-tree-node-title>
