<div class="sp-mb-16">
  <ui-radio-group [(ngModel)]="selectedOption">
    <div class="sp-mb-8 sp-mr-0 sp-px-8 sp-py-12 radio-item-wrapper" [class.selected-item]="selectedOption === 'original'" [hasCustomRadioContent]="true" ui-radio uiValue="original">
      <div class="sp-ml-8 radio-button-content">
        <span class="fs-16px radio-button-label">{{ "original_suggestion" | localize }}</span>
        <div class="sp-py-6 sp-px-10 subcard-wrapper">
          <pi-text-subcard [isIconAvailable]="isCardsIconAvailable" [textToDisplay]="suggestion.text" fontColor="dark" pi-subcard
            ><pi-card-icon *ngIf="instance.isInline" iconTheme="outline" iconType="platform-intelligence-default"></pi-card-icon
            ><pi-card-icon *ngIf="!instance.isInline" [iconTheme]="instance.iconTheme" [iconType]="instance.iconType"></pi-card-icon
          ></pi-text-subcard>
        </div>
      </div>
    </div>

    <div
      class="sp-mb-8 sp-mr-0 sp-px-8 sp-py-12 radio-item-wrapper"
      [class.selected-item]="selectedOption === 'updated'"
      [hasCustomRadioContent]="true"
      [uiDisabled]="errorData?.isMaliciousInstructionError"
      ui-radio
      uiValue="updated">
      <div class="sp-ml-8 radio-button-content">
        <span class="fs-16px radio-button-label">{{ "new_suggestion" | localize }}</span>
        <div class="sp-py-6 sp-px-10 subcard-wrapper" *ngIf="!isLoading && !isError">
          <pi-text-subcard [isIconAvailable]="isCardsIconAvailable" [textToDisplay]="improvedSuggestion" fontColor="dark" pi-subcard
            ><pi-card-icon *ngIf="instance.isInline" iconTheme="outline" iconType="platform-intelligence-default"></pi-card-icon
            ><pi-card-icon *ngIf="!instance.isInline" [iconTheme]="instance.iconTheme" [iconType]="instance.iconType"></pi-card-icon
          ></pi-text-subcard>
        </div>

        <ng-container *ngIf="isLoading">
          <pi-loading-card-v2 *ngIf="instance.isInline"></pi-loading-card-v2>

          <pi-loading-card-v2
            *ngIf="!instance.isInline"
            [iconTheme]="instance.iconTheme"
            [iconType]="instance.iconType"
            [isCustomIcon]="true"
            [isIconAvailable]="isCardsIconAvailable"></pi-loading-card-v2>
        </ng-container>

        <pi-clickable-error-card
          *ngIf="isError"
          [errorData]="errorData"
          [isRemoveBtnAvailable]="false"
          [isToggleEnabled]="!errorData.isMaliciousInstructionError"
          [suggestion]="this.suggestion"
          (errorRefresh)="getImprovedSuggestion()">
        </pi-clickable-error-card>
      </div>
    </div>

    <div class="sp-m-0 sp-px-8 sp-py-12 radio-item-wrapper" [class.selected-item]="selectedOption === 'none'" [hasCustomRadioContent]="true" ui-radio uiValue="none">
      <div class="sp-ml-8 radio-button-content">
        <span class="fs-16px radio-button-label">{{ "neither_suggestion_is_good" | localize }}</span>
      </div>
    </div>
  </ui-radio-group>
</div>
