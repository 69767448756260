<span class="ant-input-number-wrapper ant-input-number-group" *ngIf="isAddOn; else noAddOnTemplate">
  <div
    class="add-on-before"
    *ngIf="nzAddOnBefore || nzAddOnBeforeIcon"
    [ngStyle]="uiAddonStyle"
    [uiIcon]="nzAddOnBeforeIcon"
    [uiTemplate]="nzAddOnBefore"
    type="addon"
    ui-input-number-group-slot></div>
  <div
    class="ant-input-number-affix-wrapper"
    *ngIf="isAffix; else contentTemplate"
    [class.ant-input-number-affix-wrapper-disabled]="uiDisabled"
    [class.ant-input-number-affix-wrapper-focused]="focused"
    [class.ant-input-number-affix-wrapper-lg]="isLarge"
    [class.ant-input-number-affix-wrapper-readonly]="uiReadOnly"
    [class.ant-input-number-affix-wrapper-sm]="isSmall"
    [ngClass]="affixInGroupStatusCls">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </div>
  <span class="add-on-after" *ngIf="nzAddOnAfter || nzAddOnAfterIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnAfterIcon" [uiTemplate]="nzAddOnAfter" type="addon" ui-input-number-group-slot></span>
</span>
<ng-template #noAddOnTemplate>
  <ng-template [ngIf]="isAffix" [ngIfElse]="contentTemplate">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </ng-template>
</ng-template>
<ng-template #affixTemplate>
  <span *ngIf="nzPrefix || nzPrefixIcon" [uiIcon]="nzPrefixIcon" [uiTemplate]="nzPrefix" type="prefix" ui-input-number-group-slot></span>
  <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
  <span *ngIf="nzSuffix || nzSuffixIcon" [uiIcon]="nzSuffixIcon" [uiTemplate]="nzSuffix" type="suffix" ui-input-number-group-slot> </span>
</ng-template>
<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
