<pi-suggestion-drawer-create-form-modal-wrapper [isOpen]="isOpen">
  <pi-suggestion-drawer-base [description]="'custom_ai_suggestions_for_your_kr' | localize" [isOpen]="isOpen" (visibilityChange)="visibilityChange($event)">
    <suggestion-drawer-suggestion-group-card
      class="sp-mb-16"
      [flowId]="flowId"
      [flowName]="flowName"
      [groupTitle]="'title' | localize"
      [suggestionGroup]="titleSection"
      (goBackToSuggestion)="titleSection.goBackToLastAvailableSuggestion()"
      (retry)="titleSection.onRetry()"
      (suggest)="titleSection.onSuggestAnother()"
      (suggestionAccept)="titleSection.onAcceptSuggestion($event)"
      errorCardDataTestId="title-suggestion-error-card"
      iconTheme="fill"
      iconType="metric"
      noSuggestionReasonCardDataTestId="no-title-suggestion-reason-card"
      parentType="metric"
      subType="createMetricTitle"
      suggestionCardDataTestId="title-suggestion-text-card"
      suggestionFeedbackSubType="title"
      suggestionFeedbackType="KR">
    </suggestion-drawer-suggestion-group-card>
    <suggestion-drawer-suggestion-group-card
      [flowId]="flowId"
      [flowName]="flowName"
      [groupTitle]="'description' | localize"
      [suggestionGroup]="descriptionSection"
      (goBackToSuggestion)="descriptionSection.goBackToLastAvailableSuggestion()"
      (retry)="descriptionSection.onRetry()"
      (suggest)="descriptionSection.onSuggestAnother()"
      (suggestionAccept)="descriptionSection.onAcceptSuggestion($event)"
      errorCardDataTestId="description-suggestion-error-card"
      noSuggestionReasonCardDataTestId="no-description-suggestion-reason-card"
      parentType="metric"
      subType="createMetricDescription"
      suggestionCardDataTestId="description-suggestion-text-card"
      suggestionFeedbackSubType="description"
      suggestionFeedbackType="KR">
    </suggestion-drawer-suggestion-group-card>
  </pi-suggestion-drawer-base>
</pi-suggestion-drawer-create-form-modal-wrapper>
