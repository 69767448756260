<ng-template #renderItemTemplate let-page="page" let-type>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'page'">{{ page }}</ng-container>
    <ng-container *ngSwitchCase="'prev'">
      <i [uiType]="direction === 'rtl' ? 'right' : 'left'" ui-icon uiSize="sm"></i>
      {{ locale?.prev_page }}
    </ng-container>
    <ng-container *ngSwitchCase="'next'">
      {{ locale?.next_page }}
      <i [uiType]="direction === 'rtl' ? 'left' : 'right'" ui-icon uiSize="sm"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="ant-pagination-item-ellipsis">•••</span>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template [ngTemplateOutlet]="itemRender || renderItemTemplate" [ngTemplateOutletContext]="{ $implicit: type, page: index }"></ng-template>
