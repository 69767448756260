<div class="connector" *ngFor="let template of suggestedInsights.marketplaceTemplates; trackBy: trackById">
  <img
    *ngIf="template.requiredEntities?.[0]?.connector"
    [alt]="'template.requiredEntities[0].connector' + ' logo'"
    [ngSrc]="'/dist/img/logos/' + template.requiredEntities[0].connector + '.png'"
    height="24"
    width="24" />
  <div class="connector-title">
    <div class="bold">{{ template.title }}</div>
    <small class="text-color-gray-9">{{ "marketplace_insight" | localize }}</small>
  </div>
  <button class="select-button" [tabindex]="-1" (click)="selectSuggestedTemplate.emit(template)" ui-button uiShape="round" uiType="default">{{ "select" | localize }}</button>
</div>

<hr class="line" *ngIf="suggestedInsights.marketplaceTemplates.length && suggestedInsights.accountInsights.length" />

<div class="connector" *ngFor="let insight of suggestedInsights.accountInsights; trackBy: trackById">
  <img
    *ngIf="insight.requiredEntities?.[0]?.connector"
    [alt]="insight.requiredEntities[0].connector + ' logo'"
    [ngSrc]="'/dist/img/logos/' + insight.requiredEntities[0].connector + '.png'"
    height="24"
    width="24" />
  <div class="connector-title">
    <div class="insight-title">{{ insight.title }}</div>
    <div class="get-insight-message">{{ insight | insightUsageMessage }}</div>
  </div>
  <button class="select-button" [tabindex]="-1" (click)="selectSuggestedInsight.emit(insight)" ui-button uiShape="round" uiType="default">{{ "select" | localize }}</button>
</div>

<div class="insight-suggestions">
  <div class="display-flex">
    <a class="track-metric ui-link" (click)="openBrowseConnectionsModal.emit()">{{ "automate_yourself" | localize }}</a>
  </div>
</div>
