import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import env from "@gtmhub/env/env.module";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { FeatureToggle } from "./directives/feature-toggle";

const mod = module("featureToggles", [upgrade, env, employeesCore]);
mod.downgradeInjectable("FeatureTogglesFacade", FeatureTogglesFacade);
mod.downgradeInjectable("FeatureModuleService", FeatureModuleService);
mod.downgradeInjectable("EditionFeatureService", EditionFeatureService);
mod.directive("featureToggleEnabled", FeatureToggle.factory());
mod.directive("featureToggleDisabled", FeatureToggle.factory());

export default mod.name;
