<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>

<div *ngIf="notification.operationType === 'kpi_user_assigned' || notification.operationType === 'kpi_team_assigned'">
  <div class="title">
    <span *ngIf="notification.notificationData.item.isTeam">{{ notification.notificationData.actor.names }} {{ "assigned_kpi_to_your_team" | localize }}</span>
    <span *ngIf="!notification.notificationData.item.isTeam">{{ notification.notificationData.actor.names }} {{ "assigned_you_kpi" | localize }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon"><i class="fs-20px" [uiTooltipTitle]="'kpi' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="kpi"></i></div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="inbox-section">
          <div class="heading inline-notifications">{{ "title" | localize }}:</div>
          {{ notification.notificationData.item.name }}
        </div>
        <div class="heading inline-notifications">{{ "owner_cap" | localize }}:</div>
        <ui-assignee [uiAssignee]="notification.notificationData.item.assignee | asAssignee" uiSize="sm"></ui-assignee>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_kpi" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'kpi_user_deleted' || notification.operationType === 'kpi_team_deleted'">
  <div class="title">{{ "deleted_kpi" | localize: { names: notification.notificationData.actor.names } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon"><i class="fs-20px" [uiTooltipTitle]="'kpi' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="kpi"></i></div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
</div>

<div *ngIf="notification.operationType === 'kpi_user_modified' || notification.operationType === 'kpi_team_modified'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_edited_kpi" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>

  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.name.oldValue || notification.notificationData.item.changes.name.newValue">
        <div class="heading">{{ "title" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          {{ notification.notificationData.item.changes.name.oldValue }}
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          {{ notification.notificationData.item.changes.name.newValue }}
        </div>
      </div>

      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.assignees.oldValue || notification.notificationData.item.changes.assignees.newValue">
        <div class="heading">{{ "owners_cap" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          <span>
            <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees.oldValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
            >&nbsp;
          </span>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees.newValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
          >&nbsp;
        </div>
      </div>

      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.description.oldValue || notification.notificationData.item.changes.description.newValue">
        <div class="heading">{{ "description" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          <rich-text-viewer [text]="notification.notificationData.item.changes.description.oldValue || 'N/A'"></rich-text-viewer>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          <rich-text-viewer [text]="notification.notificationData.item.changes.description.newValue || 'N/A'"></rich-text-viewer>
        </div>
      </div>

      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.aggregation.oldValue || notification.notificationData.item.changes.aggregation.newValue">
        <div class="heading">{{ "summarize_cap" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          {{ notification.notificationData.item.changes.aggregation.oldValue }}
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          {{ notification.notificationData.item.changes.aggregation.newValue }}
        </div>
      </div>

      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.targetOperator.oldValue || notification.notificationData.item.changes.targetOperator.newValue">
        <div class="heading">{{ "direction" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          <span *ngIf="notification.notificationData.item.changes.targetOperator.oldValue">{{ notification.notificationData.item.changes.targetOperator.oldValue | localize }}</span>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          <span *ngIf="notification.notificationData.item.changes.targetOperator.newValue">{{ notification.notificationData.item.changes.targetOperator.newValue | localize }}</span>
        </div>
      </div>

      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_kpi" | localize }}</a>
      </div>
    </div>
  </div>
</div>
