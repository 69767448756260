<ui-row uiAlign="middle" uiJustify="center">
  <ui-col class="sp-pt-20 sp-pb-12">
    <img [src]="tasksEmptyIcon" alt="Custom views" />
  </ui-col>
</ui-row>
<ui-row uiAlign="middle" uiJustify="center">
  <ui-col class="sp-px-24">
    <h3>{{ "create_first_custom_view" | localize }}</h3>
  </ui-col>
</ui-row>
<ui-row uiAlign="middle" uiJustify="center">
  <ui-col class="sp-pt-4 sp-px-10">
    <p>{{ "filter_tasks_in_your_organization" | localize }}</p>
  </ui-col>
</ui-row>
<ui-row uiAlign="middle" uiJustify="center">
  <ui-col class="sp-pt-12 sp-px-10">
    <button (click)="openFilterModal()" data-test-id="create-custom-view-button-sub-navigation" ui-button uiShape="round">{{ "create_custom_view" | localize }}</button>
  </ui-col>
</ui-row>
