<ng-container *ngIf="isRelaxedMode; else dropdownTemplate">
  <ui-tabset [(uiSelectedIndex)]="activeTabIndex">
    <ng-container *ngFor="let tab of tabs">
      <ui-tab *ngIf="!tab.isVisible$ || (tab.isVisible$ | async)" [uiTitle]="tabTitleTemplate" (uiClick)="actionHandler(tab)" />
      <ng-template #tabTitleTemplate>
        <i *ngIf="tab.iconType" [uiType]="tab.iconType" ui-icon uiSize="sm"></i>
        <span [attr.data-test-id]="tab.dataTestId ?? null">{{ tab.key | localize }}{{ tab.subTitle | localize }}</span>
      </ng-template>
    </ng-container>
  </ui-tabset>
</ng-container>

<ng-template #dropdownTemplate>
  <gh-dropdown class="tabs-dropdown" [hasCustomButtonContent]="true" [menuItems]="tabs" (selectedItem)="dropdownSelectedChange($event)" iconName="menu-horizontal" placement="bottomLeft">
    <button aria-expanded="false" aria-haspopup="menu" gh-dropdown-custom-button-content ui-button uiTrigger="click" uiType="text">
      <i *ngIf="activeTab?.iconType" [uiType]="activeTab?.iconType" ui-icon uiSize="sm"></i>
      <span>{{ activeTab?.key | localize }}</span>
      <i ui-icon uiSize="sm" uiType="chevron-down-small"></i>
    </button>
  </gh-dropdown>
</ng-template>
