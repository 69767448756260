import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module, registerChildFutureState } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "kpis" */ "./kpis.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const registerKpiStatesFor = (baseState: string): Ng2StateDeclaration[] => {
  return [
    {
      name: `${baseState}._singleKpi`,
      abstract: true,
    },
    {
      name: `${baseState}._singleKpi.new.**`,
      url: "new/",
      lazyLoad,
    },
    {
      name: `${baseState}._singleKpi.form.**`,
      url: ":kpiId/edit/?groupId",
      lazyLoad,
    },
    {
      name: `${baseState}.details`,
      url: ":kpiId/details/?groupId",
      lazyLoad,
    },
    {
      name: `${baseState}.details._singleKpi`,
      abstract: true,
    },
    {
      name: `${baseState}.details._singleKpi.form.**`,
      url: "edit/",
      lazyLoad,
    },
    {
      name: `${baseState}.details.comments.**`,
      url: "comments/",
      lazyLoad,
    },
    {
      name: `${baseState}.details.comment.**`,
      url: ":commentId/comment/",
      lazyLoad,
    },
  ];
};

const kpisFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.kpis",
    abstract: true,
    url: "/kpis/",
    lazyLoad,
    redirectTo: "gtmhub.kpis.list",
  },
  {
    name: `gtmhub.kpis.grid`,
    url: "grid/",
    lazyLoad,
  },
  {
    name: `gtmhub.kpis.views`,
    abstract: true,
    url: "views/",
    lazyLoad,
  },
  {
    name: `gtmhub.kpis.views.list`,
    url: "",
    lazyLoad,
  },
  {
    name: `gtmhub.kpis.views.kpiView`,
    url: ":kpiViewId/",
    lazyLoad,
  },
  {
    name: `gtmhub.kpis.list`,
    url: "?kpiGroupId",
    lazyLoad,
  },
  ...registerKpiStatesFor("gtmhub.kpis.list"),
  ...registerKpiStatesFor("gtmhub.kpis.views.kpiView"),
  ...registerKpiStatesFor("gtmhub.kpis.grid"),
];

export const states: Ng2StateDeclaration[] = [...kpisFutureStates];

export const kpiDetailsFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base: `${base}._kpis`, factory: "kpiDetailsStates" });
  return [
    {
      name: `${base}._kpis`,
      abstract: true,
      url: "kpis/",
    },
    {
      name: `${base}._kpis.details.**`,
      url: ":kpiId/details/",
      lazyLoad,
    },
  ];
};
