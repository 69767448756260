<ng-container *uiModalDescription>
  <p class="state-description" *ngIf="oldStatus === 'achieved'">{{ "achieved_okr_status_description" | localize }}</p>
  <p class="state-description" *ngIf="oldStatus === 'abandoned'">{{ "abandoned_okr_status_description" | localize }}</p>
  <p class="state-description" *ngIf="oldStatus === 'active'">{{ "this_okr_will_be_able_to_be_updated" | localize }}</p>
</ng-container>
<form-group>
  <select-form-item
    *ngIf="oldStatus !== 'active'"
    [labelText]="'okr_state' | localize"
    [options]="stateItems"
    [placeholder]="oldStatus === 'achieved' ? (stateItems[0] | localize) : (stateItems[1] | localize)"
    [required]="true"
    (valueChange)="changeOkrState($event)"
    controlName="okrStateOption"></select-form-item>
  <select-form-item
    *ngIf="chosenState === 'abandoned'"
    [labelText]="'reason' | localize"
    [labelTooltip]="'select_reason_required' | localize"
    [options]="abandonReasonItems"
    [placeholder]="abandonReasonItems[0]"
    [required]="true"
    (valueChange)="changeAbandonReason($event)"
    controlName="okrAbandonedReason"
    e2eTestId="okr-dropdown-reason">
  </select-form-item>
  <rich-text-editor-with-mentions-form-item
    *ngIf="chosenState === 'achieved' || (chosenState === 'abandoned' && reason === 'none_of_the_above') || oldStatus === 'active'"
    [labelText]="'comment' | localize"
    [placeholder]="'please_enter_comment_optional' | localize"
    [useGifs]="false"
    [useMentions]="true"
    [useSubmitButton]="false"
    [value]="{ note: '', mentions: { removable: [], nonRemovable: [] } }"
    (valueChange)="changeComment($event)"
    controlName="okrAchievedComment"
    e2eTestId="okr-comment-textarea-toggle"></rich-text-editor-with-mentions-form-item>
</form-group>
