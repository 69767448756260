<quantive-plus-metrics
  [goal]="goal"
  [metrics]="metrics"
  [metricsInstance]="metricsInstance"
  [suggestionsStatus]="status"
  (allMetricsSuggest)="getQuantivePlusSuggestions()"
  (goBackToSuggestion)="onGoBackToSuggestionClicked($event)"
  (metricCreated)="onQuantivePlusMetricCreated($event)"
  (metricSuggest)="changeQuantivePlusSuggestion($event)"
  borderStyle="solid"
  iconTheme="fill"
  iconType="metric">
</quantive-plus-metrics>
