<ui-card [uiBordered]="false" [uiExtra]="extraHeaderContentTemplate" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [widgetId]="widgetId" />
  </ng-template>

  <ui-loading-indicator class="todos-widget-indicator-container" [uiIndicator]="loadingTemplate" [uiSpinning]="loading" [uiTip]="'loading' | localize">
    <ng-template #loadingTemplate>
      <widget-skeleton />
    </ng-template>

    <ng-container *ngIf="todos && !loading && !error">
      <!-- objectives -->
      <todos-widget-section
        *ngIf="todos.okrsForApproval?.length > 0"
        [ctaButtonTemplate]="ctaButtonTemplate"
        [sectionTitle]="'okrs' | localize"
        [todos]="todos.okrsForApproval"
        (todoLabelClick)="handleOkrLinkClick($event['id'])"
        data-test-id="todos-okrsForApproval"
        iconType="goal">
        <ng-template #ctaButtonTemplate let-item>
          <button
            [ghTrackMeta]="getTrackingMeta({ button_name: 'approve_objective', id: item.id })"
            (click)="openApproveOkrModal(item.id)"
            ghTrack="Button Clicked"
            ui-button
            uiShape="round"
            uiSize="small">
            {{ "approve" | localize }}
          </button>
        </ng-template>
      </todos-widget-section>

      <!-- key results -->
      <todos-widget-section
        *ngIf="todos.keyResults?.length > 0"
        [ctaButtonTemplate]="ctaButtonTemplate"
        [sectionTitle]="'key_results' | localize"
        [todos]="todos.keyResults"
        (todoLabelClick)="handleKrLinkClick($event['id'])"
        data-test-id="todos-keyResults"
        iconType="metric">
        <ng-template #ctaButtonTemplate let-item>
          <button
            [ghTrackMeta]="getTrackingMeta({ button_name: 'update_key_result', id: item.id })"
            (click)="openUpdateKrModal(item.id)"
            ghTrack="Button Clicked"
            ui-button
            uiShape="round"
            uiSize="small">
            {{ "update" | localize }}
          </button>
        </ng-template>
      </todos-widget-section>

      <!-- check-ins -->
      <todos-widget-section
        *ngIf="todos.reflections?.length > 0"
        [ctaButtonTemplate]="ctaButtonTemplate"
        [sectionTitle]="'check_ins' | localize"
        [todos]="todos.reflections"
        (todoLabelClick)="handleCheckInLinkClick($event['teamId'])"
        data-test-id="todos-checkins"
        iconType="check-in"
        idField="teamId"
        nameField="teamName">
        <ng-template #ctaButtonTemplate let-item>
          <button
            [ghTrackMeta]="getTrackingMeta({ button_name: 'reflections_fill_out', id: item.teamId })"
            (click)="openCheckInPage(item.teamId, { expandCurrentUserCheckIn: true })"
            ghTrack="Button Clicked"
            ui-button
            uiShape="round"
            uiSize="small">
            {{ "reflections_fill_out" | localize }}
          </button>
        </ng-template>
      </todos-widget-section>

      <!-- tasks -->
      <todos-widget-section
        *ngIf="todos.tasks?.length > 0"
        [ctaButtonTemplate]="ctaButtonTemplate"
        [loadingItemId]="loadingItemId"
        [sectionTitle]="'tasks' | localize"
        [todos]="todos.tasks"
        (todoLabelClick)="handleTaskLinkClick($event['id'])"
        data-test-id="todos-tasks"
        iconType="task">
        <ng-template #ctaButtonTemplate let-item>
          <task-status [inline]="true" [status]="item.status" (statusChange)="updateTaskStatus(item.id, $event)"></task-status>
        </ng-template>
      </todos-widget-section>
    </ng-container>
  </ui-loading-indicator>

  <ui-alert *ngIf="error" [uiCloseable]="true" [uiDescription]="error" (uiOnClose)="handleErrorDismiss()" uiType="error" />

  <ng-container *ngIf="!hasPendingTodos && !loading && !error">
    <container-empty-state *ngIf="!hasResolvedLastTodo" [message]="'you_will_find_here_todos' | localize" pictureUrl="/dist/img/home/widgets/empty_to-dos.svg" />
    <container-empty-state *ngIf="hasResolvedLastTodo" [message]="'your_todos_list_is_empty' | localize" pictureUrl="/dist/img/home/widgets/empty_to-dos_success.svg" />
  </ng-container>
</ui-card>
