<ng-template>
  <ui-input-number-group [uiAddOnAfter]="inputSuffix" [uiAddOnBefore]="inputPrefix" [uiAddonStyle]="{ maxWidth: '130px' }" [uiReadOnly]="readonly" uiSize="large">
    <ui-input-number
      [a11yDisabled]="disabled"
      [a11yLabelledby]="labelId"
      [a11yRequired]="required"
      [e2eTestId]="e2eTestId"
      [formControl]="formControl"
      [uiAutoFocus]="autofocus"
      [uiBorderless]="borderless"
      [uiClickable]="clickable"
      [uiId]="controlId"
      [uiPlaceHolder]="placeholder"
      [uiReadOnly]="readonly"
      [useThousandsFormatter]="useThousandsFormatter"
      [valueControlsHide]="valueControlsHide"
      (click)="this.uiClick.emit()"
      (keydown)="this.uiKeydown.emit($event)"
      (uiBlur)="this.uiBlur.emit(value)"
      uiSize="large"></ui-input-number>
  </ui-input-number-group>
</ng-template>
