<ui-date-picker
  *ngIf="!isRange"
  [(ngModel)]="datePickerModel"
  [a11yLabelledby]="a11yLabelledby"
  [a11yRequired]="a11yRequired"
  [hideSuffix]="hideSuffix"
  [treatUtcAsLocal]="treatUtcAsLocal"
  [uiAllowClear]="allowClear"
  [uiBackdrop]="backdrop && !inline"
  [uiBorderless]="borderless"
  [uiDisabled]="disabled"
  [uiDisabledDate]="isDateDisabled"
  [uiFormat]="dateFormat | toDatefnsFormat"
  [uiInline]="inline"
  [uiOpen]="open"
  [uiSize]="size"
  (uiDateChange)="dateChange.emit($event)"
  (uiOnOpenChange)="openStateChanged.emit($event)"
  data-test-id="custom-date-picker"></ui-date-picker>
<ui-range-picker
  *ngIf="isRange"
  [(ngModel)]="rangePickerModel"
  [a11yLabelledby]="a11yLabelledby"
  [a11yRequired]="a11yRequired"
  [treatUtcAsLocal]="treatUtcAsLocal"
  [uiAllowClear]="allowClear"
  [uiBackdrop]="true"
  [uiBorderless]="borderless"
  [uiDisabled]="disabled"
  [uiDisabledDate]="isDateDisabled"
  [uiFormat]="dateFormat | toDatefnsFormat"
  [uiInline]="inline"
  [uiOpen]="open"
  [uiSize]="size"
  (uiDateChange)="dateRangeChanged($event)"
  (uiOnOpenChange)="openStateChanged.emit($event)"
  data-test-id="custom-date-picker"></ui-range-picker>
