import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Task, TaskDTO } from "../models/tasks.models";
import { TaskState } from "./task-state.service";

@Injectable({
  providedIn: "root",
})
export class TasksApiService extends BaseApiService<Task, TaskDTO, TaskState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, taskState: TaskState) {
    super("tasks", { ...new ApiVersionModel("v1"), getAll: "v2", patch: "v2", get: "v1" }, httpClient, appConfig, taskState);
  }
}
