<!--single mode-->
<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'default'">
    <ui-select-search
      [a11yDescribedby]="a11yDescribedby"
      [a11yDescription]="a11yDescription"
      [a11yLabel]="a11yLabel"
      [a11yLabelledby]="a11yLabelledby"
      [a11yRequired]="a11yRequired"
      [activeDescendant]="activatedValue | valueToID"
      [attr.tabindex]="disabledTabIndex"
      [autofocus]="autofocus"
      [autoHideSearch]="autoHideSearch"
      [disabled]="disabled"
      [focusTrigger]="open"
      [listboxId]="listboxId"
      [mirrorSync]="false"
      [nzId]="nzId"
      [nzOpen]="open"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [selectSearchInputTabIndex]="selectSearchInputTabIndex"
      [showInput]="showSearch"
      [value]="inputValue!"
      (isComposingChange)="isComposingChange($event)"
      (valueChange)="onInputValueChange($event)"
      popUpType="listbox"></ui-select-search>
    <ng-container *ngIf="!hideSelectedTags">
      <ui-select-item
        *ngIf="isShowSingleLabel"
        [class.select-disabled]="disabled"
        [class.visible-template-overflow]="visibleTemplateOverflow"
        [contentTemplateOutlet]="customTemplate"
        [contentTemplateOutletContext]="listOfTopItem[0]"
        [deletable]="false"
        [disabled]="false"
        [isSelected]="focusedValue === listOfTopItem[0].nzValue"
        [label]="listOfTopItem[0].nzLabel"
        [removeIcon]="removeIcon"></ui-select-item>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <!--multiple or tags mode-->
    <ng-container *ngIf="!hideSelectedTags">
      <ui-select-item
        class="select-item-chip"
        *ngFor="let item of listOfSlicedItem; trackBy: trackValue"
        [class.error-select-item]="problematicSelectItems.includes(item.nzValue)"
        [class.remove-icon-rendered]="renderRemoveChipIcon"
        [class.select-item-chip-disabled]="item.nzDisabled || disabled"
        [class.visible-template-overflow]="visibleTemplateOverflow"
        [contentTemplateOutlet]="item.contentTemplateOutlet"
        [contentTemplateOutletContext]="item.contentTemplateOutletContext"
        [deletable]="deletable"
        [disabled]="item.nzDisabled || disabled"
        [isSelected]="focusedValue === item.nzValue"
        [label]="item.nzLabel"
        [removeIcon]="removeIcon"
        [renderRemoveIcon]="renderRemoveChipIcon"
        (delete)="onDeleteItem(item.contentTemplateOutletContext)"></ui-select-item>
    </ng-container>
    <ui-select-search
      [a11yDescribedby]="a11yDescribedby"
      [a11yDescription]="a11yDescription"
      [a11yLabel]="a11yLabel"
      [a11yLabelledby]="a11yLabelledby"
      [a11yRequired]="a11yRequired"
      [activeDescendant]="activatedValue | valueToID"
      [attr.tabindex]="disabledTabIndex"
      [autofocus]="autofocus"
      [autoHideSearch]="autoHideSearch"
      [class.invisible-input]="!open"
      [disabled]="disabled"
      [focusTrigger]="open"
      [listboxId]="listboxId"
      [mirrorSync]="true"
      [nzId]="nzId"
      [nzOpen]="open"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [selectSearchInputTabIndex]="selectSearchInputTabIndex"
      [showInput]="showSearch"
      [value]="inputValue!"
      (isComposingChange)="isComposingChange($event)"
      (valueChange)="onInputValueChange($event)"
      popUpType="listbox"></ui-select-search>
  </ng-container>
</ng-container>
<ui-select-placeholder
  *ngIf="isShowPlaceholder || (hideSelectedTags && !inputValue)"
  [class.select-placeholder-disabled]="disabled"
  [placeholder]="placeholder"
  [uiPlaceHolderTemplate]="uiPlaceHolderTemplate"
  tabindex="-1"></ui-select-placeholder>
