<pi-suggestion-drawer-side-modal-wrapper [isOpen]="isOpen">
  <pi-suggestion-drawer-base [isOpen]="isOpen" (visibilityChange)="visibilityChange($event)">
    <ui-tabset>
      <ui-tab *ngIf="tabsVisibility.overview | async" [uiTitle]="'overview' | localize">
        <suggestion-drawer-overview-tab-v2
          *ngIf="metric"
          [explanationSection]="explanationSection"
          [parent]="metric"
          [statePropagator]="overviewTabStatePropagator"
          [whyMatterSectionTitle]="'why_kr_matter' | localize"
          (retry)="explanationSection.onRetry()"
          parentType="metric">
        </suggestion-drawer-overview-tab-v2>
      </ui-tab>
      <ui-tab
        *ngIf="tabsVisibility.effectiveness | async"
        [uiTabColor]="effectivenessSection?.effectiveness?.score | scoreToLowToHighScale | lowToHighScaleToColor"
        [uiTitle]="'effectiveness_cap' | localize">
        <suggestion-drawer-effectiveness-tab
          *ngIf="effectivenessSection"
          [effectivenessSection]="effectivenessSection"
          [flowId]="flowId"
          [flowName]="effectivenessFlowName"
          [improveWithSuggestionsSectionTitle]="'use_the_suggestions_below_improve_kr' | localize"
          [parent]="metric"
          [statePropagator]="effectivenessTabStatePropagator"
          [titleSection]="titleSection"
          (retry)="effectivenessSection.onRetry()"
          parentDrawerContent="metric"
          parentType="metric"></suggestion-drawer-effectiveness-tab>
      </ui-tab>

      <ui-tab *ngIf="isQuantivePlusEnabled && tabsVisibility.allSuggestions | async" [uiTitle]="'all_suggestions_cap' | localize">
        <suggestion-drawer-all-suggestions-tab
          *ngIf="metric && taskList"
          [flowName]="allSuggestionsflowName"
          [metric]="metric"
          [suggestions]="suggestions"
          [taskList]="taskList"
          [tasksSuggestionStatus]="tasksSuggestionStatus"
          [visibleSections]="['tasks']"
          parentType="metric">
        </suggestion-drawer-all-suggestions-tab>
      </ui-tab>
    </ui-tabset>
  </pi-suggestion-drawer-base>
</pi-suggestion-drawer-side-modal-wrapper>
