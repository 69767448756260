<div class="ant-picker-header" *ngIf="nzInDatePicker">
  <div class="ant-picker-header-view">{{ dateHelper.format($any(time?.value), format) || "&nbsp;" }}</div>
</div>
<div class="ant-picker-content">
  <ul class="ant-picker-time-panel-column" #hourListElement *ngIf="hourEnabled" style="position: relative">
    <ng-container *ngFor="let hour of hourRange; trackBy: trackByFn">
      <li
        class="ant-picker-time-panel-cell"
        *ngIf="!(nzHideDisabledOptions && hour.disabled)"
        [class.ant-picker-time-panel-cell-disabled]="hour.disabled"
        [class.ant-picker-time-panel-cell-selected]="isSelectedHour(hour)"
        (click)="selectHour(hour)">
        <div class="ant-picker-time-panel-cell-inner">{{ hour.index | number: "2.0-0" }}</div>
      </li>
    </ng-container>
  </ul>
  <ul class="ant-picker-time-panel-column" #minuteListElement *ngIf="minuteEnabled" style="position: relative">
    <ng-container *ngFor="let minute of minuteRange; trackBy: trackByFn">
      <li
        class="ant-picker-time-panel-cell"
        *ngIf="!(nzHideDisabledOptions && minute.disabled)"
        [class.ant-picker-time-panel-cell-disabled]="minute.disabled"
        [class.ant-picker-time-panel-cell-selected]="isSelectedMinute(minute)"
        (click)="selectMinute(minute)">
        <div class="ant-picker-time-panel-cell-inner">{{ minute.index | number: "2.0-0" }}</div>
      </li>
    </ng-container>
  </ul>
  <ul class="ant-picker-time-panel-column" #secondListElement *ngIf="secondEnabled" style="position: relative">
    <ng-container *ngFor="let second of secondRange; trackBy: trackByFn">
      <li
        class="ant-picker-time-panel-cell"
        *ngIf="!(nzHideDisabledOptions && second.disabled)"
        [class.ant-picker-time-panel-cell-disabled]="second.disabled"
        [class.ant-picker-time-panel-cell-selected]="isSelectedSecond(second)"
        (click)="selectSecond(second)">
        <div class="ant-picker-time-panel-cell-inner">{{ second.index | number: "2.0-0" }}</div>
      </li>
    </ng-container>
  </ul>
  <ul class="ant-picker-time-panel-column" #use12HoursListElement *ngIf="nzUse12Hours" style="position: relative">
    <ng-container *ngFor="let range of use12HoursRange">
      <li class="ant-picker-time-panel-cell" *ngIf="!nzHideDisabledOptions" [class.ant-picker-time-panel-cell-selected]="isSelected12Hours(range)" (click)="select12Hours(range)">
        <div class="ant-picker-time-panel-cell-inner">{{ range.value }}</div>
      </li>
    </ng-container>
  </ul>
</div>
<div class="ant-picker-footer" *ngIf="!nzInDatePicker">
  <div class="ant-picker-footer-extra" *ngIf="nzAddOn">
    <ng-template [ngTemplateOutlet]="nzAddOn"></ng-template>
  </div>
  <ul class="ant-picker-ranges">
    <li class="ant-picker-now">
      <a (click)="onClickNow()">
        {{ nzNowText || ("Calendar.lang.now" | nzI18n) }}
      </a>
    </li>
    <li class="ant-picker-ok">
      <button (click)="onClickOk()" type="button" ui-button uiSize="small" uiType="primary">
        {{ nzOkText || ("Calendar.lang.ok" | nzI18n) }}
      </button>
    </li>
  </ul>
</div>
