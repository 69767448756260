import { ChangeDetectorRef, Component, ContentChild, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnInit, Output, TemplateRef, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Observable, Subject, debounceTime } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { sortAssigneeIdsByActiveStatus } from "@webapp/assignees/utils/assignee.utils";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { IRelatedItemsSelectorNode } from "@webapp/links/models/related-items.models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IParentSelectorEntry, IParentSelectorNode } from "@webapp/okrs/models/parent-selector.models";
import { emptyNode, kpiNode, metricNode, objectiveNode } from "@webapp/okrs/utils/okr-and-kpi-selector-nodes-builder.util";
import { SimpleChangesTyped } from "@webapp/shared/models";
import { MultiSelectorFacade } from "./multi-selector-facade.service";
import {
  IMultiSelectorGoal,
  IMultiSelectorKpi,
  IMultiSelectorMetric,
  IMultiSelectorNodesType,
  IMultiSelectorSearchParams,
  IMultiSelectorSelectedEntry,
  IMultiSelectorTrackingParams,
} from "./multi-selector.models";

const relatedItemsSelectorHeight = 40;
const minRowHeight = 50;

const MULTI_SELECTOR_CONTROL_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiSelectorComponent), multi: true };

@UntilDestroy()
@Component({
  selector: "multi-selector",
  templateUrl: "multi-selector.component.html",
  styleUrls: ["multi-selector.component.less"],
  providers: [MULTI_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class MultiSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public nodes: NzTreeNodeOptions[];
  @Input() public nodesLoading: boolean;
  @Input() public selectedItemPartial: Partial<IMultiSelectorSelectedEntry>;
  @Input() public searchParamsPlaceholder: IMultiSelectorSearchParams;
  @Input() public searchFunctionPlaceholder: (searchTerm: string) => Observable<NzTreeNodeOptions[]>;
  @Input() public sortFunctionPlaceholder: (
    itemA: IMultiSelectorGoal | IMultiSelectorMetric | IMultiSelectorKpi,
    itemB: IMultiSelectorGoal | IMultiSelectorMetric | IMultiSelectorKpi
  ) => number;
  @Input() public sessionIds: string[];
  @Input() public uiId: string;
  @Input({ required: false }) public placeholder: string;
  @Input({ required: false }) public nodesType: IMultiSelectorNodesType = IMultiSelectorNodesType.OKR;
  @Input() public readonly: boolean;
  @Input() public disabled: boolean;
  @Input() public borderless: boolean;
  @Input() public treeOffset: number = 0;
  @Input() public matchSelectWidth = true;
  @Input() public removeChipEnabled = true;
  @Input() @InputBoolean() public a11yRequired = false;
  @Input() @InputBoolean() public autofocus = false;
  @Input() @InputBoolean() public triggerOpen = false;
  @Input() public a11yLabelledby: string;
  @Output() public readonly valueUpdate = new EventEmitter<NzTreeNodeOptions>();
  @Output() public readonly isScrollUsed = new EventEmitter<boolean>();
  @Output() public readonly expandChange = new EventEmitter();
  @Output() public readonly openChange = new EventEmitter<boolean>();
  @Output() public readonly trackingParamsUpdate = new EventEmitter<IMultiSelectorTrackingParams>();
  @Output() public readonly selectedItemChipClick = new EventEmitter<void>();
  @HostBinding("class.borderless") public get borderlessClass(): boolean {
    return this.borderless;
  }

  @HostBinding("class.disabled") public get disabledClass(): boolean {
    return this.disabled;
  }

  @ContentChild("nodeTitleTemplate") public nodeTitleTemplate!: TemplateRef<{ $implicit: { node: NzTreeNodeOptions } }>;

  public selectorForm: FormGroup;
  public onSearchChange$ = new Subject<string>();
  public visualizationNodes: NzTreeNodeOptions[] = [];
  public selectedNode: NzTreeNodeOptions | null = null;
  private searchResults: NzTreeNodeOptions[] = [];
  public inputValue = "";
  public isSearchLoading = false;
  public isMenuOpen = false;
  public isEmptyInputValue = true;
  public canPerformSearch = false;
  private scrollUsed = false;
  public isEmptyNodes = true;
  private hasHiddenNodeInNodes: boolean;
  public showLoading = true;
  public showNotFoundMsg = false;

  // custom search ng-zorro fn is needed to override the default ui filtering from ng-zorro, because we are performing back-end filtering
  public onSearchFunc = (): boolean => true;

  private notifyControlChange: (value: NzTreeNodeOptions) => void;

  constructor(
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private multiSelectorFacade: MultiSelectorFacade,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initSelectorForm();
    this.subscribeToValueChange();
    this.subscribeSearchChange();

    if (this.selectedItemPartial) {
      this.startLoadingSearch();
      this.getSelectedItem();
    }
  }

  public ngOnChanges(changes: Partial<SimpleChangesTyped<MultiSelectorComponent>>): void {
    if (changes.nodes && !changes.nodes.firstChange && !this.inputValue) {
      this.setNodes(this.nodes);
      this.stopLoadingIfNoSuggestionsAvailable();
    }

    if (changes.selectedItemPartial && !changes.selectedItemPartial.firstChange) {
      this.startLoadingSearch();
      this.getSelectedItem();
    }
  }

  public get noRecentsMessage(): string {
    return localize("no_recently_visited_items_found", { itemsType: localize(`${this.nodesType}s`) });
  }

  public get noResultsFoundMessage(): string {
    return localize("no_results_found");
  }

  public get treeSelectPlaceholder(): string {
    return this.isMenuOpen ? this.searchFieldPlaceholder : this.selectFieldPlaceholder;
  }

  private get searchFieldPlaceholder(): string {
    return localize(this.nodesType === IMultiSelectorNodesType.OKR ? "search_by_okr_or_owner" : "search_kpis");
  }

  public get treeSelectExpandIconCondition(): boolean {
    return this.nodesType === IMultiSelectorNodesType.OKR;
  }

  private get selectFieldPlaceholder(): string {
    if (this.placeholder) return localize(this.placeholder);

    return localize(this.nodesType === IMultiSelectorNodesType.OKR ? "select_okr_or_kr" : "select_kpi");
  }

  public writeValue(value: NzTreeNodeOptions): void {
    this.selectedItemPartial = value as Partial<IMultiSelectorSelectedEntry>;

    if (this.selectedItemPartial) {
      this.startLoadingSearch();
      this.getSelectedItem();
    }

    this.changeDetector.markForCheck();
  }

  public registerOnChange(fn: (value: NzTreeNodeOptions) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public removeSelectedItem(): void {
    this.selectedNode = null;
    this.selectorForm.controls["entity"].setValue(null);
    this.emitChange();
  }

  private emitChange(): void {
    this.valueUpdate.emit(this.selectedNode);
    this.notifyControlChange?.(this.selectedNode);
  }

  private isEntryOkr(item: Partial<IMultiSelectorSelectedEntry>): item is IParentSelectorEntry {
    return item && ["goal", "metric"].includes((item as IParentSelectorEntry).type);
  }

  private getSelectedItem(): void {
    if (this.isEntryOkr(this.selectedItemPartial)) {
      this.setShowLoading();
      const sessionIds = this.selectedItemPartial?.sessionId ? [...(this.sessionIds || []), this.selectedItemPartial.sessionId] : this.sessionIds;
      this.multiSelectorFacade
        .getSelectedItem$({
          sessionIds: sessionIds,
          type: this.selectedItemPartial?.type,
          parentId: this.selectedItemPartial?.id,
        })
        .pipe(untilDestroyed(this))
        .subscribe(([selectedItem]) => {
          if (selectedItem && !this.isMenuOpen) {
            this.setSelectedItem(selectedItem);
            this.stopLoadingSearch();
          }

          if (this.isEntryOkr(this.selectedItemPartial) && this.selectedItemPartial?.isRestrictedParentSession) {
            this.setSelectedItem(this.buildRestrictedParentPlaceholder() as Goal);
          }

          if (!selectedItem) {
            this.selectedNode = null;
          }
        });
    }
  }

  private initSelectorForm(): void {
    this.selectorForm = this.formBuilder.group({ entity: this.formBuilder.control("") });
  }

  private subscribeToValueChange(): void {
    this.selectorForm.controls["entity"].valueChanges.pipe(untilDestroyed(this)).subscribe((id) => this.updateValue(id));
  }

  private updateValue(id: string): void {
    const selectedNode = this.findNodeByKey(this.visualizationNodes, id);

    this.selectedNode = selectedNode;
    if (this.selectedNode) {
      this.selectedNode.hidden = true;
      this.selectedNode.children = null;
    }

    const trackingParams = {
      canPerformSearch: this.canPerformSearch,
      isEmptyInputValue: this.isEmptyInputValue,
      inputValue: this.inputValue,
    };

    this.trackingParamsUpdate.emit(trackingParams);
    this.valueUpdate.emit(selectedNode);

    this.notifyControlChange?.(selectedNode);
  }

  private findNodeByKey(nodes: NzTreeNodeOptions[], key: string): NzTreeNodeOptions | null {
    // when we have empty nodes or we have not found any child node by key
    if (nodes.length === 0) {
      return null;
    }

    const foundNode = nodes.find((node) => node.key === key);

    if (foundNode) {
      return foundNode;
    }

    // when we have not found a node by key we are collecting all child nodes in order to find recursively a child node by key
    const allChildren = nodes.reduce((children, node) => {
      if (node.children) {
        children.push(...node.children);
      }

      return children;
    }, []);

    return this.findNodeByKey(allChildren, key);
  }

  private subscribeSearchChange(): void {
    const DEBOUNCE_TIME = 200;
    this.onSearchChange$.pipe(untilDestroyed(this), debounceTime(DEBOUNCE_TIME)).subscribe(() => this.onInputValueChange());
  }

  private onInputValueChange(): void {
    if (this.isEmptyInputValue) {
      this.setNodes(this.nodes);
    } else {
      this.getItemsBySearchTerm();
    }

    this.stopLoadingIfNoSuggestionsAvailable();
  }

  private getItemsBySearchTerm(): void {
    if (this.canPerformSearch) {
      this.setShowLoading();

      this.searchFunctionPlaceholder(this.inputValue)
        .pipe(untilDestroyed(this))
        .subscribe((searchResults) => {
          if (this.canPerformSearch) {
            this.searchResults = searchResults;
            const assigneesMap = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>().assignees.map;
            this.searchResults.forEach((result) => {
              if (result?.ownerIds) {
                result.ownerIds = sortAssigneeIdsByActiveStatus(result.ownerIds, assigneesMap);
              }
            });
            this.buildListNodes();
          }
          this.stopLoadingSearch();
        });
    }
  }

  private buildListNodes(): void {
    let nodes = this.searchResults;

    if (this.sortFunctionPlaceholder) {
      nodes = nodes.sort((nodeA, nodeB) => this.sortFunctionPlaceholder({ ownerIds: nodeA.ownerIds }, { ownerIds: nodeB.ownerIds }));
    }

    this.setNodes(nodes);
  }

  private setSelectedItem(selectedItem: Goal | Metric | Kpi): void {
    this.selectedNode = this.getSelectedItemNode(selectedItem);
    this.selectedNode.hidden = true;
    this.selectedNode.children = null;
    this.setNodes([this.selectedNode]);
  }

  private getSelectedItemNode(selectedItem: Goal | Metric | Kpi): IRelatedItemsSelectorNode | IParentSelectorNode {
    if (!this.selectedItemPartial?.type) return emptyNode();

    switch (this.selectedItemPartial.type) {
      case "goal":
        return objectiveNode(selectedItem);
      case "metric":
        return metricNode(selectedItem);
      case "kpi":
        return kpiNode(selectedItem);
      default:
        return emptyNode();
    }
  }

  public onOpenChangeHandler({ isOpen }: { isOpen: boolean }): void {
    this.isMenuOpen = isOpen;
    this.openChange.emit(isOpen);

    if (!isOpen && this.selectedNode && this.inputValue) {
      this.canPerformSearch = false;
      this.selectedNode.children = null;
      this.selectedNode.hidden = true;
      setTimeout(() => {
        this.setNodes([this.selectedNode]);
      }, 0);
    }

    if (isOpen) {
      this.onSearchValueChange("");

      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          const dropdown = document.querySelector(".cdk-overlay-pane") as HTMLElement;

          if (!this.scrollUsed) {
            dropdown.onwheel = (): void => {
              this.isScrollUsed.emit(true);
            };
          }
        }, 0);
      });
    }
  }

  public onSearchValueChange(inputValue: string): void {
    if (this.selectedNode) {
      this.selectedNode.children = null;
      this.selectedNode.hidden = true;
    }

    // setting nodes state is for preventing of menu flickering when we transition from list view to tree view
    // when we have a selected node and we perform search query we need to preserve the reference to the selected node in the array
    this.setNodes(this.selectedNode ? [this.selectedNode] : []);

    this.startLoadingSearch();
    this.inputValue = inputValue;
    this.isEmptyInputValue = inputValue.length === 0;
    this.canPerformSearch = inputValue.length > 2;
    this.setShowLoading();
    this.setShowNotFoundMsg();

    this.onSearchChange$.next(inputValue);
  }

  private setNodes(nodes: NzTreeNodeOptions[]): void {
    const isSelectedItemInSuggested = this.isSelectedItemInSuggested(nodes);

    if (this.selectedNode && !isSelectedItemInSuggested && this.isEmptyInputValue) {
      this.selectedNode.hidden = true;

      nodes.push(this.selectedNode);
    }

    this.visualizationNodes = nodes;
    this.calculateDropdownPosition();

    this.hasHiddenNodeInNodes = this.visualizationNodes.length === 1 && !!this.visualizationNodes[0].hidden;
    this.isEmptyNodes = this.visualizationNodes.length === 0 || this.hasHiddenNodeInNodes;
    this.stopLoadingSearch();
    this.setShowLoading();
    this.setShowNotFoundMsg();

    this.changeDetector.markForCheck();
  }

  private calculateDropdownPosition(): void {
    const el = document.querySelector(".tree-select-cdk-overlay-pane") as HTMLElement;

    if (!el) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      const screenHeight = entries[0].rootBounds?.height;
      const dropdownHeight = entries[0].boundingClientRect.height;
      const dropdownTop = entries[0].intersectionRect.top;
      const dropdownBottom = entries[0].intersectionRect.bottom;

      if (screenHeight && screenHeight === dropdownBottom && dropdownHeight > minRowHeight) {
        el.style.top = `${dropdownTop - relatedItemsSelectorHeight - dropdownHeight}px`;
      }
    });

    observer.observe(el);
  }

  private isSelectedItemInSuggested(suggestedNodes: NzTreeNodeOptions[]): boolean {
    if (!this.selectedNode) {
      return false;
    }

    for (const node of suggestedNodes) {
      if (node.key === this.selectedNode.key) {
        return true;
      }

      if (node.children?.length) {
        const result = this.isSelectedItemInSuggested(node.children);
        if (result) return result;
      }
    }
  }

  private startLoadingSearch(): void {
    this.isSearchLoading = true;
  }

  private stopLoadingSearch(): void {
    this.isSearchLoading = false;
  }

  private get isLoading(): boolean {
    return !this.isEmptyInputValue ? this.isSearchLoading : this.nodesLoading;
  }

  private setShowNotFoundMsg(): void {
    if (!this.isEmptyInputValue) {
      this.showNotFoundMsg = this.isEmptyNodes && this.canPerformSearch && !this.isLoading;
      return;
    }

    this.showNotFoundMsg = this.isEmptyNodes && !this.isLoading;
  }

  private setShowLoading(): void {
    if (!this.isEmptyInputValue) {
      this.showLoading = (this.isEmptyNodes || this.selectedNode) && this.canPerformSearch && this.isLoading;
      return;
    }

    this.showLoading = this.isEmptyNodes || this.isLoading;
    this.changeDetector.markForCheck();
  }

  private stopLoadingIfNoSuggestionsAvailable(): void {
    if (this.isEmptyNodes === true && this.hasHiddenNodeInNodes === false && this.isLoading === false) {
      this.showLoading = false;
    }
  }

  private buildRestrictedParentPlaceholder(): Pick<Goal, "name"> | Pick<Metric, "name"> {
    return {
      name: localize("parent_okr_not_available"),
    };
  }
}
