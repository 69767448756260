import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { CurrentUserAllowedActionsParamType } from "@webapp/permissions/models/permissions.model";
import { IAllowedActionsMap } from "@webapp/sessions/models/sessions.model";
import { GranularKpiPermissionsService } from "./granular-kpi-permissions.service";

type NegateParam = { negate: boolean | undefined };

@Injectable({ providedIn: "root" })
export class KpiPermissionsService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private editionFeatureService: EditionFeatureService,
    private granularKpiPermissionsService: GranularKpiPermissionsService
  ) {}

  public hasPermissionToView$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isKpisFeatureEnabledForAccount$(), this.granularKpiPermissionsService.hasPermissionToView$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToOwn$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isKpisFeatureEnabledForAccount$(), this.granularKpiPermissionsService.hasPermissionToOwn$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToCreate$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isKpisFeatureEnabledForAccount$(), this.granularKpiPermissionsService.hasPermissionToCreate$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToEdit$(currentUserAllowedActions: CurrentUserAllowedActionsParamType, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isKpisFeatureEnabledForAccount$(),
      this.granularKpiPermissionsService.hasPermissionToEdit$(),
      of(currentUserAllowedActionsMap.update ?? false),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToDelete$(currentUserAllowedActions: CurrentUserAllowedActionsParamType, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isKpisFeatureEnabledForAccount$(),
      this.granularKpiPermissionsService.hasPermissionToDelete$(),
      of(currentUserAllowedActionsMap.delete),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToCreateSnapshots$(
    currentUserAllowedActions: CurrentUserAllowedActionsParamType,
    { negate }: NegateParam = { negate: false }
  ): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isKpisFeatureEnabledForAccount$(),
      this.granularKpiPermissionsService.hasPermissionToCreateSnapshots$(),
      of(currentUserAllowedActionsMap.update),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToDeleteOwnSnapshots$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isKpisFeatureEnabledForAccount$(), this.granularKpiPermissionsService.hasPermissionToDeleteOwnSnapshots$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToDeleteSnapshots$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isKpisFeatureEnabledForAccount$(), this.granularKpiPermissionsService.hasPermissionToDeleteSnapshots$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  private isKpisFeatureEnabledForAccount$(): Observable<boolean> {
    return combineLatest([this.featureModuleService.isKpiModuleEnabled$(), this.editionFeatureService.hasFeature$("kpis")]).pipe(
      map((conditions) => conditions.every((condition) => condition))
    );
  }

  private negateIfNecessary$({ hasPermission, negate }: { hasPermission: boolean; negate: boolean }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;
    return of(hasPermission !== shouldNegate);
  }

  private currentUserAllowedActionsParamToMap(currentUserAllowedActions: CurrentUserAllowedActionsParamType): IAllowedActionsMap {
    return Array.isArray(currentUserAllowedActions) ? currentUserAllowedActions.reduce((acc, action) => ((acc[action] = true), acc), {}) : currentUserAllowedActions;
  }
}
