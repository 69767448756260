<ng-container *ngIf="config.nzFooter; else defaultFooterButtons">
  <ng-container *nzStringTemplateOutlet="config.nzFooter; context: { $implicit: config.nzData, modalRef: modalRef }">
    <div *ngIf="!buttonsFooter" [innerHTML]="config.nzFooter"></div>
    <ng-container *ngIf="buttonsFooter">
      <button
        *ngFor="let button of buttons"
        [attr.data-test-id]="button.e2eTestId"
        [disabled]="getButtonCallableProp(button, 'disabled')"
        [hidden]="!getButtonCallableProp(button, 'show')"
        [uiDanger]="button.danger"
        [uiGhost]="button.ghost!"
        [uiLoading]="getButtonCallableProp(button, 'loading')"
        [uiShape]="button.shape!"
        [uiSize]="button.size!"
        [uiType]="button.type!"
        (click)="onButtonClick(button)"
        ui-button>
        {{ button.label }}
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #defaultFooterButtons>
  <button
    *ngIf="config.nzOkText !== null"
    [attr.cdkFocusInitial]="config.nzAutofocus === 'ok' || null"
    [disabled]="config.nzOkDisabled"
    [uiDanger]="config.nzOkDanger"
    [uiLoading]="customConfig.nzOkLoading"
    [uiShape]="customConfig.nzOkShape"
    [uiSize]="customConfig.nzOkSize"
    [uiType]="config.nzOkType!"
    (click)="onOk()"
    ui-button>
    {{ config.nzOkText || locale.okText }}
  </button>
  <button
    *ngIf="config.nzCancelText !== null"
    [attr.cdkFocusInitial]="config.nzAutofocus === 'cancel' || null"
    [disabled]="config.nzCancelDisabled"
    [uiLoading]="customConfig.nzCancelLoading"
    [uiShape]="customConfig.nzCancelShape"
    [uiSize]="customConfig.nzCancelSize"
    [uiType]="customConfig.nzCancelType"
    (click)="onCancel()"
    ui-button>
    {{ config.nzCancelText || locale.cancelText }}
  </button>
</ng-template>
