<ng-template>
  <ui-input-text-field
    [a11yRequired]="required"
    [borderless]="borderless"
    [debounceTime]="0"
    [displayMaxCharacterCount]="maxCharsCount > 0"
    [e2eTestId]="e2eTestId"
    [focusMe]="autofocus"
    [formControl]="formControl"
    [inputType]="inputType"
    [maxCharacterCount]="maxCharsCount"
    [readonly]="readonly"
    [uiId]="controlId"
    [uiPlaceholder]="placeholder"
    updateOn="change"></ui-input-text-field>
</ng-template>
