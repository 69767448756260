<ui-loading-indicator *ngIf="loading; else defaultArrow"></ui-loading-indicator>
<ng-template #defaultArrow>
  <ng-container *ngIf="!suffixIcon; else suffixTemplate">
    <i class="ui-arrow-custom fs-16px" *ngIf="!search" ui-icon uiType="chevron-down-small"></i>
    <i *ngIf="search" ui-icon uiSize="xs" uiType="search"></i>
  </ng-container>
  <ng-template #suffixTemplate>
    <ng-container *nzStringTemplateOutlet="suffixIcon; let suffixIcon">
      <i [uiType]="suffixIcon" ui-icon></i>
    </ng-container>
  </ng-template>
</ng-template>
