<div class="ant-notification ant-notification-topLeft" [class.ant-notification-rtl]="dir === 'rtl'" [style.left]="'0px'" [style.top]="top">
  <ui-notification *ngFor="let instance of topLeftInstances" [uiInstance]="instance" [uiPlacement]="config.nzPlacement" (uiDestroyed)="remove($event.id, $event.userAction)"></ui-notification>
</div>
<div class="ant-notification ant-notification-topRight" [class.ant-notification-rtl]="dir === 'rtl'" [style.right]="'0px'" [style.top]="top">
  <ui-notification *ngFor="let instance of topRightInstances" [uiInstance]="instance" [uiPlacement]="config.nzPlacement" (uiDestroyed)="remove($event.id, $event.userAction)"></ui-notification>
</div>
<div class="ant-notification ant-notification-bottomLeft" [class.ant-notification-rtl]="dir === 'rtl'" [style.bottom]="bottom" [style.left]="'0px'">
  <ui-notification *ngFor="let instance of bottomLeftInstances" [uiInstance]="instance" [uiPlacement]="config.nzPlacement" (uiDestroyed)="remove($event.id, $event.userAction)"></ui-notification>
</div>
<div class="ant-notification ant-notification-bottomRight" [class.ant-notification-rtl]="dir === 'rtl'" [style.bottom]="bottom" [style.right]="'0px'">
  <ui-notification *ngFor="let instance of bottomRightInstances" [uiInstance]="instance" [uiPlacement]="config.nzPlacement" (uiDestroyed)="remove($event.id, $event.userAction)"></ui-notification>
</div>
