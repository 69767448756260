<ng-container *ngIf="showCharacterCount">
  <ng-container *ngTemplateOutlet="textareaWithCounterTemplate"></ng-container>
</ng-container>
<ng-container *ngIf="!showCharacterCount">
  <ng-container *ngTemplateOutlet="textareaWithoutCounterTemplate"></ng-container>
</ng-container>

<ng-template #textareaWithCounterTemplate>
  <ui-textarea-count [displayCounter]="false" [uiMaxCharacterCount]="maxCharacterCount$ | async" (formatedCountChange)="onFormatedCountChange($event)">
    <div
      class="textarea-wrapper"
      [class.characters-on-focus]="maxCharacterCount && showMaxCharacterCount && showCharacterCountMode === 'focus'"
      [class.non-resizable]="!isManualResizeEnabled"
      [class.with-icon]="uiIconType && uiIconTheme"
      visible-textarea-content>
      <ng-container *ngIf="uiIconType && uiIconTheme">
        <ng-container *ngTemplateOutlet="textareaIconTemplate" visible-textarea-content></ng-container>
      </ng-container>
      <textarea
        #textareaRef
        [attr.aria-describedby]="
          applyStringWhenTrue('validation_hint', { condition: validationHint && !controlInvalid }) +
          applyStringWhenTrue(' ui_validation_hint', { condition: controlInvalid }) +
          applyStringWhenTrue(' textarea_counter', { condition: showCharacterCount })
        "
        [attr.aria-label]="ariaLabel"
        [attr.aria-readonly]="isReadonly"
        [attr.aria-required]="isRequired"
        [attr.data-test-id]="e2eTestId"
        [attr.id]="uiId"
        [attr.placeholder]="uiPlaceholder && !isReadonly ? uiPlaceholder : null"
        [class.borderless]="isBorderless"
        [class.error-state]="controlInvalid"
        [class.focused]="isFocused"
        [class]="textAreaClassName"
        [formControl]="control"
        [maxlength]="maxCharacterCount && enforceCharactersLimit ? maxCharacterCount : null"
        [ngStyle]="textAreaStyles"
        [nzAutosize]="isAutoresized"
        [readonly]="isReadonly"
        [rows]="visibleRowCount"
        (blur)="onBlur()"
        (focus)="onFocus()"
        aria-multiline="true"
        contenteditable
        ui-input></textarea>
      <ng-container *ngTemplateOutlet="textareaResizerTemplate" visible-textarea-content></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="textareaAfterContentTemplate" visible-textarea-content></ng-container>
  </ui-textarea-count>
</ng-template>

<ng-template #textareaWithoutCounterTemplate>
  <div class="textarea-wrapper" [class.non-resizable]="!isManualResizeEnabled" [class.with-icon]="uiIconType && uiIconTheme" visible-textarea-content>
    <ng-container *ngIf="uiIconType && uiIconTheme">
      <ng-container *ngTemplateOutlet="textareaIconTemplate" visible-textarea-content></ng-container>
    </ng-container>
    <textarea
      #textareaRef
      [attr.aria-describedby]="applyStringWhenTrue('validation_hint', { condition: validationHint && !controlInvalid }) + applyStringWhenTrue(' ui_validation_hint', { condition: controlInvalid })"
      [attr.aria-label]="ariaLabel"
      [attr.aria-readonly]="isReadonly"
      [attr.aria-required]="isRequired"
      [attr.data-test-id]="e2eTestId"
      [attr.id]="uiId"
      [attr.placeholder]="uiPlaceholder && !isReadonly ? uiPlaceholder : null"
      [class.borderless]="isBorderless"
      [class.error-state]="controlInvalid"
      [class]="textAreaClassName"
      [formControl]="control"
      [ngStyle]="textAreaStyles"
      [nzAutosize]="isAutoresized"
      [readonly]="isReadonly"
      [rows]="visibleRowCount"
      aria-multiline="true"
      contenteditable
      ui-input></textarea>
    <ng-container *ngTemplateOutlet="textareaResizerTemplate" visible-textarea-content></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="textareaAfterContentTemplate" visible-textarea-content></ng-container>
</ng-template>

<ng-template #textareaAfterContentTemplate>
  <div class="textarea-after-content sp-py-4" *ngIf="!isReadonly && showCharacterCount" [ngClass]="{ 'characters-on-focus': showCharacterCountMode === 'focus' }">
    <ng-container *ngTemplateOutlet="textareaMessageTemplate" visible-textarea-content></ng-container>
    <div
      class="char-counter sp-pl-4"
      id="textarea_counter"
      *ngIf="showCharacterCount"
      [ngClass]="{
        error:
          (controlInvalid && control.errors?.hasOwnProperty('required')) ||
          (formatedCharacterCount$ | async).isMaxLengthExceeded ||
          (enforceCharactersLimit && (formatedCharacterCount$ | async).isMaxLengthReached),
      }">
      {{ (formatedCharacterCount$ | async).value }}
    </div>
  </div>
</ng-template>

<ng-template #textareaMessageTemplate>
  <ng-container *ngTemplateOutlet="textareaInfoMessageTemplate" visible-textarea-content></ng-container>
  <ng-container *ngTemplateOutlet="textareaErrorMessageTemplate" visible-textarea-content></ng-container>
</ng-template>

<ng-template #textareaInfoMessageTemplate>
  <div class="validation-hint" id="validation_hint" *ngIf="validationHint && !controlInvalid">{{ validationHint }}</div>
</ng-template>

<ng-template #textareaErrorMessageTemplate>
  <ng-container *ngIf="control.errors">
    <ng-container *ngFor="let error of control.errors | keyvalue">
      <ui-validation-hint id="ui_validation_hint" *ngIf="error.value" [hintContent]="error.value" [isAccented]="isValidationHintAccented" hintType="error"></ui-validation-hint>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #textareaResizerTemplate>
  <i class="fs-10px text-color-gray-13 textarea-resizer" *ngIf="!isReadonly && isManualResizeEnabled" ui-icon uiType="resizer" visible-textarea-content></i>
</ng-template>

<ng-template #textareaIconTemplate>
  <i class="fs-16px textarea-icon" *ngIf="uiIconType && uiIconTheme" [uiTheme]="uiIconTheme" [uiType]="uiIconType" ui-icon></i>
</ng-template>
