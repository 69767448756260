<ui-card [uiBordered]="false" [uiExtra]="extraHeaderContentTemplate" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [canRemove]="true" [widgetId]="widgetId" (remove)="remove()" />
  </ng-template>

  <ui-alert *ngIf="error" [uiCloseable]="true" [uiMessage]="error" uiType="error"></ui-alert>

  <div class="boxes-container">
    <div class="boxes">
      <div
        class="box box-blue"
        *hasPermissionWhen="'ManageOKRsGranularPermissions'; flagIsOn: 'goals:create'; flagIsOff: 'ManageGoals'"
        (click)="openSelectHowToStartModal()"
        (keydown.enter)="openSelectHowToStartModal()"
        tabindex="0">
        <span class="icon-container"><i ui-icon uiType="goal"></i></span>
        <div class="title">{{ "set_company_and_team_objectives" | localize }}</div>
        <button class="ui-link" tabindex="-1">{{ "create_okr" | localize }}</button>
      </div>
      <div
        class="box box-purple"
        *hasAllPermissions="'users:invite'"
        [ghTrackMeta]="{ location: 'OnboardingWidget' }"
        (click)="openInvitationModal()"
        (keydown.enter)="openInvitationModal()"
        ghTrack="User Invited"
        tabindex="0">
        <span class="icon-container"><i ui-icon uiType="users-group"></i></span>
        <div class="title">{{ "add_colleagues_to_execute" | localize }}</div>
        <button class="ui-link" tabindex="-1">{{ "invite_teammate" | localize }}</button>
      </div>
      <span *hasPermissionWhen="'ManageOKRsGranularPermissions'; flagIsOn: 'goals:create'; flagIsOff: 'ManageGoals'">
        <div
          class="box box-purple"
          *hasAllPermissions="'users:invite'; negate: true"
          [ghTrackMeta]="{ location: 'OnboardingWidget' }"
          (click)="visitMarketplace()"
          (keydown.enter)="visitMarketplace()"
          ghTrack="Browse the Marketplace"
          tabindex="0">
          <span class="icon-container"><i ui-icon uiType="add-from-marketplace"></i></span>
          <div class="title">{{ "add_okr_templates_from_marketplace" | localize }}</div>
          <button class="ui-link" tabindex="-1" ui-external-link>
            {{ "browse_the_Marketplace" | localize }}
          </button>
        </div>
      </span>
      <div
        class="box box-yellow"
        [ghTrackMeta]="{ location: 'OnboardingWidget' }"
        (click)="createDemoAccount()"
        (keydown.enter)="createDemoAccount()"
        ghTrack="Check out Demo Account Clicked"
        tabindex="0">
        <span class="icon-container"><i ui-icon uiType="live-demo"></i></span>
        <div class="title">{{ "launch_live_demo" | localize }}</div>
        <button class="ui-link" tabindex="-1" ui-external-link>
          {{ "play_with_the_demo" | localize }}
        </button>
      </div>
    </div>
  </div>
</ui-card>
