import { IScope } from "angular";
import { StateDeclaration, TransitionService, UIRouterGlobals } from "@uirouter/angularjs";

const grayBackgroundPageColor = "#f1f3f8"; // gray-2

interface IRootLayoutScope extends IScope {
  backgroundColor?: string;
  useNewLayout?: boolean;
}

export class RootLayoutCtrl {
  static $inject = ["$scope", "$transitions", "$uiRouterGlobals"];

  constructor(
    private $scope: IRootLayoutScope,
    $transitions: TransitionService,
    $uiRouterGlobals: UIRouterGlobals
  ) {
    this.setScopePropertiesFromState($uiRouterGlobals.current);

    $scope.$on(
      "$destroy",
      $transitions.onSuccess({}, (trans) => {
        const to = trans.to();
        this.setScopePropertiesFromState(to);
      })
    );
  }

  private setScopePropertiesFromState(state: StateDeclaration): void {
    this.$scope.backgroundColor = state.data?.pageBackgroundColor;

    if (state.data?.isGrayBackgroundPageColor) {
      this.$scope.backgroundColor = grayBackgroundPageColor;
    }

    this.$scope.useNewLayout = state.data?.newLayout === true;
  }
}
