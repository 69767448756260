<ui-card [uiBordered]="false" [uiDescription]="'owned_by_me_and_my_teams' | localize" [uiExtra]="extraHeaderContentTemplate" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <active-okrs-group-sort-dropdown
      *featureToggleEnabled="'my-okrs-group-sort-dropdown'"
      [group]="groupBy"
      [sort]="sortBy"
      [trackMeta]="{
        screen_name: 'Home Dashboard',
        widget: 'my_okrs',
      }"
      (groupChange)="handleGroupChange($event)"
      (sortChange)="handleSortChange($event)"></active-okrs-group-sort-dropdown>
    <widget-actions [widgetId]="widgetId"></widget-actions>
  </ng-template>

  <assignee-active-okrs-list
    [assigneeId]="currentUserId"
    [groupBy]="groupBy"
    [sortBy]="sortBy"
    [trackMeta]="{ widget: 'my_okrs', name: 'home_customizable' }"
    assigneeType="user"></assignee-active-okrs-list>
</ui-card>
