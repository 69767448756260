import { IHttpService, IPromise, IQService, IRequestShortcutConfig, IRootScopeService } from "angular";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { filterActiveAssigneeIdsOrCurrentUserId } from "@gtmhub/assignees/redux/assignee-selectors";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { INgRedux } from "@gtmhub/state-management";
import { ICollection } from "@webapp/core/core.models";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";
import { ITaskFilter, NewTask, Task } from "@webapp/tasks/models/tasks.models";
import { UIErrorHandlingService } from "../../error-handling/services/error-handling-service";
import { TaskEvents } from "../task.events";

export class TaskService {
  public static $inject = ["$ngRedux", "$http", "EnvironmentService", "$q", "UIErrorHandlingService", "$rootScope"];
  private events: TaskEvents;

  constructor(
    private $ngRedux: INgRedux,
    private $http: IHttpService,
    private env: EnvironmentService,
    private $q: IQService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private $rootScope: IRootScopeService
  ) {
    this.events = new TaskEvents(this.$rootScope);
  }

  public create(task: NewTask, params: { gtmhubAdditionalParams?: { pluginId?: string; cloned?: boolean } } = {}): IPromise<Task> {
    const url = this.env.getApiEndpoint("/tasks");
    if (params.gtmhubAdditionalParams?.cloned) {
      // check if we have deactivated owners and remove them
      task.ownerId = filterActiveAssigneeIdsOrCurrentUserId(this.$ngRedux.getState<IAssigneesStoreState>(), Array.of(task.ownerId)).shift();
    }
    const config: IRequestShortcutConfig = {
      params: params,
    };
    return this.$http.post<Task>(url, task, config).then((response) => response.data);
  }

  public getList(filter: ITaskFilter): IPromise<ICollection<Task>> {
    const url = this.env.getApiEndpoint("/tasks");

    const query: IRequestShortcutConfig = {
      params: {},
    };

    if (filter) {
      query.params = filter;
    }

    return this.$http.get<ICollection<Task>>(url, query).then((response) => response.data);
  }

  public getOne(taskId: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<Task> {
    const url = this.env.getApiEndpoint("/tasks/" + taskId);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.get<Task>(url, config).then((response) => response.data);
  }

  public delete(id: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/tasks/" + id);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.delete(url, config).then(() => {
      this.events.broadcastTaskDeletedRequestSucceeded({ task: { id } });
    });
  }

  public update(id: string, task: Partial<Task>, params?: PluginGtmhubAdditionalParams): IPromise<Task> {
    const url = this.env.getApiEndpointV2("/tasks/" + id);

    const config: IRequestShortcutConfig = {
      params,
    };

    return this.$http.patch<Task>(url, task, config).then((response) => {
      this.events.broadcastTaskPatched({ update: response.data, task: { id, ...task } });
      return response.data;
    });
  }

  public updateStatus(id: string, status: string, params?: PluginGtmhubAdditionalParams): IPromise<Task> {
    const url = this.env.getApiEndpointV2("/tasks/" + id + "/status");

    const config: IRequestShortcutConfig = {
      params,
    };

    return this.$http.patch<Task>(url, { status }, config).then((response) => {
      this.events.broadcastTaskPatched({ update: response.data, task: { id, status } });
      return response.data;
    });
  }

  public getTasksV2(params: IRestLayerRequest, additionalParams?: PluginGtmhubAdditionalParams): IPromise<ICollection<Task>> {
    const url = this.env.getApiEndpointV2("/tasks");

    const config: IRequestShortcutConfig = {
      params: { ...params, ...additionalParams },
    };

    return this.$http.get<ICollection<Task>>(url, config).then((response) => response.data);
  }

  public cloneTasksRecursively(
    paginator: { currentPage: number; limit: number },
    reqData: { newParentIdsMap: { [oldId: string]: string }; parentIds: string[] }
  ): IPromise<Task[] | void> {
    const mongoTasksFilter = {
      filter: { parentId: { $in: reqData.parentIds } },
      fields: "name,description,status,parentId,parentType,dueDate,customFields,externalId,externalSystem,priority,taskType,ownerId,userEmail",
      skip: (paginator.currentPage - 1) * paginator.limit,
      take: paginator.limit,
    };

    return this.getTasksV2(mongoTasksFilter).then(
      (collection) => {
        const innerPromises: IPromise<Task>[] = collection.items.reduce((tasksPromises, task) => {
          if (task.ownerId) {
            const newTask = { ...task, parentId: reqData.newParentIdsMap[task.parentId] };
            delete newTask.id;
            tasksPromises.push(this.create(newTask, { gtmhubAdditionalParams: { cloned: true } }));
          }
          return tasksPromises;
        }, []);

        if (collection.totalCount <= paginator.currentPage * paginator.limit) {
          return this.$q.all(innerPromises);
        }

        return this.$q.all(innerPromises).then(
          () => {
            paginator.currentPage++;
            return this.cloneTasksRecursively(paginator, reqData);
          },
          (error) => this.uiErrorHandlingService.handleModal(error)
        );
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  }
}
