<ng-template
  [cdkConnectedOverlayHasBackdrop]="nzBackdrop"
  [cdkConnectedOverlayMinWidth]="$any(nzDropdownMatchSelectWidth ? null : triggerWidth)"
  [cdkConnectedOverlayOpen]="nzOpen"
  [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
  [cdkConnectedOverlayPanelClass]="'tree-select-cdk-overlay-pane'"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-select-tree-dropdown'"
  [cdkConnectedOverlayWidth]="$any(nzDropdownMatchSelectWidth ? triggerWidth : null)"
  (detach)="closeDropDown()"
  (overlayOutsideClick)="onClickOutside($event)"
  (positionChange)="onPositionChange($event)"
  cdkConnectedOverlay
  nzConnectedOverlay>
  <div
    [class.ant-select-dropdown-placement-bottomLeft]="dropDownPosition === 'bottom'"
    [class.ant-select-dropdown-placement-topLeft]="dropDownPosition === 'top'"
    [class.ant-tree-select-dropdown-rtl]="dir === 'rtl'"
    [dir]="dir"
    [ngClass]="dropdownClassName"
    [ngStyle]="nzDropdownStyle">
    <ui-tree
      #treeRef
      *ngIf="!uiLoading"
      [activatedNode]="activatedNode"
      [ariaLabel]="a11yLabel"
      [ariaLabelledby]="a11yLabelledby"
      [hidden]="isNotFound"
      [isTreeFlatten]="isTreeFlatten"
      [listboxId]="listboxId"
      [uiAsyncData]="nzAsyncData"
      [uiCheckable]="nzCheckable"
      [uiCheckedKeys]="nzCheckable ? value : []"
      [uiCheckStrictly]="nzCheckStrictly"
      [uiData]="nzNodes"
      [uiExpandAll]="nzDefaultExpandAll"
      [uiExpandedIcon]="nzExpandedIcon"
      [uiExpandedKeys]="expandedKeys"
      [uiHideUnMatched]="nzHideUnMatched"
      [uiMultiple]="nzMultiple"
      [uiOffset]="uiOffset"
      [uiSearchFunc]="nzSearchFunc"
      [uiSearchValue]="inputValue"
      [uiSelectedKeys]="!nzCheckable ? value : []"
      [uiShowExpand]="nzShowExpand"
      [uiShowIcon]="nzShowIcon"
      [uiShowLine]="nzShowLine"
      [uiTreeTemplate]="treeTemplate"
      [uiVirtualHeight]="nzVirtualHeight"
      [uiVirtualItemSize]="nzVirtualItemSize"
      [uiVirtualMaxBufferPx]="nzVirtualMaxBufferPx"
      [uiVirtualMinBufferPx]="nzVirtualMinBufferPx"
      (uiCheckBoxChange)="nzTreeCheckBoxChange.emit($event)"
      (uiCheckedKeysChange)="updateSelectedNodes()"
      (uiClick)="nzTreeClick.emit($event)"
      (uiExpandChange)="onExpandedKeysChange($event)"
      (uiSearchValueChange)="setSearchValues($event)"
      (uiSelectedKeysChange)="updateSelectedNodes()"
      nzBlockNode
      nzSelectMode></ui-tree>
    <span class="ant-select-not-found" *ngIf="!uiLoading && isNotFound">
      <ui-embed-empty *ngIf="!nzNotFoundContentReference" [uiSpecificContent]="nzNotFoundContent" uiComponentName="tree-select"></ui-embed-empty>
      <ui-embed-empty *ngIf="nzNotFoundContentReference" [uiSpecificContent]="nzNotFoundContentReference" uiComponentName="tree-select"></ui-embed-empty>
    </span>

    <span class="ant-select-not-found" *ngIf="inputValue.length > 0 && inputValue.length < 3">
      <ui-embed-empty *ngIf="uiSearchFieldRequiredCharsTemplate" [uiSpecificContent]="uiSearchFieldRequiredCharsTemplate" uiComponentName="tree-select"></ui-embed-empty>
    </span>

    <span class="ant-select-not-found" *ngIf="uiLoading">
      <ui-embed-empty [uiSpecificContent]="uiLoadingContentReference" uiComponentName="tree-select"></ui-embed-empty>
    </span>
  </div>
</ng-template>

<div class="ant-select-selector" cdkOverlayOrigin>
  <ng-container *ngIf="isMultiple">
    <ui-select-item
      *ngFor="let node of selectedNodes | slice: 0 : nzMaxTagCount; trackBy: trackValue"
      [deletable]="true"
      [disabled]="node.isDisabled || nzDisabled || readonly"
      [label]="nzDisplayWith(node)"
      (delete)="removeSelected(node, true)"></ui-select-item>

    <ui-select-item
      *ngIf="selectedNodes.length > nzMaxTagCount"
      [contentTemplateOutlet]="nzMaxTagPlaceholder"
      [contentTemplateOutletContext]="selectedNodes | slice: nzMaxTagCount"
      [deletable]="false"
      [disabled]="false"
      [label]="'+ ' + (selectedNodes.length - nzMaxTagCount) + ' ...'"></ui-select-item>
  </ng-container>

  <ui-select-search
    [a11yDescribedby]="a11yDescribedby"
    [a11yDescription]="searchInputAriaDescription"
    [a11yLabel]="a11yLabel"
    [a11yLabelledby]="a11yLabelledby"
    [a11yRequired]="a11yRequired"
    [activeDescendant]="activatedNode ? 'node-' + activatedNode.key : ''"
    [autofocus]="autofocus"
    [disabled]="nzDisabled"
    [focusMe]="focusMe"
    [focusTrigger]="nzOpen"
    [listboxId]="listboxId"
    [mirrorSync]="isMultiple"
    [nzId]="nzId"
    [nzOpen]="nzOpen"
    [placeholder]="nzPlaceHolder && selectedNodes.length === 0 ? nzPlaceHolder : ''"
    [popUpType]="isTreeFlatten ? 'listbox' : 'tree'"
    [readonly]="readonly"
    [showInput]="nzShowSearch"
    [triggerOpen]="triggerOpen"
    [value]="inputValue"
    (isComposingChange)="isComposing = $event"
    (keydown)="onKeyDownInput($event)"
    (valueChange)="setInputValue($event)"></ui-select-search>

  <ui-select-placeholder *ngIf="nzPlaceHolder && selectedNodes.length === 0" [placeholder]="nzPlaceHolder" [style.display]="placeHolderDisplay"></ui-select-placeholder>

  <ui-select-item
    *ngIf="!isMultiple && selectedNodes.length === 1 && !isComposing && inputValue === ''"
    [deletable]="false"
    [disabled]="false"
    [label]="nzDisplayWith(selectedNodes[0])"
    [uiSelectItemContext]="{ $implicit: selectedNodes[0], origin: selectedNodes[0] }"
    [uiSelectItemTemplate]="selectItemTemplate">
  </ui-select-item>

  <ui-select-clear *ngIf="nzAllowClear && !nzDisabled && !readonly && selectedNodes.length" [clearIcon]="nzClearIcon" (clear)="onClearSelection()"></ui-select-clear>
</div>

<ui-select-arrow *ngIf="!isMultiple"></ui-select-arrow>
