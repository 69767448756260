import { StateParams, UIRouterGlobals } from "@uirouter/angular";
import { MISSING_SESSION_ID_STATE_PARAM } from "@gtmhub/goals/models";
import { compoundSuffix } from "@gtmhub/search/services/search.service";
import { buildSearchConditions } from "@gtmhub/shared/utils/search-utils";
import { IViewHistoryItemsGroupedReqParams, ViewHistoryItemGrouped } from "@gtmhub/view-history";
import { localize } from "@webapp/localization/utils/localization.utils";
import { ISearchRequestsBody } from "@webapp/search/models/search-api.models";
import {
  FacetIcon,
  FocusParams,
  GlobalSearchItemReturnState,
  GlobalSearchResult,
  IGlobalMultiSearchItems,
  ITEMS_LIMIT_IF_EVERYWHERE,
  ITEMS_LIMIT_IF_NOT_EVERYWHERE,
  RecentFacetLocalisationMap,
  RecentSearchFacetsOptions,
  Search,
  SearchCollection,
  SearchConditionSettings,
  SearchFacetsIconTypes,
  SearchFacetsOptions,
  SearchFacetsOptionsEnum,
  SearchFacetsOptionsMap,
  SearchItemsLimit,
  SearchMultiDTO,
  SearchQueryParams,
  fieldsToSearchByMap,
  recentItemsTypes,
  responseFieldsMap,
  searchCollectionsTranslationMap,
} from "@webapp/search/models/search.models";

const getSearchFields = (searchCollection: string[]): { name: string }[] => {
  return searchCollection.map((field) => {
    return {
      name: field,
    };
  });
};

const getSearchBodyForSingleCollection = (facet: string, settings: SearchConditionSettings): ISearchRequestsBody[] => {
  const isCompound = searchCollectionsTranslationMap[facet].length > 1;

  return searchCollectionsTranslationMap[facet].map((collection) => {
    const collectionKey = isCompound ? collection + compoundSuffix : collection;
    return {
      collectionName: collection,
      searchFields: getSearchFields(fieldsToSearchByMap[collectionKey]),
      responseFields: responseFieldsMap[collectionKey],
      searchConditions: buildSearchConditions(collection, settings),
    };
  });
};

export const getSearchBodyV2 = (searchTerm: string, facets: string[], settings: SearchConditionSettings = {}): SearchMultiDTO => {
  const searchFields = {} as Record<string, ISearchRequestsBody[]>;

  facets.forEach((f) => {
    searchFields[f] = getSearchBodyForSingleCollection(f, settings);
  });
  return {
    searchTerm: searchTerm,
    operator: 0,
    searchRequests: searchFields,
  };
};

export const convertSearchResponseToOrderedArray = (searchResults: IGlobalMultiSearchItems): GlobalSearchResult[] => {
  const result: GlobalSearchResult[] = [] as GlobalSearchResult[];
  Object.keys(searchResults.items).forEach((key: SearchFacetsOptionsEnum) => {
    if (searchResults.items[key].items.length > 0) {
      result.push({ ...searchResults.items[key], collectionName: key });
    }
  });
  return result.sort((a, b) => b.maxScore - a.maxScore);
};

/*
 * Setting fake max score for each group to be able to sort them by max score when showing recent items('everywhere' facet)
 * */
const getMaxScoreByGroupName = (groupName: RecentSearchFacetsOptions): number => {
  switch (groupName) {
    case SearchFacetsOptionsEnum.OKRs: {
      return 100;
    }
    case SearchFacetsOptionsEnum.Employees: {
      return 90;
    }
    case SearchFacetsOptionsEnum.Teams: {
      return 80;
    }
    default: {
      return 0;
    }
  }
};

export const convertRecentResponseToOrderedArray = (
  recentItems: ViewHistoryItemGrouped[],
  facet: RecentSearchFacetsOptions,
  totalCount: number
): GlobalSearchResult[] => {
  const result: GlobalSearchResult[] = [] as GlobalSearchResult[];
  const group: GlobalSearchResult = {
    collectionName: facet,
    items: [],
    maxScore: getMaxScoreByGroupName(facet),
    totalCount,
  };

  recentItems.forEach((recentItem) => {
    const item = {
      // Adding 's' to the collection name to match the search collection name
      collectionName: `${recentItem.type}s` as SearchCollection,
      fields: { ...recentItem[recentItem.type] },
      id: recentItem.itemId,
      score: 0,
    };
    group.items.push(item);
  });

  result.push(group);

  return result.sort((a, b) => b.maxScore - a.maxScore);
};

export const getIcon = (groupName: SearchCollection): string => {
  switch (groupName) {
    case "sessions":
    case "goals":
    case "metrics":
    case "tasks":
    case "automations":
    case "whiteboards":
    case "lists":
    case "insights":
    case "kpis": {
      return groupName.slice(0, groupName.length - 1);
    }
    case "filters": {
      return "filtered-view";
    }
    case "dashboards": {
      return "insightboard";
    }
    case "kpiviews": {
      return "filtered-view-kpis";
    }
    default: {
      return undefined;
    }
  }
};

export const getItemType = (groupName: SearchCollection): string => {
  const itemTypeMap: Record<Exclude<SearchCollection, "teams" | "roles" | "users">, string> = {
    sessions: "Session",
    tasks: "Task",
    automations: "Automation",
    whiteboards: "Whiteboard",
    insights: "Insight",
    goals: "Objective",
    metrics: "Key Result",
    lists: "Report",
    filters: "OKR View",
    kpis: "Key Performance Indicator",
    dashboards: "Insightboard",
    kpiviews: "Kpi View",
  };

  return itemTypeMap[groupName] || "";
};

/*
 * @param to - 'next' or 'prev' item
 * @param siblingSelectors - string of selectors separated by comma Ex: "#search-input, #search-submit-button"
 * @param parentElementId - id of the element to focus if the user is on the first item in the list and presses the up arrow key
 * @returns shouldClosePopover {boolean} - true if the user is on the first item in the list and presses the ArrowUp key
 * @description - Moves focus to the next or previous item of the current focused element
 * */
export const moveFocusTo = (params: FocusParams = { to: "next", siblingSelectors: "", parentElementId: "" }): boolean => {
  let shouldClosePopover = false;
  const searchItems = [...document.querySelectorAll(params.siblingSelectors)];

  const ind = searchItems.indexOf(document.activeElement) + (params.to === "next" ? 1 : -1);

  // When the last item is focused and the user presses the down arrow key, the focus should move to the first item
  const isOnLastItem = ind === searchItems.length;
  // When the first item is focused and the user presses the up arrow key, the focus should move to the search input
  const isOnFirstItem = ind === -1;

  let elementToFocus: HTMLElement;

  if (isOnLastItem) {
    elementToFocus = searchItems[0] as HTMLElement;
  } else if (isOnFirstItem) {
    elementToFocus = document.getElementById(params.parentElementId);
    shouldClosePopover = true;
  } else {
    elementToFocus = searchItems[ind] as HTMLElement;
  }

  elementToFocus?.focus();
  return shouldClosePopover;
};

export const getItemReturnState = (collectionName: SearchCollection, item: Search<SearchCollection>): GlobalSearchItemReturnState => {
  switch (collectionName) {
    case "sessions": {
      return {
        state: "gtmhub.goals.all",
        stateParams: { planningSessionId: item.id, page: 1 },
      };
    }
    case "goals": {
      const sessionId = item.fields["sessionId"] || MISSING_SESSION_ID_STATE_PARAM;

      return {
        state: "gtmhub.goals.all.goal",
        stateParams: { id: item.id, planningSessionId: sessionId },
      };
    }
    case "metrics": {
      const sessionId = item.fields["sessionId"] || MISSING_SESSION_ID_STATE_PARAM;
      return {
        state: "gtmhub.goals.all.goal.metric",
        stateParams: { id: item.fields["goalId"], planningSessionId: sessionId, metricId: item.id },
      };
    }
    case "filters": {
      return {
        state: "gtmhub.okrView",
        stateParams: { okrViewId: item.id },
      };
    }
    case "tasks": {
      return {
        state: "gtmhub.tasks.task",
        stateParams: { taskId: item.id },
      };
    }
    case "lists": {
      return {
        state: "gtmhub.list",
        stateParams: { listId: item.id, isNewList: false },
      };
    }
    case "automations": {
      return {
        state: "gtmhub.automation.rules",
        stateParams: { automationId: item.id },
      };
    }
    case "teams": {
      return {
        state: "gtmhub.teams.team",
        stateParams: { teamId: item.id },
      };
    }
    case "users": {
      return {
        state: "gtmhub.employees.employee",
        stateParams: { employeeId: item.id },
      };
    }
    case "kpis": {
      return {
        state: "gtmhub.kpis.list.details",
        stateParams: { kpiId: item.id },
      };
    }
    case "dashboards": {
      return {
        state: "gtmhub.insightboard",
        stateParams: { dashboardId: item.id },
      };
    }
    case "whiteboards": {
      return {
        state: "gtmhub.whiteboard",
        stateParams: { whiteboardId: item.id },
      };
    }
    case "kpiviews": {
      return {
        state: "gtmhub.kpis.views.kpiView",
        stateParams: { kpiViewId: item.id },
      };
    }
  }
};

const getViewHistoryRejectedIdsByCurrentState = (currentState: string, stateParams: StateParams): string[] => {
  const currentPageItemIds: string[] = [];

  switch (currentState) {
    case "gtmhub.goals.all": {
      currentPageItemIds.push(stateParams.planningSessionId);
      break;
    }
    case "gtmhub.insightboard": {
      currentPageItemIds.push(stateParams.dashboardId);
      break;
    }
    case "gtmhub.list": {
      currentPageItemIds.push(stateParams.listId);
      break;
    }
  }

  return currentPageItemIds;
};

export const getFacetsAndQueryParams = (
  currentFacets: SearchFacetsOptions[],
  facetOptionsMap: SearchFacetsOptionsMap,
  params: SearchQueryParams
): { facets: string[]; queryParams: SearchQueryParams } => {
  let facets: SearchFacetsOptions[] = [];
  const queryParams: SearchQueryParams = { skip: 0, take: ITEMS_LIMIT_IF_EVERYWHERE };

  if (currentFacets.length === 1 && currentFacets[0] === SearchFacetsOptionsEnum.Everywhere) {
    facets = Object.values(facetOptionsMap).filter((f) => f !== SearchFacetsOptionsEnum.Everywhere && f !== SearchFacetsOptionsEnum.Roles);
  } else {
    currentFacets.forEach((cf) => {
      if (facetOptionsMap[cf]) {
        facets.push(cf);
      }
    });
    queryParams.take = params?.take || ITEMS_LIMIT_IF_NOT_EVERYWHERE;
    queryParams.skip = params?.skip || 0;
  }
  return { facets, queryParams };
};

const getMongoQuery = (
  facet: RecentSearchFacetsOptions,
  routerGlobals: UIRouterGlobals,
  itemsPerSection: SearchItemsLimit = 4
): { query: IViewHistoryItemsGroupedReqParams; title: RecentSearchFacetsOptions } => {
  return {
    query: {
      filter: {
        $and: [
          {
            type: {
              $in: recentItemsTypes[facet],
            },
          },
          {
            source: {
              $in: ["search"],
            },
          },
        ],
      },
      rejectedIds: getViewHistoryRejectedIdsByCurrentState(routerGlobals.current.name, routerGlobals.params),
      skip: 0,
      limit: itemsPerSection,
    },
    title: facet,
  };
};

export const getRecentItemsMongoQueries = (
  facet: RecentSearchFacetsOptions,
  routerGlobals: UIRouterGlobals
): { query: IViewHistoryItemsGroupedReqParams; title: RecentSearchFacetsOptions }[] => {
  const queries: { query: IViewHistoryItemsGroupedReqParams; title: RecentSearchFacetsOptions }[] = [];
  if (facet === SearchFacetsOptionsEnum.Everywhere) {
    queries.push(getMongoQuery(SearchFacetsOptionsEnum.OKRs, routerGlobals));
    queries.push(getMongoQuery(SearchFacetsOptionsEnum.Teams, routerGlobals));
    queries.push(getMongoQuery(SearchFacetsOptionsEnum.Employees, routerGlobals));
    queries.push(getMongoQuery("otherItems", routerGlobals));
  } else {
    queries.push(getMongoQuery(facet, routerGlobals, ITEMS_LIMIT_IF_NOT_EVERYWHERE));
  }
  return queries;
};

export const getItemsLimit = (facet: RecentSearchFacetsOptions): SearchItemsLimit => {
  return facet !== SearchFacetsOptionsEnum.Everywhere ? ITEMS_LIMIT_IF_NOT_EVERYWHERE : ITEMS_LIMIT_IF_EVERYWHERE;
};

export const getSearchFacetsOptionsLocalizationMap = (): RecentFacetLocalisationMap => {
  return {
    otherItems: localize("other"),
    [SearchFacetsOptionsEnum.Automations]: localize("automations"),
    [SearchFacetsOptionsEnum.Employees]: localize("employees"),
    [SearchFacetsOptionsEnum.Everywhere]: localize("everywhere"),
    [SearchFacetsOptionsEnum.Insightboards]: localize("insightboards"),
    [SearchFacetsOptionsEnum.Insights]: localize("insights"),
    [SearchFacetsOptionsEnum.KPIs]: localize("kpis"),
    [SearchFacetsOptionsEnum.Reports]: localize("reports"),
    [SearchFacetsOptionsEnum.Roles]: localize("roles"),
    [SearchFacetsOptionsEnum.OKRs]: localize("okrs"),
    [SearchFacetsOptionsEnum.OkrViews]: localize("okr_views"),
    [SearchFacetsOptionsEnum.Sessions]: localize("sessions"),
    [SearchFacetsOptionsEnum.Tasks]: localize("tasks"),
    [SearchFacetsOptionsEnum.Teams]: localize("teams"),
    [SearchFacetsOptionsEnum.Whiteboards]: localize("whiteboards"),
    [SearchFacetsOptionsEnum.KpiViews]: localize("kpi_views"),
  };
};

export const facetIcons: FacetIcon[] = [
  {
    facet: SearchFacetsOptionsEnum.Sessions,
    type: SearchFacetsIconTypes.Session,
    tooltipTitle: localize(SearchFacetsIconTypes.Session),
  },
  {
    facet: SearchFacetsOptionsEnum.OKRs,
    type: SearchFacetsIconTypes.Goal,
    tooltipTitle: localize("objective"),
  },
  {
    facet: SearchFacetsOptionsEnum.Tasks,
    type: SearchFacetsIconTypes.Task,
    tooltipTitle: localize(SearchFacetsIconTypes.Task),
  },
  {
    facet: SearchFacetsOptionsEnum.Reports,
    type: SearchFacetsIconTypes.List,
    tooltipTitle: localize(SearchFacetsIconTypes.List),
  },
  {
    facet: SearchFacetsOptionsEnum.Automations,
    type: SearchFacetsIconTypes.Automation,
    tooltipTitle: localize(SearchFacetsIconTypes.Automation),
  },
  {
    facet: SearchFacetsOptionsEnum.Teams,
    type: SearchFacetsIconTypes.Team,
    tooltipTitle: localize(SearchFacetsOptionsEnum.Teams),
  },
  {
    facet: SearchFacetsOptionsEnum.Employees,
    type: SearchFacetsIconTypes.Employee,
    tooltipTitle: localize(SearchFacetsIconTypes.Employee),
  },
  {
    facet: SearchFacetsOptionsEnum.KPIs,
    type: SearchFacetsIconTypes.Kpi,
    tooltipTitle: localize(SearchFacetsIconTypes.Kpi),
  },
  {
    facet: SearchFacetsOptionsEnum.Insights,
    type: SearchFacetsIconTypes.Insight,
    tooltipTitle: localize(SearchFacetsIconTypes.Insight),
  },
  {
    facet: SearchFacetsOptionsEnum.Insightboards,
    type: SearchFacetsIconTypes.Insightboard,
    tooltipTitle: localize(SearchFacetsIconTypes.Insightboard),
  },
  {
    facet: SearchFacetsOptionsEnum.Whiteboards,
    type: SearchFacetsIconTypes.Whiteboard,
    tooltipTitle: localize(SearchFacetsIconTypes.Whiteboard),
  },
  {
    facet: SearchFacetsOptionsEnum.OkrViews,
    type: SearchFacetsIconTypes.OkrViews,
    tooltipTitle: localize("okr_view"),
  },
  {
    facet: SearchFacetsOptionsEnum.KpiViews,
    type: SearchFacetsIconTypes.Kpi,
    tooltipTitle: localize("kpi_views"),
  },
];
