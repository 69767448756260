<button
  class="centered-vert hov-cursor-p justify-cont-c"
  [attr.aria-label]="isFavorite ? 'Remove from favorites' : 'Add to favorites'"
  [attr.aria-pressed]="isFavorite"
  (click)="toggleFavorite()"
  tabindex="0"
  ui-button
  uiType="link">
  <i class="fs-16px" *ngIf="!isFavorite" ui-icon uiTheme="outline" uiType="favorite"></i>
  <i class="fs-16px" *ngIf="isFavorite" ui-icon uiTheme="fill" uiType="favorite"></i>
</button>
