import { Injectable } from "@angular/core";
import { StorageService } from "@webapp/core/storage/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class LastUrlService {
  public readonly keepLocationKey = "keepLocation";

  constructor(private storageService: StorageService) {}

  public storeCurrentLocation(toPath: string): void {
    // This service is interested in the relative-to-the-webapp path, so we strip the /results in front if it exists
    toPath = toPath.replace("/results", "").replace("results/", "");
    // don't store default page or /error page as last page url
    const errorPageSuffix = "/error";
    if (toPath === "/" || toPath.indexOf(errorPageSuffix, toPath.length - errorPageSuffix.length) >= 0) {
      return;
    }

    this.storeLocationPath(toPath);

    const url = new URL(window.location.href.replace("#/", ""));
    const queryParams = Object.fromEntries(new URLSearchParams(url.search));
    this.storeCurrentLocationSearch(queryParams);
  }

  public storeLocationPath(locationPath: string): void {
    this.storageService.set("originalReqPath", locationPath);
  }

  public storeCurrentLocationSearch(locationSearch: Record<string, unknown>): void {
    this.storageService.set("originalReqSearch", locationSearch);
  }

  public getStoredLocationSearch(): string {
    return this.storageService.get<string>("originalReqSearch");
  }

  public getStoredLocationPath(): string {
    return this.storageService.get<string>("originalReqPath");
  }

  public clear(): void {
    this.storageService.remove("originalReqSearch");
    this.storageService.remove("originalReqPath");
  }
}
