import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, noop, tap } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { KpiEventsMediatorService } from "@webapp/kpis/components/kpis-grid-page/kpis-grid/services/kpi-events.mediator.service";
import { KpiProjection, KpiProjectionDTO, KpiProjectionOpenedFrom as KpiProjectionFormOpenedFrom } from "@webapp/kpis/models/kpi-projections.models";
import { KpiProjectionsApiService } from "./kpi-projections-api.service";
import { KpiProjectionsState } from "./kpi-projections-state.service";

@Injectable({
  providedIn: "any",
})
export class KpiProjectionsFacade extends BaseFacade<KpiProjection, KpiProjectionDTO, KpiProjectionsState, KpiProjectionsApiService> {
  constructor(
    state: KpiProjectionsState,
    api: KpiProjectionsApiService,
    private kpiEventsMediator: KpiEventsMediatorService
  ) {
    super(state, api);
  }

  public getProjectionsOfKpi$(kpiId: string): Observable<ICollection<KpiProjection>> {
    return this.get$(kpiId, {
      ...new RequestConfig(),
      url: this.apiService.getProjectionsForKpiEndpoint(kpiId),
    });
  }

  public createProjectionForKpi$(kpiId: string, projectionPayload: KpiProjectionDTO, projectionCreatedFrom: KpiProjectionFormOpenedFrom): Observable<KpiProjection> {
    const gtmhubAdditionalParams = {
      from: projectionCreatedFrom,
    };

    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };

    return this.post$(projectionPayload, { ...requestConfig, url: this.apiService.getCreateEndpoint(kpiId) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public updateProjection$(kpiId: string, projectionId: string, projectionPayload: KpiProjectionDTO, from: "chart modal" | "dragging"): Observable<KpiProjection> {
    const gtmhubAdditionalParams = {
      from: from,
    };

    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };
    return this.patch$(kpiId, projectionPayload, { ...requestConfig, url: this.apiService.getPatchEndpoint(kpiId, projectionId) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public deleteProjection$(kpiId: string, projectionId: string): Observable<void> {
    return this.delete$(kpiId, { ...new RequestConfig(), url: this.apiService.getDeleteEndpoint(kpiId, projectionId) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      }),
      map(noop)
    );
  }
}
