import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KpiEventsMediatorService {
  private kpisChanged$ = new Subject<void>();

  public emitKpisChanged(): void {
    this.kpisChanged$.next();
  }

  public getKpisChanged$(): Observable<void> {
    return this.kpisChanged$.asObservable();
  }
}
