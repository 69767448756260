<ng-template #itemsTpl>
  <div class="ant-list-items">
    <ng-container *ngFor="let item of nzDataSource; let index = index">
      <ng-template [ngTemplateOutlet]="nzRenderItem" [ngTemplateOutletContext]="{ $implicit: item, index: index }"></ng-template>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-template>

<ui-list-header *ngIf="nzHeader">
  <ng-container *nzStringTemplateOutlet="nzHeader">{{ nzHeader }}</ng-container>
</ui-list-header>
<ng-content select="ui-list-header"></ng-content>

<ui-loading-indicator [uiSpinning]="nzLoading" uiSize="small"></ui-loading-indicator>

<ng-container>
  <div *ngIf="nzLoading && nzDataSource && nzDataSource.length === 0" [style.min-height.px]="53"></div>
  <div *ngIf="nzGrid && nzDataSource; else itemsTpl" [uiGutter]="nzGrid.gutter || null" ui-row>
    <div
      *ngFor="let item of nzDataSource; let index = index"
      [uiLg]="nzGrid.lg || null"
      [uiMd]="nzGrid.md || null"
      [uiSm]="nzGrid.sm || null"
      [uiSpan]="nzGrid.span || null"
      [uiXl]="nzGrid.xl || null"
      [uiXs]="nzGrid.xs || null"
      [uiXXl]="nzGrid.xxl || null"
      ui-col>
      <ng-template [ngTemplateOutlet]="nzRenderItem" [ngTemplateOutletContext]="{ $implicit: item, index: index }"></ng-template>
    </div>
  </div>
  <ui-list-empty *ngIf="!nzLoading && nzDataSource && nzDataSource.length === 0" [uiNoResult]="nzNoResult"></ui-list-empty>
</ng-container>

<ui-list-footer *ngIf="nzFooter">
  <ng-container *nzStringTemplateOutlet="nzFooter">{{ nzFooter }}</ng-container>
</ui-list-footer>
<ng-content select="ui-list-footer, [ui-list-footer]"></ng-content>

<ng-template [ngTemplateOutlet]="nzLoadMore"></ng-template>
<ng-content select="ui-list-load-more, [ui-list-load-more]"></ng-content>

<ui-list-pagination *ngIf="nzPagination">
  <ng-template [ngTemplateOutlet]="nzPagination"></ng-template>
</ui-list-pagination>
<ng-content select="ui-list-pagination, [ui-list-pagination]"></ng-content>
