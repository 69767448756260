import { Injectable } from "@angular/core";
import { NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";

@Injectable({
  providedIn: "any",
})
export class NavItemsSectionBroadcastConfigFactory {
  public buildDefaultNavItemsSectionBroadcastConfig(): NavItemsSectionBroadcastConfig {
    return new NavItemsSectionBroadcastConfig();
  }

  public buildSessionNavItemsSectionBroadcastConfig({
    isOkrViewsFeatureAvailable,
    currentStateName,
    currentSubNavItemIdOnDisplay,
  }: {
    isOkrViewsFeatureAvailable: boolean;
    currentStateName: string;
    currentSubNavItemIdOnDisplay: string;
  }): NavItemsSectionBroadcastConfig {
    const supportedStatesRequireCustomEventHandler = ["gtmhub.goals.all", "gtmhub.goals.alignmentSidebar", "gtmhub.okrView"];
    const currentStateRequiresCustomEventHandler = supportedStatesRequireCustomEventHandler.some((supportedState) => currentStateName.startsWith(supportedState));
    const doesAnyFeatureRequireCustomEventHandler = isOkrViewsFeatureAvailable;

    return {
      ...new NavItemsSectionBroadcastConfig(),
      navItemContextMenuActions: {
        shareLinkToNavItem(navItemId: string): boolean {
          const isCurrentItemOnDisplay = navItemId === currentSubNavItemIdOnDisplay;

          return doesAnyFeatureRequireCustomEventHandler && currentStateRequiresCustomEventHandler && isCurrentItemOnDisplay;
        },
      },
    };
  }

  public buildKpiNavItemsSectionBroadcastConfig(): NavItemsSectionBroadcastConfig {
    return {
      ...new NavItemsSectionBroadcastConfig(),
    };
  }
}
