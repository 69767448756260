<form [formGroup]="selectorForm" ui-form>
  <ui-form-item>
    <ui-form-control [uiXs]="24">
      <ui-tree-select
        #treeSelect
        *ngIf="!readonly"
        [a11yLabelledby]="a11yLabelledby"
        [a11yRequired]="a11yRequired"
        [autofocus]="autofocus"
        [isTreeFlatten]="!isEmptyInputValue"
        [triggerOpen]="triggerOpen"
        [uiAllowClear]="true"
        [uiAsyncData]="true"
        [uiClearIcon]="clearIcon"
        [uiDisabled]="disabled"
        [uiDropdownMatchSelectWidth]="matchSelectWidth"
        [uiId]="uiId"
        [uiLoading]="showLoading"
        [uiLoadingContentReference]="loading"
        [uiNodes]="visualizationNodes"
        [uiNotFound]="showNotFoundMsg"
        [uiNotFoundContentReference]="nzNotFoundContentReference"
        [uiOffset]="treeOffset"
        [uiPlaceHolder]="treeSelectPlaceholder"
        [uiSearchFieldRequiredCharsTemplate]="uiSearchFieldRequiredCharsTemplate"
        [uiSearchFunc]="onSearchFunc"
        [uiSelectedKeys]="selectedNode ? [selectedNode.key] : []"
        [uiSelectItemTemplate]="selectItemTemplate"
        [uiShowExpand]="treeSelectExpandIconCondition"
        [uiTreeTemplate]="nzTreeTemplate"
        (uiExpandChange)="expandChange.emit($event)"
        (uiOpenChange)="onOpenChangeHandler({ isOpen: $event })"
        (uiSearchValueChange)="onSearchValueChange($event)"
        formControlName="entity"
        uiDropdownClassName="multi-selector-suggestions"
        uiHideUnMatched
        uiShowIcon
        uiShowSearch>
      </ui-tree-select>
      <div
        class="multi-selector-readonly"
        *ngIf="readonly"
        [attr.aria-labelledby]="a11yLabelledby"
        [attr.aria-required]="a11yRequired"
        aria-readonly="true"
        data-test-id="multi-selector-readonly"
        tabindex="0">
        <ui-chip class="sp-mt-3" *ngIf="selectedNode" [uiCloseable]="!disabled && !readonly" [uiIconType]="selectedNode?.icon" uiIconTheme="fill">
          {{ selectedNode?.title }}
        </ui-chip>
      </div>
    </ui-form-control>
  </ui-form-item>
</form>

<ng-template #loading>
  <gh-indicator class="sp-ml-8" [ghMessage]="'loading' | localize" [ghValue]="{ progress: true }"></gh-indicator>
</ng-template>

<ng-template #uiSearchFieldRequiredCharsTemplate>
  <div class="no-results-found d-flex justify-cont-sb align-items-c ml-16px">{{ "search_field_must_contain_al_three_chars" | localize }}</div>
</ng-template>

<ng-template #nzNotFoundContentReference>
  <div class="no-results-found d-flex justify-cont-sb align-items-c ml-16px" *ngIf="showNotFoundMsg" data-test-id="tree-node-item-empty">
    {{ isEmptyInputValue ? noRecentsMessage : noResultsFoundMessage }}
  </div>
</ng-template>

<ng-template #selectItemTemplate let-node>
  <ui-chip
    class="sp-m-3 selected-item-chip custom-max-width"
    [uiCloseable]="removeChipEnabled && !disabled && !readonly"
    [uiIconType]="node.icon"
    (uiOnChipClick)="selectedItemChipClick.emit()"
    (uiOnClose)="removeSelectedItem()"
    uiIconTheme="fill">
    {{ node.title }}
  </ui-chip>
</ng-template>

<ng-template #nzTreeTemplate let-node>
  <div
    class="ant-tree-node-content-wrapper"
    *ngIf="!node.origin.noOKRsAvailable && !node.origin.noResultsFound"
    [ngClass]="{
      'header-node': node.origin.isHeader,
      'read-only': node.origin.readonly || node.origin.disabled,
    }"
    [uiTooltipTitle]="node.origin.tooltip"
    ui-row
    ui-tooltip
    uiTooltipPlacement="right"
    uiTooltipTrigger="hover">
    <div class="node-icon mr-5px">
      <i class="fs-16px" [uiType]="node.icon" ui-icon uiTheme="fill"></i>
    </div>
    <ng-container *ngIf="!nodeTitleTemplate">
      <div class="node-title" ui-typography uiEllipsis>
        {{ node.title }}
      </div>
    </ng-container>
    <ng-container *ngIf="nodeTitleTemplate">
      <ng-container [ngTemplateOutlet]="nodeTitleTemplate" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
    </ng-container>
    <div class="selected-icon" *ngIf="node.isSelected">
      <i class="fs-14px" ui-icon uiTheme="fill" uiType="check-white"></i>
    </div>
    <div class="node-assignees" [ngStyle]="{ 'max-width.px': 20 * (node.origin.ownerIds && node.origin.ownerIds.length < 5 ? node.origin.ownerIds.length : 6) }">
      <ui-assignee-avatar-group *ngIf="node.origin.ownerIds" [uiAlignRight]="true" [uiMax]="5" uiSize="xs">
        <ui-assignee-avatar *ngFor="let assigneeId of node.origin.ownerIds" [fromAssigneeId]="assigneeId" />
      </ui-assignee-avatar-group>
    </div>
  </div>
  <div class="ant-tree-node-content-wrapper no-okrs-available" *ngIf="node.origin.noOKRsAvailable" ui-row ui-typography uiEllipsis>{{ "no_okrs_available_to_align" | localize }}</div>
  <div class="ant-tree-node-content-wrapper no-okrs-available" *ngIf="node.origin.noResultsFound" ui-row ui-typography uiEllipsis>{{ "no_results_found" | localize }}</div>
</ng-template>

<ng-template #clearIcon>
  <i ui-icon uiType="close-big"></i>
</ng-template>
