<ul
  id="gh-dropdown-menu"
  [attr.data-test-id]="testIdSuffix ? 'dropdown-actions-menu-for-' + testIdSuffix : 'dropdown-actions-menu-for'"
  [ngClass]="dropdownMenuClass"
  (keydown)="menuKeyDown.emit($event)"
  role="menu"
  ui-menu>
  <ng-container *ngFor="let item of menuItems; let index = index">
    <li
      *ngIf="(!item.isVisible$ || (item.isVisible$ | async)) && !item.subitems"
      [activeDropdownItemChange$]="activeDropdownItemChange$"
      [attr.data-test-id]="item.dataTestId"
      [class.active-dropdown-item]="isItemActive(item)"
      [class.destructive]="item.isDestructive"
      [class.gh-separator-top]="item.startOfSection && !showNewDesign"
      [class.gh-separator]="item.endOfSection && !showNewDesign"
      [class.menu-item-info-text]="item.isInfoTextOnly"
      [isActive]="isItemActive(item)"
      [item]="item"
      [ngClass]="item.classes"
      [showNewDesign]="showNewDesign"
      (click)="closeMenu.emit(item)"
      (closeGroup)="closeGroup()"
      gh-dropdown-menu-item
      role="menuitem"></li>
    <ui-submenu-title *ngIf="item.subitems">
      <li [attr.data-test-id]="item.dataTestId" [uiTitle]="item.key | localize" ui-submenu>
        <div class="sub-actions" data-test-id="kpi-actions-menu-move-option">
          <dropdown-menu-item-group
            [activeDropdownItemChange$]="activeDropdownItemChange$"
            [dropdownMenuClass]="dropdownMenuClass"
            [isItemActive]="isItemActive"
            [menuItems]="item.subitems"
            [mode]="'inline'"
            [nzMenuClassName]="'gh-dropdown-submenu-item-group'"
            (closeMenu)="closeMenu.emit()"
            (menuKeyDown)="menuKeyDown.emit($event)"
            ui-submenu-inline-child>
          </dropdown-menu-item-group>
        </div>
      </li>
    </ui-submenu-title>

    <hr *ngIf="item.endOfSection && showNewDesign && (!item.isVisible$ || (item.isVisible$ | async))" />
  </ng-container>
</ul>
