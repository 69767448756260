<div>
  <rich-text-editor
    [a11yLabelledby]="a11yLabelledby"
    [disabled]="disabled"
    [focusMe]="focusMe"
    [gif]="value?.gif"
    [hidePostButton]="!useSubmitButton"
    [inline]="inline"
    [placeholder]="placeholder"
    [postButtonA11yLabel]="postButtonA11yLabel"
    [readonly]="readonly"
    [text]="value?.note"
    [uiId]="uiId"
    [withoutGifs]="!useGifs"
    (editorBlur)="editorBlur.emit()"
    (editorFocus)="editorFocus.emit()"
    (gifChange)="setGif($event)"
    (mentionsChange)="onCommentMentionsChanged($event)"
    (textChange)="setNote($event)"></rich-text-editor>
  <mentions-editor
    class="mentions-editor"
    *ngIf="useMentions"
    [nonRemovable]="value?.mentions?.nonRemovable"
    [removable]="value?.mentions?.removable"
    [removeAllMentionsAriaLabel]="removeAllMentionsAriaLabel"
    (removeAll)="removeAllMentions()"
    (removeOne)="removeMention($event)"></mentions-editor>
</div>
