<ng-container>
  <titled-sub-nav-options *ngIf="state.showSystemOptions$ | async" [label]="'system'" [options]="state.systemOptions" data-test-id="sub-nav-section-system"></titled-sub-nav-options>
  <div class="borderline"></div>
  <titled-sub-nav-options *ngIf="state.showUserOptions$ | async" [label]="'users_cap'" [options]="state.usersOptions" data-test-id="sub-nav-section-users"></titled-sub-nav-options>
  <div class="borderline"></div>
  <titled-sub-nav-options
    *ngIf="state.showIntegrationOptions$ | async"
    [label]="'integrations'"
    [options]="state.integrationsOptions"
    data-test-id="sub-nav-section-integrations"></titled-sub-nav-options>
  <div class="borderline"></div>
  <titled-sub-nav-options *ngIf="state.showAccountOptions$ | async" [label]="'account'" [options]="state.accountOptions" data-test-id="sub-nav-section-account"></titled-sub-nav-options>
</ng-container>
