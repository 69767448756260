<div>
  <input [ngStyle]="HIDDEN_STYLE" aria-hidden="true" />
</div>
<div class="ant-tree-list" [class.ant-select-tree-list]="nzSelectMode">
  <div>
    <cdk-virtual-scroll-viewport
      *ngIf="nzVirtualHeight"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-multiselectable]="nzMultiple"
      [attr.role]="isTreeFlatten ? 'listbox' : 'tree'"
      [class.ant-select-tree-list-holder-inner]="nzSelectMode"
      [class.ant-tree-list-holder-inner]="!nzSelectMode"
      [id]="listboxId"
      [itemSize]="nzVirtualItemSize"
      [maxBufferPx]="nzVirtualMaxBufferPx"
      [minBufferPx]="nzVirtualMinBufferPx"
      [style.height]="nzVirtualHeight"
      tabindex="-1">
      <ng-container *cdkVirtualFor="let node of nzFlattenNodes; trackBy: trackByFlattenNode; index as i">
        <ng-template [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{ node: node, index: i }"></ng-template>
      </ng-container>
    </cdk-virtual-scroll-viewport>

    <div
      *ngIf="!nzVirtualHeight"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-multiselectable]="nzMultiple"
      [attr.role]="isTreeFlatten ? 'listbox' : 'tree'"
      [class.ant-select-tree-list-holder-inner]="nzSelectMode"
      [class.ant-tree-list-holder-inner]="!nzSelectMode"
      [id]="listboxId"
      tabindex="-1">
      <ng-container *ngFor="let node of nzFlattenNodes; trackBy: trackByFlattenNode; index as i">
        <ng-template *ngIf="!node.origin.hidden" [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{ node: node, index: i }"></ng-template>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #nodeTemplate let-index="index" let-treeNode="node">
  <ui-tree-node
    [attr.aria-checked]="treeNode.isChecked"
    [attr.aria-expanded]="isTreeFlatten || !treeNode.isLeaf || treeNode.children?.length > 0 ? treeNode.isExpanded : null"
    [attr.aria-level]="isTreeFlatten ? null : (treeNode.level || 0) + 1"
    [attr.aria-selected]="treeNode.isSelected"
    [attr.role]="nzCheckable && isTreeFlatten ? 'checkbox' : isTreeFlatten ? 'option' : 'treeitem'"
    [canHide]="treeNode.canHide"
    [class.activated-tree-node]="activatedNode === treeNode"
    [class.hovered-tree-node]="hoveredNode === treeNode"
    [icon]="treeNode.icon"
    [id]="'node-' + (treeNode.key || treeNode.title)"
    [isChecked]="treeNode.isChecked"
    [isDisableCheckbox]="treeNode.isDisableCheckbox || (treeNode.origin.isDisableCheckbox$ | async)"
    [isDisabled]="treeNode.isDisabled || (treeNode.origin.isDisabled$ | async)"
    [isEnd]="treeNode.isEnd"
    [isExpanded]="treeNode.isExpanded"
    [isHalfChecked]="treeNode.isHalfChecked || treeNode.origin.isHalfChecked"
    [isLeaf]="treeNode.isLeaf"
    [isLoading]="treeNode.isLoading"
    [isMatched]="treeNode.isMatched"
    [isSelectable]="treeNode.isSelectable"
    [isSelected]="treeNode.isSelected"
    [isStart]="treeNode.isStart"
    [isTreeFlatten]="isTreeFlatten"
    [title]="treeNode.title"
    [treeSize]="treeSize"
    [uiAsyncData]="nzAsyncData"
    [uiBeforeDrop]="nzBeforeDrop"
    [uiCheckable]="nzCheckable"
    [uiDraggable]="nzDraggable"
    [uiExpandedIcon]="nzExpandedIcon"
    [uiHideUnMatched]="nzHideUnMatched"
    [uiOffset]="uiOffset"
    [uiSearchValue]="nzSearchValue"
    [uiSelectMode]="nzSelectMode"
    [uiShowExpand]="nzShowExpand && !treeNode.origin.hideExpand"
    [uiShowIcon]="nzShowIcon"
    [uiShowLine]="nzShowLine"
    [uiTreeNode]="treeNode"
    [uiTreeTemplate]="nzTreeTemplate || nzTreeTemplateChild"
    (nodeCheckBoxChange)="nodeCheckBoxChange($event)"
    (uiCheckBoxChange)="eventTriggerChanged($event)"
    (uiClick)="eventTriggerChanged($event)"
    (uiContextMenu)="eventTriggerChanged($event)"
    (uiDblClick)="eventTriggerChanged($event)"
    (uiExpandChange)="eventTriggerChanged($event)"
    (uiHover)="onNodeHover($event)"
    (uiOnDragEnd)="eventTriggerChanged($event)"
    (uiOnDragEnter)="eventTriggerChanged($event)"
    (uiOnDragLeave)="eventTriggerChanged($event)"
    (uiOnDragOver)="eventTriggerChanged($event)"
    (uiOnDragStart)="eventTriggerChanged($event)"
    (uiOnDrop)="eventTriggerChanged($event)"
    builtin
    data-test-id="tree-node-item"
    tabindex="0"></ui-tree-node>
</ng-template>
