<div class="ant-input-number-handler-wrap" [ngStyle]="{ display: valueControlsHide || nzDisabled || nzReadOnly ? 'none' : 'block' }">
  <span
    class="ant-input-number-handler ant-input-number-handler-up"
    #upHandler
    [class.ant-input-number-handler-up-disabled]="disabledUp"
    (mousedown)="up($event)"
    (mouseleave)="stop()"
    (mouseup)="stop()"
    unselectable="unselectable">
    <i ui-icon uiType="chevron-up-small"></i>
  </span>
  <span
    class="ant-input-number-handler ant-input-number-handler-down"
    #downHandler
    [class.ant-input-number-handler-down-disabled]="disabledDown"
    (mousedown)="down($event)"
    (mouseleave)="stop()"
    (mouseup)="stop()"
    unselectable="unselectable">
    <i ui-icon uiType="chevron-down-small"></i>
  </span>
</div>
<div class="ant-input-number-input-wrap" data-test-id="input-number-value-field">
  <input
    class="ant-input-number-input"
    #inputElement
    [attr.aria-disabled]="a11yDisabled"
    [attr.aria-errormessage]="errorMessageId"
    [attr.aria-label]="a11yLabel"
    [attr.aria-labelledby]="a11yLabelledby"
    [attr.aria-required]="a11yRequired"
    [attr.autofocus]="nzAutoFocus ? 'autofocus' : null"
    [attr.data-test-id]="e2eTestId"
    [attr.id]="nzId"
    [attr.inputmode]="nzInputMode"
    [attr.max]="nzMax"
    [attr.min]="nzMin"
    [attr.step]="nzStep"
    [class.p-handles]="!valueControlsHide"
    [disabled]="nzDisabled"
    [ngModel]="displayValue"
    [placeholder]="nzPlaceHolder"
    [readOnly]="nzReadOnly"
    (input)="onInput($any($event))"
    (keyup)="stop()"
    (ngModelChange)="onModelChange($event)"
    autocomplete="off"
    type="text" />
</div>

<!-- The div below plays the role of aria-description since it still doesn't have full support for macOs and safari -->
<!-- Once we have full support we can simply remove it and attach back [attr.aria-description]="a11yDescription" to the input element -->
<div class="select-a11y-description" *ngIf="nzId && a11yDescription" [attr.id]="nzId + '-description'">{{ a11yDescription }}</div>
