import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseListModel } from "@webapp/core/abstracts/models/base-list.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { AiRecommendationsApiService } from "./ai-recommendations-api.service";
import { AiRecommendation, AiRecommendationRequest } from "./ai-recommendations.models";

@Injectable({
  providedIn: "root",
})
export class AiRecommendationRepository implements Pick<IBaseRepository<AiRecommendation>, "post$" | "getAll$" | "patch$"> {
  constructor(private aiRecommendationsApiService: AiRecommendationsApiService) {}

  public post$({ postModel }: { postModel: AiRecommendationRequest; config?: RequestConfig }): Observable<AiRecommendation> {
    return this.aiRecommendationsApiService.post$(postModel, {
      ...new RequestConfig(),
      url: this.aiRecommendationsApiService.getTriggerSuggestionEndpoint(),
    });
  }

  public getAll$(params?: { filter: RequestPaging; config?: RequestConfig }): Observable<BaseListModel<AiRecommendation>> {
    return this.aiRecommendationsApiService.getAll$(params.filter, params?.config);
  }

  public patch$({ id, patchModel, config }: { id: string; patchModel: Partial<AiRecommendation>; config?: RequestConfig }): Observable<AiRecommendation> {
    return this.aiRecommendationsApiService.patch$(id, patchModel, config);
  }
}
