<div class="a11y-skip-to-main-container" id="notification-layout">
  <ul>
    <li class="sp-pb-16 first-category">
      <notification-category [categoryNameKey]="'okrs'" [icon]="'goal'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.okrs"> </notification-category>
      <div [class]="'col-titles-container ' + notificationClass">
        <span class="col-title">Quantive</span>
        <span class="col-title">Email</span>
      </div>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category [categoryNameKey]="'key_result_updates'" [icon]="'kr'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.krs"> </notification-category>
    </li>
    <ng-container *editionFeature="'kpis'">
      <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
        <notification-category [categoryNameKey]="'kpis'" [icon]="'kpi'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.kpis"> </notification-category>
      </li>
    </ng-container>
    <ng-container *editionFeature="'hub.tasks'">
      <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
        <notification-category [categoryNameKey]="'tasks'" [icon]="'task'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.tasks"> </notification-category>
      </li>
    </ng-container>

    <li class="sp-mt-32 sp-pb-16">
      <notification-category [categoryNameKey]="'teams_with_capital'" [icon]="'users-group'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.teams">
      </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
      <notification-category [categoryNameKey]="'badges'" [icon]="'award'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.badges"> </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category
        [categoryNameKey]="'mentions_and_comments_and_reactions'"
        [icon]="'mention'"
        [notificationSettings]="notificationSettings"
        [settingKeys]="notificationSettingsMap.mentionsInComments">
      </notification-category>
    </li>
    <li class="sp-mt-32 sp-pb-16" *ngIf="canManageCheckinNotifications">
      <notification-category [categoryNameKey]="'check_ins'" [icon]="'check-in'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.reflections">
      </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category [categoryNameKey]="'sharing'" [icon]="'view-only'" [notificationSettings]="notificationSettings" [settingKeys]="notificationSettingsMap.sharing"> </notification-category>
    </li>
  </ul>
</div>
