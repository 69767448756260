<div class="sp-m-0" [uiGutter]="[24, 24]" ui-row>
  <div class="sp-px-12" ui-col uiLg="24" uiMd="24" uiXs="24">
    <div ui-row>
      <div ui-col uiLg="24" uiMd="24" uiXs="24">
        <form>
          <div ui-row>
            <div ui-col uiLg="4" uiMd="4" uiXs="4">
              <p class="text-color-gray-13 fs-14px">{{ "name" | localize }}</p>
            </div>

            <div ui-col uiLg="2" uiMd="2" uiXs="2"></div>

            <div ui-col uiLg="18" uiMd="18" uiXs="18">
              <ui-form-item class="sp-m-0">
                <ui-form-control>
                  <ui-input-text-field
                    [(value)]="inputValue"
                    [debounceTime]="300"
                    [focusMe]="true"
                    [required]="true"
                    [uiId]="'view-name'"
                    [uiPlaceholder]="modalData.placeholder"
                    [validationHint]="modalData.hint"
                    (valueChange)="onInputValueChange($event)"
                    updateOn="change"></ui-input-text-field>
                </ui-form-control>
              </ui-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="isLoading" ui-row>
      <div class="sp-py-32" ui-col uiLg="24" uiMd="24" uiXs="24">
        <ui-loading-indicator [uiTip]="'saving' | localize"></ui-loading-indicator>
      </div>
    </div>
  </div>
</div>
