<ui-card [uiBordered]="false" [uiExtra]="extraHeaderContentTemplate" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title">
  <ui-loading-indicator [uiIndicator]="loadingTemplate" [uiSpinning]="loading" [uiTip]="'loading' | localize">
    <div class="check-ins-team-info-wrapper" *ngFor="let team of teams" [attr.data-test-id]="'checkin-container-team-' + team?.name" role="group">
      <div class="check-ins-team-info-and-notes-wrapper" role="group">
        <div class="check-ins-team-info">
          <ui-assignee-avatar class="check-ins-team-avatar" [fromAssigneeId]="team.id" [uiShowTooltip]="true" uiSize="md"></ui-assignee-avatar>
          <div class="check-ins-team-name-and-due-date">
            <ui-assignee-name
              class="check-ins-team-name"
              [attr.aria-label]="constructTeamNameAriaLabel(team)"
              [fromAssigneeId]="team.id"
              [ghTrackMeta]="{ link: 'team_profile', id: team.id, user_id: currentUserId, widget: 'checkins', name: 'home_customizable' }"
              [uiParams]="{ teamId: team.id, activeTab: 'check-ins' }"
              (keydown.enter)="onEnterKeyDown(team.id)"
              ghTrack="Link Clicked"
              role="link"
              tabindex="0"
              uiSref="gtmhub.home.dashboard.team"></ui-assignee-name>
            <div class="check-ins-meta-info-wrapper">
              <div class="check-ins-count-published" data-test-id="published-info">
                {{ team.filledOutCheckInsCount }}/{{ "reflections_overview_published" | localize: { count: team.membersCount } }}
              </div>
              <i ui-icon uiSize="xxs" uiTheme="fill" uiType="ellipse"></i>
              <div class="check-ins-due-date" data-test-id="due-date">{{ team.hasCurrentUserFilledOut ? ("filled_out" | localize) : team.dueOn }}</div>
            </div>
          </div>
        </div>
        <div class="check-ins-notes" [class.check-ins-notes-in-german-or-bulgarian]="isGermanOrBulgarian()" [class.isFilledOut]="!team.hasCurrentUserFilledOut">
          <ng-container *ngFor="let note of noteTypes">
            <button
              *ngIf="team.notes?.[note]"
              [attr.aria-label]="('reflections_overview_' + note | localize) + ', ' + team.notes?.[note].count"
              [ghTrackMeta]="{ button_name: 'reflection_note', note: note, number_note: team.notes?.[note], id: team.id, user_id: currentUserId, widget: 'checkins', name: 'home_customizable' }"
              [ngClass]="'check-ins-notes-button ' + note"
              [uiParams]="{ teamId: team.id, activeTab: 'check-ins', note: note, expandCheckIns: team.notes?.[note].expandCheckIns, cadanceStart: cadanceStart }"
              data-test-id="fill-out-button"
              ghTrack="Button Clicked"
              ui-button
              uiSref="gtmhub.home.dashboard.team">
              {{ "reflections_overview_" + note | localize }} <span class="check-ins-note-count">{{ team.notes?.[note].count }}</span>
            </button>
          </ng-container>
        </div>
      </div>
      <div class="check-ins-fill-out">
        <button
          class="check-ins-fill-out-button"
          *ngIf="!team.hasCurrentUserFilledOut"
          [ghTrackMeta]="{ button_name: 'reflections_fill_out', id: team.id, user_id: currentUserId, widget: 'checkins', name: 'home_customizable' }"
          [uiParams]="{ teamId: team.id, activeTab: 'check-ins', memberId: currentUserId }"
          ghTrack="Button Clicked"
          ui-button
          uiShape="round"
          uiSize="small"
          uiSref="gtmhub.home.dashboard.team"
          uiType="default">
          {{ "reflections_fill_out" | localize }}
        </button>
      </div>
    </div>
  </ui-loading-indicator>

  <ui-alert *ngIf="error" [uiCloseable]="true" [uiDescription]="error" uiType="error" />

  <ng-container *ngIf="!loading && !error && !teams.length">
    <container-empty-state *editionFeature="'reflections'" [message]="'you_can_update_checkins_when_in_team' | localize" pictureUrl="/dist/img/home/widgets/empty_check-ins.svg" />

    <container-empty-state *editionFeature="'reflections'; negate: true" [message]="'checkins_allow_teams_update_progress' | localize" pictureUrl="/dist/img/check-ins_preview.png">
      <a *ngIf="(showUpgradeButton$ | async) === true" ui-button uiShape="round" uiSref="gtmhub.editionPlanChange">{{ "upgrade" | localize }}</a>
    </container-empty-state>
  </ng-container>
</ui-card>

<ng-template #loadingTemplate>
  <widget-skeleton />
</ng-template>

<ng-template #extraHeaderContentTemplate>
  <widget-actions [widgetId]="widgetId"></widget-actions>
</ng-template>
