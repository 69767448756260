<div class="user-details-wrap">
  <a class="user-avatar" [uiParams]="{ employeeId: employeeId }" uiSref="gtmhub.employees.employee">
    <ui-assignee-avatar
      [attr.aria-describedby]="'open_user_profile' | localize"
      [attr.aria-label]="'hi_open_user_profile' | localize: { user: employeeFirstName }"
      [fromAssigneeId]="employeeId"
      tabindex="0"
      uiAlt="Go to your profile"
      uiSize="xl" />
  </a>

  <div class="chip-wrapper" *ngIf="employeeFirstName" [style.flex-direction]="!managedTeams.length && !memberTeams.length ? 'row' : 'column'">
    <div class="title-wrap">
      <h1 class="welcome-title" [class.welcome-title-centered]="!memberTeams.length && !managedTeams.length" data-test-id="greeting-text">
        {{ "user_greeting" | localize: { name: employeeFirstName } }}
      </h1>

      <ng-container *ngIf="isViewOnly">
        <div class="button-pad">
          <button
            [class.has-view-only-popover]="viewOnlyPopoverVisible"
            [uiBackdrop]="true"
            [uiContent]="contentTemplate"
            [uiVisible]="viewOnlyPopoverVisible"
            (uiVisibleChange)="handleViewOnlyPopoverVisibleChange($event)"
            ui-button
            ui-popover
            uiPlacement="bottom"
            uiShape="round"
            uiSize="small"
            uiTrigger="click">
            <i ui-icon uiType="view-only"></i>
          </button>
        </div>

        <ng-template #contentTemplate>
          <div class="sp-p-16" style="width: 300px">
            <read-only-user-message (closeAction)="closeViewOnlyPopover()" />
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div class="chip-grid">
      <div class="chip-list-title" *ngIf="managedTeams.length">{{ "managing" | localize }}</div>
      <view-more *ngIf="managedTeams.length" [attr.aria-describedby]="'managing' | localize">
        <a *ngFor="let team of managedTeams; let i = index" [uiParams]="{ teamId: team.id }" [uiSref]="teamProfileSref">
          <ui-chip [attr.aria-describedby]="'chip_aria_description' | localize" [chipAssignee]="team">
            {{ team.name }}
          </ui-chip>
        </a>
      </view-more>

      <div class="chip-list-title" *ngIf="memberTeams.length">{{ "member_of" | localize }}</div>
      <view-more *ngIf="memberTeams.length" [attr.aria-describedby]="'member_of' | localize">
        <a *ngFor="let team of memberTeams; let i = index" [uiParams]="{ teamId: team.id }" [uiSref]="teamProfileSref">
          <ui-chip [attr.aria-describedby]="'chip_aria_description' | localize" [chipAssignee]="team">
            {{ team.name }}
          </ui-chip>
        </a>
      </view-more>
    </div>
  </div>
</div>
