<ng-template [ngTemplateOutlet]="selectItemTemplate" [ngTemplateOutletContext]="selectItemContext"></ng-template>
<ng-container *ngIf="!selectItemTemplate">
  <ng-container *nzStringTemplateOutlet="contentTemplateOutlet; context: { $implicit: contentTemplateOutletContext }">
    <div class="ant-select-selection-item-content" *ngIf="deletable; else labelTemplate" data-test-id="tag-label">{{ label }}</div>
    <ng-template #labelTemplate>{{ label }}</ng-template>
  </ng-container>
  <span class="ant-select-selection-item-remove" *ngIf="deletable && !disabled && renderRemoveIcon" [attr.data-test-id]="'remove-owner-' + label" (click)="onDelete($event)">
    <ng-template *ngIf="removeIcon" [ngTemplateOutlet]="removeIcon"></ng-template>
    <i class="default-remove-chip-icon" *ngIf="!removeIcon" ui-icon uiTheme="outline" uiType="close-medium"></i>
  </span>
</ng-container>
