<div *ngIf="notification.operationType === 'metric_snapshot_user_created' || notification.operationType === 'metric_snapshot_team_created'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "kr_updated_by_user" | localize: { names: notification.notificationData.actor.names } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="'metric' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="metric"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.targetInfo.manualType === 'boolean'">
        <div class="heading inline-notifications">
          {{ "status" | localize }}
        </div>
        :&nbsp;<span *ngIf="notification.notificationData.item.updatedValue.target === 0">Not achieved yet</span
        ><span *ngIf="notification.notificationData.item.updatedValue.target !== 0">{{ "achieved" | localize }}</span>
      </div>
      <div class="inbox-section inline-notifications" *ngIf="notification.notificationData.item.targetInfo.manualType !== 'boolean'">
        <div class="heading inline-notifications">
          {{ "new_value" | localize }}
        </div>
        : {{ notification.notificationData.item.updatedValue.target | metricValue: notification.notificationData.item.updatedValue.format }} (
        <div class="heading inline-notifications">
          {{ "target" | localize }}
        </div>
        :&nbsp;<span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_above'"
          >{{ "should_stay_above_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_below'"
          >{{ "should_stay_below_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_least'"
          >{{ "should_increase_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_most'"
          >{{ "should_decrease_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        >)
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "notes" | localize }}
        </div>
        :<rich-text-viewer [text]="notification.notificationData.item.updateNote"></rich-text-viewer>
        <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gif]="notification.notificationData.item?.gif" [gifSize]="sizeOfGif.Small"></gif-view>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'metric_snapshot_user_edited' || notification.operationType === 'metric_snapshot_team_edited'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_edited_kr_update_note" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="'metric' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="metric"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "new_note" | localize }}
        </div>
        :<rich-text-viewer [text]="notification.notificationData.item.changes.updateNote.newValue"></rich-text-viewer>
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "old_note" | localize }}
        </div>
        :<rich-text-viewer [text]="notification.notificationData.item.changes.updateNote.oldValue"></rich-text-viewer>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.targetInfo.manualType === 'boolean'">
        <div class="heading inline-notifications">
          {{ "status" | localize }}
        </div>
        :&nbsp;<span *ngIf="notification.notificationData.item.updatedValue.target === 0">{{ "not_achieved_yet" | localize }}</span
        ><span *ngIf="notification.notificationData.item.updatedValue.target !== 0">{{ "achieved" | localize }}</span>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.targetInfo.manualType !== 'boolean'">
        <div class="heading inline-notifications">
          {{ "value" | localize }}
        </div>
        : {{ notification.notificationData.item.updatedValue.target | metricValue: notification.notificationData.item.updatedValue.format }} (
        <div class="heading inline-notifications">
          {{ "target" | localize }}
        </div>
        :&nbsp;<span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_above'"
          >{{ "should_stay_above_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_below'"
          >{{ "should_stay_below_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_least'"
          >{{ "should_increase_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_most'"
          >{{ "should_decrease_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        >)
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "read_update" | localize }}</a>
      </div>
    </div>
  </div>
</div>
