<div class="ant-message" [class.ant-message-rtl]="dir === 'rtl'" [style.top]="top">
  <ui-toast
    *ngFor="let instance of instances"
    [uiCloseable]="instance.options.uiCloseable"
    [uiHideIcon]="instance.options.uiHideIcon"
    [uiInstance]="instance"
    [uiPrimaryCTA]="instance.options.uiPrimaryCTA"
    [uiSecondaryCTA]="instance.options.uiSecondaryCTA"
    (uiDestroyed)="remove($event.id, $event.userAction)"
    (uiOnPrimaryAction)="instance.options.uiOnPrimaryAction()"
    (uiOnSecondaryAction)="instance.options.uiOnSecondaryAction()"></ui-toast>
</div>
