<div *ngIf="explanationSection">
  <div class="grid-container" *ngIf="explanationSection.isLoading">
    <suggestion-drawer-content-card *ngFor="let _ of [1, 2, 3]" classes="sp-p-12 content-card">
      <ui-skeleton-line class="sp-mb-4" [style]="{ height: '22px' }" [uiActive]="true" />
      <ui-skeleton-line class="sp-mb-4" [style]="{ height: '22px' }" [uiActive]="true" />
    </suggestion-drawer-content-card>
  </div>

  <ng-container *ngIf="!explanationSection.isLoading">
    <div *ngIf="explanationSection.hasError" ui-col uiXs="24">
      <pi-clickable-error-card [errorData]="explanationSection.errorData" [instance]="summaryInstance" [isRemoveBtnAvailable]="false" (errorRefresh)="handleRetry()"></pi-clickable-error-card>
    </div>

    <div class="grid-container" *ngIf="!explanationSection.hasError && explanationSection.explanation" ui-col uiXs="24">
      <pi-feedback-card-readonly
        [instance]="summaryInstance"
        [parent]="parent"
        [suggestion]="{ id: explanationSection.explanation.id, text: explanationSection.explanation.summary }"
        [suggestionFeedbackType]="parentType === 'goal' ? 'objective' : 'KR'"
        data-test-id="okr-suggestion-item-overview-summary"
        suggestionFeedbackSubType="summary">
        <suggestion-drawer-content-card classes="sp-p-0">
          <div ui-row>
            <div class="content-card" ui-col uiXs="24">
              <div ui-row>
                <div ui-col uiXs="24">
                  <suggestion-drawer-paragraph [text]="'summary' | localize" size="large" weight="semibold"></suggestion-drawer-paragraph>
                </div>
              </div>
              <div ui-row>
                <div ui-col uiXs="24">
                  <suggestion-drawer-paragraph [text]="explanationSection.explanation.summary" contrast="medium" size="small"></suggestion-drawer-paragraph>
                </div>
              </div>
            </div>
          </div>
        </suggestion-drawer-content-card>
      </pi-feedback-card-readonly>

      <pi-feedback-card-readonly
        [instance]="whyMatterInstance"
        [parent]="parent"
        [suggestion]="{ id: explanationSection.explanation.id, text: explanationSection.explanation.summary }"
        [suggestionFeedbackType]="parentType === 'goal' ? 'objective' : 'KR'"
        data-test-id="okr-suggestion-item-overview-why"
        suggestionFeedbackSubType="whyMatter">
        <suggestion-drawer-content-card classes="sp-p-0">
          <div ui-row>
            <div class="content-card" ui-col uiXs="24">
              <div ui-row>
                <div ui-col uiXs="24">
                  <suggestion-drawer-paragraph [text]="whyMatterSectionTitle" size="large" weight="semibold"></suggestion-drawer-paragraph>
                </div>
              </div>
              <div ui-row>
                <div ui-col uiXs="24">
                  <suggestion-drawer-paragraph [text]="explanationSection.explanation.whyMatter" contrast="medium" size="small"></suggestion-drawer-paragraph>
                </div>
              </div>
            </div>
          </div>
        </suggestion-drawer-content-card>
      </pi-feedback-card-readonly>

      <pi-feedback-card-readonly
        *ngIf="explanationSection.explanation.keywords && explanationSection.explanation.keywords.length"
        [instance]="keyTermsInstance"
        [parent]="parent"
        [suggestion]="{ id: explanationSection.explanation.id, text: explanationSection.explanation.summary }"
        [suggestionFeedbackType]="parentType === 'goal' ? 'objective' : 'KR'"
        data-test-id="okr-suggestion-item-overview-key"
        suggestionFeedbackSubType="keyTerms">
        <suggestion-drawer-content-card classes="sp-p-0">
          <div ui-row>
            <div class="content-card" ui-col uiXs="24">
              <div class="sp-pb-8" ui-row>
                <div ui-col uiXs="24">
                  <suggestion-drawer-paragraph [text]="'key_terms' | localize" size="large" weight="semibold"></suggestion-drawer-paragraph>
                </div>
              </div>
              <div ui-row>
                <div ui-col uiXs="24">
                  <div class="overview-keyword-section" *ngFor="let keyword of explanationSection.explanation.keywords" ui-row>
                    <div ui-col uiXs="24">
                      <div ui-row>
                        <div ui-col uiXs="24">
                          <suggestion-drawer-paragraph [text]="keyword.name" size="medium" weight="semibold"></suggestion-drawer-paragraph>
                        </div>
                      </div>
                      <div ui-row>
                        <div ui-col uiXs="24">
                          <suggestion-drawer-paragraph [text]="keyword.description" contrast="medium" size="small"></suggestion-drawer-paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </suggestion-drawer-content-card>
      </pi-feedback-card-readonly>
    </div>
  </ng-container>
</div>
