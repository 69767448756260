import { Ng2StateDeclaration } from "@uirouter/angular";
import { lazyLoadNgAndNg1Module, registerChildFutureState } from "@webapp/core/routing/routing.utils";
import type * as lazy from "./okrs.lazy";

export const loadModule = (): Promise<typeof lazy> => import(/* webpackChunkName: "okrs" */ "./okrs.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const goalsAbstractState: Ng2StateDeclaration = {
  name: "gtmhub.goals",
  url: "/goals/:planningSessionId/",
  abstract: true,
  lazyLoad,
};

const goalsGridFutureState: Ng2StateDeclaration = {
  // when enabling okr-grid feature flag this state and url should be changed to all
  name: "gtmhub.goals.grid",
  url: "grid/",
};

const goalsGridGoalFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.grid.goal.**",
  url: ":id/metrics/",
};

const goalsGridMetricFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.grid.metric.**",
  url: "metric/:metricId/",
};

const goalsAllFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.all",
  url: "all/",
};

const goalsGoalFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.all.goal.**",
  url: ":id/metrics/",
};

const goalsMetricFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.all.metric.**",
  url: "metric/:metricId/",
};

const goalsSessionOverviewFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.overview.**",
  url: "overview/",
};

const goalsWorkflowFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.workflow.**",
  url: "workflow/",
};

const goalsAlignmentFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.alignmentSidebar.**",
  url: "alignment/",
};

const goalsSessionFormFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goals.all.session.**",
  url: "form/",
};

const sessionsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.sessions.**",
  url: "/sessions/",
  lazyLoad,
};

const workflowFutureState: Ng2StateDeclaration = {
  name: "gtmhub.workflow.**",
  url: "/workflow/",
  lazyLoad,
};

const okrViewsFeatureState: Ng2StateDeclaration[] = [
  {
    name: `gtmhub.okrViews.**`,
    url: "/okr-views/",
    lazyLoad,
  },
  {
    name: `gtmhub.okrView.**`,
    url: "/okr-views/:okrViewId/",
  },
];

const allOkrsFeatureState: Ng2StateDeclaration[] = [
  {
    name: `gtmhub.allOkrs`,
    url: "/all-okrs/",
    lazyLoad,
  },
  {
    name: "gtmhub.allOkrs.grid",
    url: "grid/",
  },
  {
    name: "gtmhub.allOkrs.grid.goal.**",
    url: ":id/metrics/",
  },
  {
    name: "gtmhub.allOkrs.grid.metric.**",
    url: "metric/:metricId/",
  },
];

const tasksFutureState: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.tasks",
    url: "/tasks/",
    lazyLoad,
  },
  {
    name: "gtmhub.tasks.task.**",
    url: "task/:taskId/",
    lazyLoad,
  },
];

export const states: Ng2StateDeclaration[] = [
  goalsAbstractState,
  goalsGridFutureState,
  goalsAllFutureState,
  goalsAlignmentFutureState,
  goalsGoalFutureState,
  goalsGridGoalFutureState,
  goalsMetricFutureState,
  goalsGridMetricFutureState,
  goalsSessionFormFutureState,
  goalsSessionOverviewFutureState,
  goalsWorkflowFutureState,
  sessionsFutureState,
  workflowFutureState,
  ...okrViewsFeatureState,
  ...allOkrsFeatureState,
  ...tasksFutureState,
];

export const taskFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base, factory: "taskStates" });
  return [
    {
      name: `${base}.task.**`,
      url: "task/:taskId/",
      lazyLoad,
    },
  ];
};

export const metricFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base, factory: "metricStates" });
  return [
    {
      name: `${base}.metric.**`,
      url: "metric/:metricId/",
      lazyLoad,
    },
  ];
};

export const goalFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base, factory: "goalStates" });
  return [
    {
      name: `${base}.goal.**`,
      url: ":id/metrics/",
      lazyLoad,
    },
  ];
};
