<div
  class="simple-global-search-actions"
  [uiContent]="resultTemplate"
  [uiTrigger]="null"
  [uiVisible]="shouldShowPopover"
  (keydown.escape)="onEsc($event)"
  ui-popover
  uiOverlayClassName="without-arrow"
  uiPlacement="bottomLeft">
  <input
    class="search-field"
    id="search-input"
    #searchInput
    [(ngModel)]="searchTerm"
    [modelChangeDebounce]="500"
    [placeholder]="searchPlaceholder"
    (keydown.arrowDown)="focusSearchItems()"
    (ngModelChange)="onSearchTermChange()"
    autocomplete="off" />
</div>
<ng-template #resultTemplate>
  <div class="search-results" #resultsPopover>
    <search-results [isCustomClickHandlerAvailable]="true" [maxItems]="totalNumberOfItems" (escKeydown)="onEsc($event)" searchMode="simple"></search-results>
  </div>
</ng-template>
