<ng-container *ngIf="!nzInline; else inlineMode">
  <!-- Content of single picker -->
  <div *ngIf="!isRange" [class]="prefixCls + '-input'">
    <span [class]="prefixCls + '-prefix'">
      <i class="fs-16px" ui-icon uiType="calendar"></i>
    </span>
    <input
      #pickerInput
      [(ngModel)]="inputValue"
      [attr.aria-controls]="pickerId + '-dropdown'"
      [attr.aria-expanded]="realOpenState"
      [attr.aria-labelledby]="a11yLabelledby"
      [attr.aria-required]="a11yRequired"
      [attr.id]="nzId"
      [class.ant-input-disabled]="nzDisabled"
      [disabled]="nzDisabled"
      [placeholder]="!nzDisabled && !readonly ? getPlaceholder() : ''"
      [readOnly]="nzInputReadOnly"
      [size]="inputSize"
      (focusout)="onFocusout($event)"
      (keyup.arrowdown)="onKeyUpArrowDown()"
      (keyup.enter)="onKeyupEnter($event)"
      (ngModelChange)="onInputChange($event)"
      aria-autocomplete="none"
      aria-haspopup="dialog"
      autocomplete="off"
      role="combobox" />
    <span *ngIf="!hideSuffix" [class]="prefixCls + '-suffix'">
      <i ui-icon uiType="chevron-down-small"></i>
    </span>
    <ng-container *ngTemplateOutlet="tplRightRest"></ng-container>
  </div>

  <!-- Content of range picker -->
  <ng-container *ngIf="isRange">
    <div [class]="prefixCls + '-input'">
      <ng-container *ngTemplateOutlet="tplRangeInput; context: { partType: 'left' }"></ng-container>
    </div>
    <div [class]="prefixCls + '-input'">
      <ng-container *ngTemplateOutlet="tplRangeInput; context: { partType: 'right' }"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="tplRightRest"></ng-container>
  </ng-container>
</ng-container>
<!-- Input for Range ONLY -->
<ng-template #tplRangeInput let-partType="partType">
  <span [class]="prefixCls + '-prefix'">
    <i class="fs-16px" ui-icon uiType="calendar"></i>
  </span>
  <ng-container *ngIf="inputValue[datePickerService.getActiveIndex(partType)]">
    <span *ngIf="partType === 'left'">{{ "DatePicker.lang.fromDate" | uiI18n }}</span>
    <span *ngIf="partType === 'right'">{{ "DatePicker.lang.toDate" | uiI18n }}</span>
  </ng-container>
  <input
    #rangePickerInput
    [(ngModel)]="inputValue[datePickerService.getActiveIndex(partType)]"
    [attr.id]="nzId"
    [disabled]="nzDisabled"
    [placeholder]="getPlaceholder(partType)"
    [readOnly]="nzInputReadOnly"
    [size]="inputSize"
    (click)="onClickInputBox($event)"
    (focusin)="onFocusIn(partType)"
    (focusout)="onFocusout($event)"
    (keyup.arrowdown)="onKeyUpArrowDown()"
    (keyup.enter)="onKeyupEnter($event)"
    (ngModelChange)="onInputChange($event)"
    autocomplete="off" />
  <span *ngIf="!hideSuffix" [class]="prefixCls + '-suffix'">
    <i class="fs-16px" ui-icon uiType="chevron-down-small"></i>
  </span>
</ng-template>

<!-- Right operator icons -->
<ng-template #tplRightRest>
  <span *ngIf="showClear" [class]="prefixCls + '-clear'" (click)="onClickClear($event)">
    <i ui-icon uiSize="sm" uiType="close-circle"></i>
  </span>
</ng-template>

<ng-template #inlineMode>
  <div
    [class.ant-picker-active-left]="datePickerService.activeInput === 'left'"
    [class.ant-picker-active-right]="datePickerService.activeInput === 'right'"
    [class.ant-picker-dropdown-placement-bottomLeft]="currentPositionY === 'bottom' && currentPositionX === 'start'"
    [class.ant-picker-dropdown-placement-bottomRight]="currentPositionY === 'bottom' && currentPositionX === 'end'"
    [class.ant-picker-dropdown-placement-topLeft]="currentPositionY === 'top' && currentPositionX === 'start'"
    [class.ant-picker-dropdown-placement-topRight]="currentPositionY === 'top' && currentPositionX === 'end'"
    [class.ant-picker-dropdown-range]="isRange"
    [class.ant-picker-dropdown-rtl]="dir === 'rtl'"
    [class]="prefixCls + '-dropdown ' + nzDropdownClassName"
    [id]="pickerId + '-dropdown'"
    [ngStyle]="nzPopupStyle">
    <!-- the onFocusout method extension prevents the popup from closing when clicking outside it without (focusout)="onFocusout($event) addition here -->
    <ui-date-range-popup
      [cdkTrapFocus]="!nzInline && nzBackdrop && isOpenHandledByUser()"
      [uiDateRender]="nzDateRender"
      [uiDefaultPickerValue]="nzDefaultPickerValue"
      [uiDir]="dir"
      [uiDisabledDate]="nzDisabledDate"
      [uiDisabledTime]="nzDisabledTime"
      [uiExtraFooter]="extraFooter"
      [uiInline]="nzInline"
      [uiIsRange]="isRange"
      [uiLocale]="nzLocale?.lang!"
      [uiPanelMode]="panelMode"
      [uiRanges]="nzRanges"
      [uiShowNow]="nzMode === 'date' && nzShowNow && !isRange && !!nzShowTime"
      [uiShowTime]="nzShowTime"
      [uiShowToday]="nzMode === 'date' && nzShowToday && !isRange && !nzShowTime"
      [uiShowWeek]="nzMode === 'week'"
      (keyup.pagedown)="onPopupKeyUpPageDown($event)"
      (keyup.pageup)="onPopupKeyUpPageUp($event)"
      (keyup.shift.pagedown)="onPopupKeyUpShiftPageDown($event)"
      (keyup.shift.pageup)="onPopupKeyUpShiftPageUp($event)"
      (mousedown)="onPopupMouseDown($event)"
      (uiCalendarChange)="onCalendarChange($event)"
      (uiPanelModeChange)="onPanelModeChange($event)"
      (uiResultOk)="onResultOk()"></ui-date-range-popup>
  </div>
</ng-template>

<!-- Overlay -->
<ng-template
  [cdkConnectedOverlayHasBackdrop]="nzBackdrop"
  [cdkConnectedOverlayOpen]="realOpenState"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-picker-wrapper'"
  (detach)="close()"
  (overlayKeydown)="onOverlayKeydown($event)"
  (positionChange)="onPositionChange($event)"
  cdkConnectedOverlay
  nzConnectedOverlay>
  <div class="ant-picker-wrapper" style="position: relative">
    <ng-container *ngTemplateOutlet="inlineMode"></ng-container>
  </div>
</ng-template>
