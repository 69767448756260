<!-- show the hints only if no error messages are currently visible -->
<ng-container
  *ngIf="formItemDescriptor.hints?.length > 0 && (!(formItemDescriptor.formControl?.errors | firstErrorMessage: formItemDescriptor.errorMessages) || formItemDescriptor.formControl?.pristine)">
  <ui-validation-hint
    class="form-item-hint-row"
    *ngFor="let hint of formItemDescriptor.hints"
    [hintContent]="hint.content"
    [hintType]="hint.type"
    [isAccented]="hint.accented"
    [ngClass]="{ 'hint-accented': hint.accented }"
    [uiHideIcon]="hint.hideIcon"></ui-validation-hint>
</ng-container>
<ui-validation-hint
  class="form-item-hint-row"
  *ngIf="formItemDescriptor.formControl?.dirty && formItemDescriptor.formControl?.errors | firstErrorMessage: formItemDescriptor.errorMessages"
  [hintContent]="formItemDescriptor.errorMessages[formItemDescriptor.formControl?.errors | firstErrorMessage: formItemDescriptor.errorMessages]"
  [isAccented]="false"
  aria-live="assertive"
  hintType="error"
  role="region"></ui-validation-hint>
