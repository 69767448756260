<quantive-plus-tasks
  [flowName]="flowName"
  [item]="item"
  [maxTasksToSuggest]="taskList.length >= this.minTasksCountNotToFetchSuggestions ? taskList.length + 1 : this.minTasksCountNotToFetchSuggestions"
  [parentType]="parentType"
  [sessionId]="suggestions?.sessionId"
  [suggestionsStatus]="status"
  [taskList]="taskList"
  [tasks]="suggestions?.tasks"
  [tasksInstance]="tasksInstance"
  (taskCreate)="onTaskCreated($event)"
  borderStyle="solid"
  iconTheme="fill"
  iconType="task">
</quantive-plus-tasks>
