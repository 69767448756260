<span class="ant-input-wrapper ant-input-group" *ngIf="isAddOn; else noAddOnTemplate">
  <span class="add-on-before" *ngIf="nzAddOnBefore || nzAddOnBeforeIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnBeforeIcon" [uiTemplate]="nzAddOnBefore" type="addon" ui-input-group-slot></span>
  <span class="ant-input-affix-wrapper" *ngIf="isAffix; else contentTemplate" [class.ant-input-affix-wrapper-lg]="isLarge" [class.ant-input-affix-wrapper-sm]="isSmall">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </span>
  <span class="add-on-after" *ngIf="nzAddOnAfter || nzAddOnAfterIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnAfterIcon" [uiTemplate]="nzAddOnAfter" type="addon" ui-input-group-slot></span>
</span>
<ng-template #noAddOnTemplate>
  <ng-template [ngIf]="isAffix" [ngIfElse]="contentTemplate">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </ng-template>
</ng-template>
<ng-template #affixTemplate>
  <span *ngIf="nzPrefix || nzPrefixIcon" [uiIcon]="nzPrefixIcon" [uiTemplate]="nzPrefix" type="prefix" ui-input-group-slot></span>
  <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
  <span *ngIf="nzSuffix || nzSuffixIcon" [uiIcon]="nzSuffixIcon" [uiTemplate]="nzSuffix" type="suffix" ui-input-group-slot></span>
</ng-template>
<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
