<div class="show-comments-button" *ngIf="!isCurrentUserViewOnly || commentsCount > 0" (click)="showCommentsButtonClick.emit()" (keydown)="handleEnter($event)" tabindex="0">
  <div class="post-comment" *ngIf="commentsCount === 0">
    <div class="icon">
      <i ui-icon uiType="comments"></i>
    </div>
    <div *ngIf="onPageUsed === 'metric'">{{ "comment_on_update" | localize }}&nbsp;</div>
    <div *ngIf="onPageUsed === 'feed'">{{ "post_a_comment" | localize }}&nbsp;</div>
  </div>
  <div class="comments-summary" *ngIf="commentsCount > 0">
    <ui-assignee-avatar-group *ngIf="userIds" [uiMax]="avatarPreviewNumber" ui-align-right uiSize="xs">
      <ui-assignee-avatar *ngFor="let assigneeId of userIds" [fromAssigneeId]="assigneeId"></ui-assignee-avatar>
    </ui-assignee-avatar-group>
    <div class="counter" *ngIf="onPageUsed !== 'metric'">
      <div>{{ commentsCount }}</div>
      <div>{{ (commentsCount > 1 ? "comments" : "comment") | localize }}</div>
    </div>
    <div class="counter metric-counter" *ngIf="onPageUsed === 'metric'">
      <div class="icon">
        <i ui-icon uiType="comments"></i>
      </div>
      <div>{{ commentsCount }}</div>
      <div>{{ (commentsCount > 1 ? "comments" : "comment") | localize }}</div>
    </div>
  </div>
</div>
