<ng-template [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{ $implicit: context, origin: context.origin }"></ng-template>
<ng-container *ngIf="!treeTemplate">
  <span
    *ngIf="icon && showIcon"
    [class.ant-select-tree-iconEle]="selectMode"
    [class.ant-tree-icon__close]="isSwitcherClose"
    [class.ant-tree-icon__open]="isSwitcherOpen"
    [class.ant-tree-icon_loading]="isLoading"
    [class.ant-tree-iconEle]="!selectMode">
    <span [class.ant-select-tree-icon__customize]="selectMode" [class.ant-select-tree-iconEle]="selectMode" [class.ant-tree-icon__customize]="!selectMode" [class.ant-tree-iconEle]="!selectMode">
      <i *ngIf="icon" [uiType]="icon" ui-icon></i>
    </span>
  </span>
  <span class="ant-tree-title" *ngIf="title" [innerHTML]="title | nzHighlight: matchedValue : 'i' : 'font-highlight'"></span>
  <ui-tree-drop-indicator *ngIf="showIndicator" [dropPosition]="dragPosition" [level]="context.level"></ui-tree-drop-indicator>
</ng-container>
