<i
  [attr.aria-label]="a11yLabel"
  [tabindex]="a11yTabIndex"
  [uiContent]="contentTemplate"
  [uiPlacement]="placement"
  [uiTitle]="title ? titleTemplate : null"
  [uiTrigger]="trigger"
  [uiType]="iconName"
  [uiVisible]="isPopoverOpen"
  (keydown.enter)="onIconKeydown()"
  aria-haspopup="true"
  aria-keyshortcuts="Enter"
  type="button"
  ui-icon
  ui-popover></i>

<ng-template #titleTemplate>
  <div class="title-container" id="icon-popup-title" #contentElements (keydown)="onKeydownContent($event)" aria-keyshortcuts="Escape" role="note" tabindex="0">{{ title }}</div>
</ng-template>

<ng-template #contentTemplate>
  <div class="content-container" #contentElements (keydown)="onKeydownContent($event)" aria-keyshortcuts="Escape" role="note" tabindex="0">{{ content }}</div>
  <a class="link-container" #contentElements *ngIf="linkText && linkLocation" (click)="linkInteract()" (keydown)="onKeydownContent($event)" aria-keyshortcuts="Escape" role="link" tabindex="0">
    {{ linkText }}</a
  >
</ng-template>
