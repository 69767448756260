<div *ngIf="effectivenessSection" ui-row>
  <div *ngIf="effectivenessSection.isLoading" ui-col uiXs="24">
    <suggestion-drawer-content-card>
      <ui-skeleton-line class="sp-mb-16" [style]="{ width: '25%', height: '18px' }" [uiActive]="true" />
      <ui-skeleton-line class="sp-mb-24" [style]="{ height: '22px' }" [uiActive]="true" />
      <div class="sp-px-12">
        <div class="sp-mb-4" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px">
            <ui-skeleton-line [uiActive]="true" />
          </div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
        <div class="sp-mb-16" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px"></div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
        <div class="sp-mb-4" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px">
            <ui-skeleton-line [uiActive]="true" />
          </div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
        <div class="sp-mb-16" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px"></div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
        <div class="sp-mb-4" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px">
            <ui-skeleton-line [uiActive]="true" />
          </div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
        <div class="sp-mb-16" [uiGutter]="12" ui-row uiAlign="middle">
          <div ui-col uiFlex="28px"></div>
          <div ui-col uiFlex="auto">
            <ui-skeleton-line [style]="{ height: '22px' }" [uiActive]="true" />
          </div>
        </div>
      </div>
    </suggestion-drawer-content-card>
  </div>

  <ng-container *ngIf="!effectivenessSection.isLoading">
    <div *ngIf="effectivenessSection.hasError" ui-col uiXs="24">
      <pi-clickable-error-card [errorData]="effectivenessSection.errorData" [instance]="instance" [isRemoveBtnAvailable]="false" (errorRefresh)="retryEffectiveness()"></pi-clickable-error-card>
    </div>

    <div *ngIf="!effectivenessSection.hasError && effectivenessSection.effectiveness" ui-col uiXs="24">
      <pi-feedback-card-readonly
        class="sp-pb-16"
        [instance]="instance"
        [parent]="parent"
        [suggestion]="{ id: effectivenessSection.effectiveness.id, text: effectivenessSection.effectiveness.summary }"
        [suggestionFeedbackType]="parentType === 'goal' ? 'objective' : 'KR'"
        data-test-id="okr-suggestion-item-effectiveness-assessment"
        suggestionFeedbackSubType="effectiveness">
        <suggestion-drawer-content-card classes="sp-p-0">
          <div class="sp-pb-16" ui-row>
            <div ui-col uiXs="24">
              <ui-label [uiColor]="effectivenessSection.effectiveness.score | scoreToLowToHighScale | lowToHighScaleToColor">
                <span>{{ effectivenessSection.effectiveness.score | scoreToLowToHighScale | lowToHighScaleToLabel }}</span>
              </ui-label>
            </div>
          </div>

          <div class="sp-pb-16" ui-row>
            <div ui-col uiXs="24">
              <suggestion-drawer-paragraph [text]="effectivenessSection.effectiveness.summary" weight="semibold"></suggestion-drawer-paragraph>
            </div>
          </div>

          <div ui-row>
            <div ui-col uiXs="24">
              <div *ngFor="let property of effectivenessSection.effectiveness.properties">
                <suggestion-drawer-bullet-point-card
                  [description]="property.explanation"
                  [icon]="property.score | scoreToLowToHighScale | lowToHighScaleToIcon"
                  [iconBackground]="property.score | scoreToLowToHighScale | lowToHighScaleToColor: { checkIfShouldSkipColor: isOneOfFewPropsHighInEffectiveness.bind(this) }"
                  [title]="property.name">
                </suggestion-drawer-bullet-point-card>
              </div>
            </div>
          </div>
        </suggestion-drawer-content-card>
      </pi-feedback-card-readonly>

      <ng-container *ngIf="titleSection && !titleSection.isHidden">
        <div class="sp-pb-10" ui-row>
          <div ui-col uiXs="24">
            <suggestion-drawer-paragraph [text]="improveWithSuggestionsSectionTitle" weight="semibold"></suggestion-drawer-paragraph>
          </div>
        </div>

        <div ui-row>
          <div ui-col uiXs="24">
            <suggestion-drawer-suggestion-group-card
              [flowId]="flowId"
              [flowName]="flowName"
              [groupTitle]="'title' | localize"
              [iconType]="parentDrawerContent"
              [parentType]="parentType"
              [suggestionFeedbackType]="parentDrawerContent === 'goal' ? 'objective' : 'KR'"
              [suggestionGroup]="titleSection"
              (goBackToSuggestion)="titleSection.goBackToLastAvailableSuggestion()"
              (retry)="titleSection.onRetry()"
              (suggest)="titleSection.onSuggestAnother()"
              (suggestionAccept)="titleSection.onAcceptSuggestion($event)"
              iconTheme="fill"
              suggestionFeedbackSubType="title">
            </suggestion-drawer-suggestion-group-card>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
