<ui-loading-indicator *ngIf="progress" [uiSize]="size" [uiTip]="progressMessage$ | async" [uiTipPosition]="tipPosition" [uiType]="type"></ui-loading-indicator>

<div class="has-inline-error" *ngIf="error && (!errorOptions || errorOptions.inline)" data-test-id="unhandled-error">
  <span>
    <span *ngIf="error.title">{{ error.title | localize }}</span>
    <span *ngIf="error.body && (!errorOptions || !errorOptions.hideBody)">{{ error.body }}</span>
    <span *ngIf="error.transactionId">{{ "transaction_id" | localize }}: {{ error.transactionId }}</span>
  </span>
  <button (click)="resetError()" ui-button uiShape="circle" uiSize="small" uiType="link">
    <i ui-icon uiTheme="fill" uiType="ui-1_bold-remove"></i>
  </button>
</div>

<error-view *ngIf="error && errorOptions && !errorOptions.inline" [error]="error" [errorOptions]="errorOptions"></error-view>
