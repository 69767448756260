<gh-dropdown [hasCustomButtonContent]="true" [menuItems]="menuItems" (dropdownToggled)="dropdownVisibilityChange($event)" dropdownMenuClass="okr-status-dropdown" uiOverlayClassName="okr-status-menu">
  <button
    [attr.aria-label]="ariaLabel"
    [attr.data-test-id]="'okr-' + goalId + '-status-dropdown-toggle'"
    [tabindex]="tabIndex"
    [uiSpecialType]="specialButtonType"
    [uiTooltipOverlayClassName]="toolTipClass"
    [uiTooltipTitle]="tooltip"
    gh-dropdown-custom-button-content
    ui-button
    ui-tooltip
    uiOverlayClassName="okr-status-dropdown"
    uiShape="round"
    uiSize="small"
    uiTooltipPlacement="bottom"
    uiTooltipTrigger="hover"
    uiTrigger="click">
    <span class="okr-status">{{ goalStatus | localize }} </span>
    <i ui-icon uiSize="sm" uiType="chevron-down-small"></i>
  </button>
</gh-dropdown>
