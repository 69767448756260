<div class="action" *ngIf="entity" [class.danger]="actionType === 'delete'" (click)="confirmAction()" (keydown.enter)="confirmAction()" tabindex="0">
  <span>{{ actionKey | localize }}</span>
</div>

<ng-template #notificationTemplate let-actionType="data.actionType" let-statusChangedText="data.statusChangedText">
  <div>{{ statusChangedText }}</div>
  <div *ngIf="openDetailsPath">
    <a class="view-profile-button" (click)="openEntityDetails()">{{ viewEntityKey | localize }}</a>
  </div>
</ng-template>

<ng-template #usersLimitTemplate>
  <div class="sp-px-24 sp-py-32">
    <i class="fs-72px centered-horiz" ui-icon uiTheme="fill" uiType="plan-upgrade"></i>

    <h3 class="fs-20px fw-600 ink-100-v2 t-align-c sp-mt-32 sp-mb-16">License limit reached</h3>

    <p class="ink-70-v2 sp-mb-32 t-align-c">
      {{ "maximum_users_reached_upgrade_or_deactivate_standard_licenses" | localize: { name: entity.name } }}
    </p>

    <div class="centered-horiz">
      <button (click)="contactUs()" data-test-id="contact-us-button" ui-button uiShape="round" uiSize="large" uiType="primary">
        {{ "contact_us" | localize }}
      </button>
      <button class="sp-ml-12" (click)="destroyModals()" data-test-id="maybe-later-button" ui-button uiShape="round" uiSize="large" uiType="default">Maybe later</button>
    </div>
  </div>
</ng-template>
