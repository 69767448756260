<gh-dropdown *ngIf="menuItems" [hasCustomButtonContent]="true" [menuItems]="menuItems" [showNewDesign]="true" (dropdownToggled)="dropdownToggled($event)" placement="bottomRight">
  <button
    id="createNewOKRBtn"
    [attr.aria-expanded]="dropdownVisible"
    [disabled]="disabled"
    aria-haspopup="true"
    data-test-id="top-nav-bar-buttons-dropdown"
    gh-dropdown-custom-button-content
    ui-button
    uiShape="round"
    uiTrigger="click"
    uiType="primary">
    <span class="selected">
      <span class="text">{{ "create_okr" | localize }} </span>
    </span>

    <i class="sp-ml-10" ui-icon uiSize="sm" uiType="chevron-down-small"></i>
  </button>
</gh-dropdown>
