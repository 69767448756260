<div
  [uiContent]="contentTemplate"
  [uiOrigin]="sourceMentionEl"
  [uiOverlayClassName]="'mention-popover'"
  [uiTitle]="titleTemplate"
  [uiVisible]="isVisible()"
  (uiVisibleChange)="changePopoverState($event)"
  ui-popover
  uiPlacement="bottom"></div>

<ng-template #titleTemplate>
  <div class="header">
    <ui-assignee-avatar [uiAssignee]="user" uiSize="lg"></ui-assignee-avatar>

    <div class="details">
      <div class="name"><ui-assignee-name [uiAssignee]="user"></ui-assignee-name></div>
      <span class="info" *ngIf="email">{{ email }}</span>
    </div>

    <i (click)="close()" ui-icon uiType="close-big"></i>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <div class="content">
    <button (click)="openProfile()" ui-button uiShape="round">{{ "view_profile" | localize }}</button>
  </div>
</ng-template>
