<div
  class="ant-modal"
  #modalElement
  [class.content-position-centered]="customConfig.nzModalContentCentered"
  [class.content-position-default]="!customConfig.nzModalContentCentered"
  [ngClass]="config.nzClassName!"
  [ngStyle]="config.nzStyle!"
  [style.width]="config.nzWidth! | nzToCssUnit"
  role="document">
  <div class="ant-modal-content">
    <button *ngIf="config.nzClosable" [attr.aria-label]="'Modal.closeText' | uiI18n" [title]="config.nzTitle" (click)="onCloseClick()" ui-modal-close></button>
    <div class="ant-modal-body" [ngStyle]="config.nzBodyStyle!">
      <div class="ant-modal-confirm-body-wrapper">
        <div class="ant-modal-confirm-body">
          <div class="icon-wrapper" *ngIf="config.nzIconType">
            <div class="icon-circle-inner">
              <i [uiType]="config.nzIconType!" ui-icon uiSize="xxl"></i>
            </div>
          </div>

          <div class="ant-modal-confirm-title" [id]="modalTitleId">
            <ng-container *nzStringTemplateOutlet="config.nzTitle">
              <h4 [innerHTML]="config.nzTitle"></h4>
            </ng-container>
          </div>
          <div class="ant-modal-confirm-content" [id]="modalDescriptionId">
            <ng-template cdkPortalOutlet></ng-template>
            <div *ngIf="isStringContent" [innerHTML]="config.nzContent"></div>
          </div>
        </div>
        <ui-loading-indicator *ngIf="customConfig.nzLoadingIndicator" uiType="linear" />
        <ui-alert *ngIf="customConfig.nzError" [uiCloseable]="true" [uiDescription]="customConfig.nzError" uiType="error" />
        <div class="ant-modal-confirm-btns">
          <button
            *ngIf="config.nzOkText !== null"
            [attr.aria-describedby]="modalDescriptionId"
            [attr.cdkFocusInitial]="config.nzAutofocus === 'ok' || null"
            [disabled]="config.nzOkDisabled"
            [uiDanger]="config.nzOkDanger"
            [uiLoading]="customConfig.nzOkLoading"
            [uiShape]="customConfig.nzOkShape"
            [uiSize]="customConfig.nzOkSize"
            [uiType]="config.nzOkType!"
            (click)="onOk()"
            ui-button>
            {{ config.nzOkText || locale.okText }}
          </button>
          <button
            *ngIf="config.nzCancelText !== null"
            [attr.cdkFocusInitial]="config.nzAutofocus === 'cancel' || null"
            [disabled]="config.nzCancelDisabled"
            [uiLoading]="customConfig.nzCancelLoading"
            [uiShape]="customConfig.nzCancelShape"
            [uiSize]="customConfig.nzCancelSize"
            [uiType]="customConfig.nzCancelType!"
            (click)="onCancel()"
            ui-button>
            {{ config.nzCancelText || locale.cancelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
