import { IPromise } from "angular";
import { IModalScope, IModalServiceInstance } from "angular-ui-bootstrap";
import { StateParams, StateService } from "@uirouter/angularjs";
import { firstValueFrom, take } from "rxjs";
import { IStateInit } from "@gtmhub/core/routing";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { IIndicator } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HubFilter } from "@webapp/filters/models/hub-filter.models";
import { HubFiltersRepository } from "@webapp/filters/services/hub-filters/hub-filters.repository.service";
import { FilterEvents } from "../events";

interface IFilterFormScope extends IModalScope {
  filter: HubFilter;
  indicators: {
    get?: IIndicator;
    save?: IIndicator;
  };
  selectAssigneesModal: IModalServiceInstance;
  filterIds: string[];
  save(): void;
  selectMultipleOwners(ids: string[]): void;
  requiredPermissionForOwnerSelector: string;
}

export class FilterFormCtrl implements IStateInit {
  public static $inject = ["$rootScope", "$scope", "$stateParams", "$state", "HubFiltersRepository", "FeatureTogglesFacade"];
  private filterEvents: FilterEvents;

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $scope: IFilterFormScope,
    private $stateParams: StateParams,
    private $state: StateService,
    private hubFiltersRepository: HubFiltersRepository,
    private featureToggleService: FeatureTogglesFacade
  ) {
    this.$scope.indicators = { get: { progress: true } };

    this.$scope.save = this.save;
    this.$scope.selectMultipleOwners = this.selectMultipleOwners;
    this.filterEvents = new FilterEvents($rootScope);

    this.featureToggleService
      .isFeatureAvailable$(FeatureFlag.ManageTasksGranularPermissions)
      .pipe(untilScopeDestroyed(this.$scope), take(1))
      .subscribe({
        next: (enabled) => {
          this.$scope.requiredPermissionForOwnerSelector = enabled ? "tasks:own" : "ManageTasks";
        },
      });
  }

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  private getFilter(): IPromise<unknown> {
    return firstValueFrom(this.hubFiltersRepository.getFilter$(this.$stateParams.formFilterId))
      .then(
        (filter) => {
          if (this.$stateParams.formFilterId) {
            if (!filter.employeeIds) {
              filter.employeeIds = [];
            }

            this.$scope.filter = filter;

            let ids = [];
            if (filter.teamIds) {
              ids = ids.concat(filter.teamIds);
            }
            if (filter.employeeIds) {
              ids = ids.concat(filter.employeeIds);
            }

            this.$scope.filterIds = ids;
          }

          delete this.$scope.indicators.get;
        },
        (error) => (this.$scope.indicators.get = { error })
      )
      .finally(() => this.$scope.$apply());
  }

  private init = (): IPromise<unknown> => {
    if (!this.$stateParams.formFilterId) {
      this.$scope.filter = {
        name: "",
        employeeIds: [],
        teamIds: [],
        filterType: "hub",
      };

      delete this.$scope.indicators.get;
    } else {
      return this.getFilter();
    }
  };

  private selectMultipleOwners = (ids: string[]): void => {
    this.$scope.filter.employeeIds = ids;
  };

  private save = () => {
    if (this.$stateParams.formFilterId) {
      this.patch();
    } else {
      this.create();
    }
  };

  private create = () => {
    this.$scope.indicators.save = { progress: true };
    this.hubFiltersRepository
      .createFilter$(this.$scope.filter)
      .pipe(untilScopeDestroyed(this.$scope))
      .subscribe((newFilter) => {
        delete this.$scope.indicators.save;
        this.filterEvents.broadcastFiltersChanged({ filter: { id: newFilter.id, name: newFilter.name }, action: "create" });

        if (this.$rootScope.featureAvailable("hub.tasks")) {
          const goToStateName = this.$rootScope.isMsTeamsState ? "gtmhub.msteams.dashboard.tasks" : "gtmhub.tasks";
          this.$state.go(goToStateName, { filterId: newFilter.id });
        } else {
          this.$scope.$close();
        }

        this.$scope.$apply();
      });
  };

  private patch = () => {
    this.$scope.indicators.save = { progress: true };
    this.hubFiltersRepository
      .patchFilter$(this.$scope.filter.id, this.$scope.filter)
      .pipe(untilScopeDestroyed(this.$scope))
      .subscribe({
        next: () => {
          delete this.$scope.indicators.save;
          this.$rootScope.$broadcast("filterChanged", this.$scope.filter);
          this.filterEvents.broadcastFiltersChanged({ filter: { id: this.$scope.filter.id, name: this.$scope.filter.name }, action: "update" });
          this.$scope.$close();
        },
        error: (error) => (this.$scope.indicators.save = { error }),
        complete: () => this.$scope.$apply(),
      });
  };
}
