<suggestion-drawer-content-card>
  <pi-loading-card-v2 *ngIf="isLoading" [instance]="descriptionInstance" [isIconAvailable]="false" [suggestion]="descriptionV2"></pi-loading-card-v2>

  <pi-clickable-error-card *ngIf="isError" [errorData]="errorData" [instance]="descriptionInstance" [isRemoveBtnAvailable]="false" [suggestion]="descriptionV2"> </pi-clickable-error-card>

  <pi-feedback-card
    *ngIf="!isError && !isLoading"
    [goal]="goal"
    [instance]="descriptionInstance"
    [suggestion]="descriptionV2"
    data-test-id="okr-suggestion-item-description"
    suggestionFeedbackSubType="description"
    suggestionFeedbackType="objective">
  </pi-feedback-card>
</suggestion-drawer-content-card>
