import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { NavItemsType } from "@webapp/navigation/models/nav-items-list.models";
import { Screens } from "@webapp/navigation/models/shared.models";

// TODO: Once the new grid is enbled for everyone, should remove the feature flag checks
export const getItemsScreenMap = (featureFlags: Partial<Record<FeatureFlag, boolean>>): { [K in NavItemsType]: ItemScreen } => ({
  okrs: {
    menuScreenKey: "home",
    itemState: { name: "._goals.goal", paramsMap: { id: "id" } },
  }, // we can open okrs in session and in home, where should we redirect
  metrics: {
    menuScreenKey: "home",
    itemState: { name: "._goals.goal", paramsMap: { id: "id" } },
  },
  sessions: {
    menuScreenKey: "okrs",
    subTypes: {
      session: {
        menuScreenKey: "okrs",
        itemState: featureFlags[FeatureFlag.OkrGrid]
          ? { name: "gtmhub.goals.grid", paramsMap: { id: "planningSessionId" } }
          : { name: "gtmhub.goals.all", paramsMap: { id: "planningSessionId" } },
        activeStates: [
          ...(featureFlags[FeatureFlag.OkrGrid] // This is necessary, since we rely on the first element to be the default state
            ? [
                { name: "gtmhub.goals.grid", paramsMap: { id: "planningSessionId" } },
                { name: "gtmhub.goals.all", paramsMap: { id: "planningSessionId" } },
              ]
            : [
                { name: "gtmhub.goals.all", paramsMap: { id: "planningSessionId" } },
                { name: "gtmhub.goals.grid", paramsMap: { id: "planningSessionId" } },
              ]),
          { name: "gtmhub.goals.workflow", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.overview", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.alignmentSidebar.tree", paramsMap: { id: "planningSessionId" } },
        ],
      },
      filter: {
        menuScreenKey: "okrs",
        activeStates: [
          ...(featureFlags[FeatureFlag.OkrGrid] // This is necessary, since we rely on the first element to be the default state
            ? [
                { name: "gtmhub.okrView.grid", paramsMap: { id: "okrViewId" } },
                { name: "gtmhub.okrView.all", paramsMap: { id: "okrViewId" } },
              ]
            : [
                { name: "gtmhub.okrView.all", paramsMap: { id: "okrViewId" } },
                { name: "gtmhub.okrView.grid", paramsMap: { id: "okrViewId" } },
              ]),
        ],
        itemState: featureFlags[FeatureFlag.OkrGrid]
          ? { name: "gtmhub.okrView.grid", paramsMap: { id: "okrViewId" } }
          : { name: "gtmhub.okrView.all", paramsMap: { id: "okrViewId" } },
      },
    },
  },
  whiteboards: {
    menuScreenKey: "whiteboards",
    itemState: { name: "gtmhub.whiteboard", paramsMap: { id: "whiteboardId" } },
  },
  kpis: {
    menuScreenKey: "kpis",
    subTypes: {
      kpigroup: {
        menuScreenKey: "kpis",
        itemState: { name: "gtmhub.kpis.list", paramsMap: { id: "kpiGroupId" } },
      },
      kpiview: {
        menuScreenKey: "kpis",
        itemState: { name: "gtmhub.kpis.views.kpiView", paramsMap: { id: "kpiViewId" } },
      },
    },
  },
  tasks: {
    menuScreenKey: "tasks",
    itemState: { name: "gtmhub.tasks", paramsMap: { id: "filterId" } },
  },
  insightboards: {
    menuScreenKey: "insightboards",
    itemState: { name: "gtmhub.insightboard", paramsMap: { id: "dashboardId" } },
  },
  lists: {
    menuScreenKey: "reports",
    itemState: { name: "gtmhub.list", paramsMap: { id: "listId" } },
  },
  people: {
    menuScreenKey: "people",
    subTypes: {
      user: {
        menuScreenKey: "people",
        itemState: { name: "gtmhub.employees.employee", paramsMap: { id: "employeeId" } },
      },
      team: {
        menuScreenKey: "people",
        itemState: { name: "gtmhub.teams.team", paramsMap: { id: "teamId" } },
      },
    },
  }, // .employee({'employeeId': employee.id}) .team({'teamId': team.id}) here it will be more tricky how to handle the states based on item type
});

export interface ItemState {
  name: string;
  paramsMap?: { id: string };
}
interface ItemScreen {
  menuScreenKey: Screens;
  itemState?: ItemState;
  activeStates?: ItemState[];
  subTypes?: { [K in UXCustomizationType]?: ItemScreen };
}
