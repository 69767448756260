import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropDownDirective, UiDropdownMenuComponent } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuDirective } from "@quantive/ui-kit/menu";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { fromEvent, take, zip } from "rxjs";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { IRole } from "@gtmhub/roles";
import { getCurrentUserId } from "@gtmhub/users";
import { toIdMap } from "@gtmhub/util";
import { AccountResolverService } from "@webapp/accounts";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrViewsCopyLinkFormComponent } from "@webapp/okrs/okr-views/components/okr-views-copy-link-form/okr-views-copy-link-form/okr-views-copy-link-form.component";
import { AccessChange, AccessOption, ShareableLink, SimplePermissionsAccessType, principalKindByCollectionMap } from "@webapp/permissions/models/permissions.model";
import { SimplePermissionsMediator } from "@webapp/permissions/services/simple-permissions.mediator";
import { determineAccessType } from "@webapp/permissions/utils/simple-permissions.utils";
import { RolesRepository } from "@webapp/roles/services/roles-repository.service";
import { SimpleSearchComponent } from "@webapp/search/components/simple-search/simple-search.component";
import { Search, SearchCollection, SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { IAccess, IApiPermission } from "@webapp/sessions/models/sessions.model";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { OkrsGridTrackingService } from "@webapp/shared/kendo/grid/services/okrs-grid-tracking.service";
import { ESCAPE, TAB } from "@webapp/shared/utils/keys";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { checkOverflow } from "@webapp/ui/tooltip/tooltip.utils";

type Principal = (Assignee | IRole) & { inUse?: boolean };

@UntilDestroy()
@Component({
  selector: "simple-permissions",
  templateUrl: "./simple-permissions.component.html",
  styleUrls: ["./simple-permissions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    UiRadioModule,
    UiIconModule,
    LocalizationModule,
    UiAssigneeModule,
    AssigneesModule,
    UiTooltipModule,
    SimpleSearchComponent,
    UiDropDownDirective,
    UiDropdownMenuComponent,
    UiMenuDirective,
    OkrViewsCopyLinkFormComponent,
    UiButtonModule,
    DropdownModule,
  ],
})
export class SimplePermissionsComponent implements OnInit, AfterViewInit {
  public searchCollections: SearchFacetsOptions[] = [SearchFacetsOptionsEnum.Teams, SearchFacetsOptionsEnum.Employees, SearchFacetsOptionsEnum.Roles];
  public principalsMap: { [id: string]: Principal };
  public selectedAccessOption: AccessOption;
  private principalsInUse: { [id: string]: boolean };
  private principals: Principal[];
  public accessTypes = SimplePermissionsAccessType;
  public isPermissionDropdownVisible = false;
  public accessOptions: AccessOption[] = [
    { icon: "restricted", labelKey: "private_access_only_you", value: SimplePermissionsAccessType.privateAccess },
    { icon: "users-group", labelKey: "private_access_only_invited", value: SimplePermissionsAccessType.restricted },
    { icon: "view-only", labelKey: "everyone_can_view", value: SimplePermissionsAccessType.publicRead },
    { icon: "inline", labelKey: "everyone_can_edit", value: SimplePermissionsAccessType.publicUpdate },
  ];

  @Input()
  public access: IAccess;

  @Input()
  public triggerButtonAriaLabel: string;

  @Input()
  public ownerId: string;

  @Input()
  public showPermissions: boolean;

  @Input()
  public compactMode?: boolean = false;

  @Input()
  public shareableLink: ShareableLink;

  @Input()
  public isGrayBackground = false;

  @Input()
  public useShareLinkSplitButtonForTheNewGrid = false;

  @Output()
  public readonly accessChange: EventEmitter<AccessChange> = new EventEmitter();

  @Output()
  public readonly toggle: EventEmitter<{ isVisible: boolean }> = new EventEmitter();

  @ViewChild("triggerButton")
  public triggerButton: ElementRef<HTMLButtonElement>;

  @ViewChild("accessDropdownContainer")
  public accessDropdownContainer: ElementRef<HTMLElement>;

  @ViewChild("simpleSearch")
  public simpleSearch: SimpleSearchComponent;

  @ContentChild("alertTemplate") public alertTemplateRef: TemplateRef<void>;

  public get triggerButtonAriaDescription(): string {
    return {
      private: "Only you have access",
      publicRead: "Everyone has read access",
      publicUpdate: "Everyone has read and update access",
      restricted: "Invited only have access",
    }[this.selectedAccessOption?.value];
  }

  public assignees: Map<string, Assignee>;
  public inviteesAccessDropdownMenuItems: DropdownMenuItem[] = [];

  constructor(
    private assigneesRepository: AssigneesRepository,
    private uiErrorHandlingService: UIErrorHandlingService,
    private accountResolverService: AccountResolverService,
    private cdRef: ChangeDetectorRef,
    private simplePermissionsMediator: SimplePermissionsMediator,
    private analyticsService: AnalyticsService,
    private rolesRepository: RolesRepository,
    private zone: NgZone,
    private okrsGridTrackingService: OkrsGridTrackingService
  ) {}

  public ngOnInit(): void {
    this.determineAccessType();
    this.getRoles();
    this.buildInviteesAccessDropdownMenuItems();

    this.simplePermissionsMediator.showPermissions$.pipe(untilDestroyed(this)).subscribe((showPermissions) => {
      this.showPermissions = showPermissions;
      this.cdRef.markForCheck();
    });
  }

  public ngAfterViewInit(): void {
    this.bindKeydownListener();
  }

  @HostBinding("class.gray-background")
  public get backgroundClass(): boolean {
    return this.isGrayBackground;
  }

  public handleShareableLink(): void {
    if (this.selectedAccessOption.value === SimplePermissionsAccessType.privateAccess) {
      this.showPermissions = true;
    } else {
      this.shareableLink.copyLinkHandler();
      this.okrsGridTrackingService.trackShareLink();
    }
  }
  private determineAccessType(): void {
    const accessType: SimplePermissionsAccessType = determineAccessType(this.access);

    this.selectedAccessOption = this.accessOptions.find((o) => o.value === accessType);
  }

  private getRoles(): void {
    zip([this.rolesRepository.getAll$(), this.assigneesRepository.getMap$()])
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: ([roles, assignees]) => {
          this.assignees = assignees;
          const assigneesList = Array.from(assignees.values());
          this.principals = [...assigneesList, ...roles.items];
          this.principalsMap = { ...toIdMap(assigneesList), ...toIdMap(roles.items) };

          this.markUsedPrincipals();
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  }

  private markUsedPrincipals(): void {
    this.principalsInUse = {};

    for (const p of this.access.permissions) {
      this.principalsInUse[p.principalId] = true;
    }

    for (const principal of this.principals) {
      principal.inUse = !!this.principalsInUse[principal.id];
    }
  }

  public trackByPrincipalId(index: number, permission: IApiPermission): string {
    return permission.principalId;
  }

  public changeGrant(changeTo: string, permission: IApiPermission): void {
    const newPermissions = this.access.permissions.map((p) => {
      if (p.principalId !== permission.principalId) return p;

      if (changeTo === "read") return { ...p, grant: { general: ["read"] } } as IApiPermission;

      if (changeTo === "update") return { ...p, grant: { general: ["read", "update"] } } as IApiPermission;

      return p;
    });

    this.access = { ...this.access, permissions: newPermissions };

    this.isPermissionDropdownVisible = false;

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Changed", {
      id: permission.principalId,
      property_type: permission.principalKind,
      from: permission.grant.general.join(","),
      to: changeTo === "read" ? "read" : "read,update",
      role: this.getRoleToTrack(permission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public changeAccessType(): void {
    const type = this.selectedAccessOption.value;
    const userPermissions: string[] = ["read", "update", "delete", "create", "modifyPermissions"];

    if (type === SimplePermissionsAccessType.privateAccess) {
      this.access = this.createAccessObject();
    } else if (type === SimplePermissionsAccessType.restricted) {
      this.access = this.createAccessObject(userPermissions);
    } else if (type === SimplePermissionsAccessType.publicRead) {
      const accountPermissions: string[] = ["read"];

      this.access = this.createAccessObject(userPermissions, accountPermissions);
    } else {
      const accountPermissions: string[] = ["read", "update"];

      this.access = this.createAccessObject(userPermissions, accountPermissions);
    }

    this.determineAccessType();

    this.accessChange.emit({ access: this.access, accessType: type });
  }

  public dropdownSelectedChange(item: DropdownMenuItem, permission: IApiPermission, removedElementContainer: Element): void {
    if (item.key === "can_view") {
      this.changeGrant("read", permission);
    } else if (item.key === "can_edit") {
      this.changeGrant("update", permission);
    } else if (item.key === "remove") {
      this.removePermission(permission, removedElementContainer);
    }
  }

  public buildInviteesAccessDropdownMenuItems(): void {
    const builder = new DropdownMenuItemBuilder();

    this.inviteesAccessDropdownMenuItems = [
      builder.setKey("can_view").setUiType({ uiType: "button" }).build(),
      builder.setKey("can_edit").setUiType({ uiType: "button" }).setToBeEndOfSection().build(),
      builder.setKey("remove").setToBeDestructive().setUiType({ uiType: "button" }).build(),
    ];
  }

  public bindKeydownListener(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.accessDropdownContainer.nativeElement, "keydown")
        .pipe(untilDestroyed(this))
        .subscribe((event: KeyboardEvent) => {
          // closes the dropdown and returns the focus to the dropdown trigger button in the top-nav-bar on TAB press, when any of the conditions below are in place
          if (
            event.key === TAB &&
            // pressing BACK-TAB when the radio button section is focused
            ((event.shiftKey && this.accessDropdownContainer.nativeElement.firstElementChild.contains(event.target as Element)) ||
              // pressing TAB when the radio button section is focused and no other sections are rendered below
              (!event.shiftKey && !this.shareableLink?.shouldDisplay && this.selectedAccessOption.value !== "restricted") ||
              // pressing TAB when the simple-search input is focused and no other sections are rendered below
              (!event.shiftKey && !this.shareableLink?.shouldDisplay && (event.target as Element).closest("simple-search")) ||
              // pressing TAB when the share-link button is focused and no other sections are rendered below
              (!event.shiftKey && this.shareableLink?.shouldDisplay && (event.target as Element).closest("okr-views-copy-link-form")))
          ) {
            this.zone.run(() => {
              event.preventDefault();
              this.changePermissionsVisibility();
              this.triggerButton.nativeElement.focus();
            });
          }

          // closes the simple-search popup when its input is focused and the user presses TAB or BACK-TAB
          if (event.key === TAB && (event.target as Element).closest("simple-search")) {
            this.zone.run(() => {
              this.simpleSearch.closeSearchResults();
            });
          }

          // closes the dropdown and returns the focus to the dropdown trigger button in the top-nav-bar on ESACPE press, when the simple-search dropdown is not open
          if (event.key === ESCAPE && !this.simpleSearch?.shouldShowPopover) {
            this.zone.run(() => {
              this.changePermissionsVisibility();
              this.triggerButton.nativeElement.focus();
            });
          }
        });
    });
  }

  public getItemOwnerAriaLabel(permission: IApiPermission): string {
    return (this.assignees?.get(permission?.principalId)?.name ?? "deleted user") + ", owner";
  }

  public getAccessInviteeAriaLabel(permission: IApiPermission): string {
    const inviteeName = this.principalsMap[permission?.principalId]?.name ?? (permission.principalKind === "role" ? "deleted role" : "deleted user");
    const grantedAccess = permission.grant.general.includes("update") ? "can edit" : "can view";

    return `${inviteeName}, ${grantedAccess}`;
  }

  public addPrincipal(value: Search<SearchCollection>): void {
    this.simpleSearch?.focusSearchInput();

    if (this.access.permissions.some((p) => p.principalId === value.id)) {
      return;
    }

    const newPermission: IApiPermission = {
      principalKind: principalKindByCollectionMap.get(value.collectionName),
      grant: {
        general: ["read"],
      },
      principalId: value.id,
    };
    this.access = { ...this.access, permissions: [...this.access.permissions, newPermission] };
    this.markUsedPrincipals();

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Given", {
      id: newPermission.principalId,
      property_type: newPermission.principalKind,
      permissions: newPermission.grant.general.join(","),
      role: this.getRoleToTrack(newPermission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public removePermission(permission: IApiPermission, removedElementContainer: Element): void {
    this.access = { ...this.access, permissions: [...this.access.permissions.filter((p) => p.principalId !== permission.principalId)] };
    this.markUsedPrincipals();

    this.isPermissionDropdownVisible = false;
    this.focusNextAvailableInviteeAccessDropdown(removedElementContainer);

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Removed", {
      id: permission.principalId,
      property_type: permission.principalKind,
      permissions: permission.grant.general.join(","),
      role: this.getRoleToTrack(permission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public checkOverflow(id: string): boolean {
    return checkOverflow(id);
  }

  public changePermissionsVisibility(): void {
    this.showPermissions = !this.showPermissions;
    this.toggle.emit({ isVisible: this.showPermissions });

    if (this.showPermissions) {
      this.focusSelectedAccessRadioButton();
    }
  }

  public get shouldDisplayGridAlert(): boolean {
    return this.selectedAccessOption.labelKey !== "private_access_only_you";
  }

  private createAccessObject(userPermissions: string[] = [], accountPermissions: string[] = []): IAccess {
    const permissions = [];

    if (userPermissions.length > 0) {
      permissions.push({
        principalKind: "user",
        grant: {
          general: userPermissions,
        },
        principalId: getCurrentUserId(),
      });
    }

    if (accountPermissions.length > 0) {
      permissions.push({
        principalKind: "account",
        grant: {
          general: accountPermissions,
        },
        principalId: this.accountResolverService.getAccountId(),
      });
    }

    return {
      inherits: false,
      permissions,
    };
  }

  private getRoleToTrack(permission: IApiPermission): string {
    if (permission.principalKind === "role") {
      const role = this.principals.find((principal) => principal.id === permission.principalId);
      return (role as IRole)?.accountId ? "custom_role" : role?.name;
    }
  }

  private focusNextAvailableInviteeAccessDropdown(removedElementContainer: Element): void {
    // try to focus the next element, if there is one, if not - focus the previous one (the `owner` row will always be rendered as a first row)
    const nextFocusableTargetRow = removedElementContainer.nextElementSibling ?? removedElementContainer.previousElementSibling;

    // the owner row is read-only and doesn't have a button in it, but rather a span, so check what the target should be
    const isNextFocusTargetOwnerRow = nextFocusableTargetRow?.classList.contains("principal-owner-row");
    const target = isNextFocusTargetOwnerRow ? ".item-owner-label" : "button";

    nextFocusableTargetRow?.querySelector<HTMLElement>(target)?.focus();
  }

  private focusSelectedAccessRadioButton(): void {
    const selectedItemIndex = this.accessOptions?.findIndex((option) => option.value === this.selectedAccessOption?.value) ?? 0;
    const targetButton = this.accessDropdownContainer.nativeElement.querySelectorAll<HTMLInputElement>('input[type="radio"]').item(selectedItemIndex);

    // await for the popover to be rendered
    window.setTimeoutOutsideAngular(() => {
      targetButton?.focus();
    });
  }
}
