import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAvatarSize } from "@quantive/ui-kit/avatar";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { AssigneesModule } from "@webapp/assignees/assignees.module";

@Component({
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-assignee-avatar-group",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ui-assignee-avatar-group [uiAlignRight]="uiAlignRight" [uiMax]="uiMax" [uiSize]="uiSize">
      <ui-assignee-avatar *ngFor="let assigneeId of filteredAssigneeIds" [fromAssigneeId]="assigneeId" [hideDeleted]="hideDeleted" />
    </ui-assignee-avatar-group>
  `,
  standalone: true,
  imports: [CommonModule, AssigneesModule],
})
export class AssigneeAvatarGroupComponent {
  @Input()
  public assigneeIds: string[] = [];

  @Input()
  public uiSize: UiAvatarSize = "md";

  @Input()
  public uiMax = 5;

  @Input()
  @InputBoolean()
  public uiAlignRight?: boolean;

  @Input()
  @InputBoolean()
  public hideDeleted?: boolean;

  @Input()
  public startIndex = 0;

  get filteredAssigneeIds(): string[] {
    return (this.assigneeIds || []).slice(this.startIndex);
  }
}
