<div *ngIf="suggestionsStatus?.isLoading">
  <div class="sp-mt-4" *ngFor="let _ of [].constructor(maxMetricsToSuggest - goal?.metrics?.length)">
    <pi-loading-card-v2 *ngIf="isInline" [isSelected]="false" borderStyle="dashed"></pi-loading-card-v2>
    <pi-loading-card-v2 *ngIf="!isInline" [isCustomIcon]="true" [isSelected]="false" iconTheme="fill" iconType="metric"></pi-loading-card-v2>
  </div>
</div>

<div *ngIf="suggestionsStatus?.isError">
  <div class="sp-mt-4">
    <pi-clickable-error-card
      [errorData]="suggestionsStatus.errorData"
      [instance]="metricsInstance"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"></pi-clickable-error-card>
  </div>
</div>

<div *ngIf="metrics && metrics.length > 0 && !suggestionsStatus?.isLoading && !suggestionsStatus?.isError">
  <div class="sp-mt-4" *ngFor="let metric of metrics; let i = index; let first = first" [class.sp-mt-4]="!first" data-test-id="okr-suggestion-item-key-results">
    <pi-feedback-card
      *ngIf="((isInline && !metric.isHidden) || !isInline) && !metric.isLoading && !metric.isError"
      [borderStyle]="borderStyle"
      [goal]="goal"
      [iconTheme]="iconTheme"
      [iconType]="iconType"
      [instance]="metricsInstance"
      [isIconAvailable]="true"
      [isInline]="isInline"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"
      [suggestion]="getMetricFeedbackSuggestion(metric, i)"
      suggestionFeedbackSubType="title"
      suggestionFeedbackType="KR">
    </pi-feedback-card>

    <pi-loading-card-v2
      *ngIf="((isInline && !metric.isHidden) || !isInline) && metric.isLoading && isInline"
      [instance]="metricsInstance"
      [suggestion]="getMetricFeedbackSuggestion(metric, i)"
      borderStyle="dashed"></pi-loading-card-v2>

    <pi-loading-card-v2
      *ngIf="((isInline && !metric.isHidden) || !isInline) && metric.isLoading && !isInline"
      [instance]="metricsInstance"
      [isCustomIcon]="true"
      [suggestion]="getMetricFeedbackSuggestion(metric, i)"
      iconTheme="fill"
      iconType="metric"></pi-loading-card-v2>

    <pi-clickable-error-card
      *ngIf="((isInline && !metric.isHidden) || !isInline) && metric.isError"
      [errorData]="metric.errorData"
      [instance]="metricsInstance"
      [isRemoveBtnAvailable]="false"
      [suggestion]="getMetricFeedbackSuggestion(metric, i)"
      (errorRefresh)="suggestAnotherMetric(metric, i)"
      (goBackToSuggestion)="onGoBackToSuggestionClicked(i)">
    </pi-clickable-error-card>
  </div>
</div>
