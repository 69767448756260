import { Injectable } from "@angular/core";
import { Observable, map, tap } from "rxjs";
import { IdMap } from "@gtmhub/util";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { KpiEventsMediatorService } from "../components/kpis-grid-page/kpis-grid/services/kpi-events.mediator.service";
import { KpisStatsDataForTimeRangePayload } from "../components/kpis-grid-page/models";
import { Kpi, KpiDTO, ModifiableKpi } from "../models/kpis.models";
import { KpisApiService } from "./kpis-api.service";
import { KpisState } from "./kpis-state.service";

@Injectable({
  providedIn: "any",
})

// TODO: fix models
export class KpisFacade extends BaseFacade<Kpi, KpiDTO, KpisState, KpisApiService> {
  constructor(
    state: KpisState,
    api: KpisApiService,
    private kpiEventsMediator: KpiEventsMediatorService
  ) {
    super(state, api);
  }

  public patchKpis$(patchableKpis: IdMap<ModifiableKpi>): Observable<unknown> {
    return this.apiService.patch$(null, { kpis: patchableKpis }, { url: this.apiService.getBasePath(HttpActions.patch) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public patchKpi$(kpiId: string, kpi: ModifiableKpi): Observable<unknown> {
    return this.apiService.patch$(kpiId, { kpi }, { url: this.apiService.getBasePath(HttpActions.patch) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public postKpi$(kpi: ModifiableKpi): Observable<unknown> {
    return this.apiService.post$(kpi).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public deleteKpi$(kpiId: string): Observable<unknown> {
    return this.apiService.delete$(kpiId).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public getKpisStatsForTimeRange$(payload: KpisStatsDataForTimeRangePayload): Observable<unknown> {
    return this.apiService
      .post$(payload, {
        ...new RequestConfig(),
        url: this.apiService.getKpisStatsForTimeRangeEndpoint(),
      })
      .pipe(map((value) => value || { kpisStats: [] }));
  }
}
