<sub-nav-options *ngIf="showInsightReports$ | async" [options]="customReportOptions"></sub-nav-options>
<default-sub-navigation
  *ngIf="(hideAllReports$ | async) === false"
  [favorites]="favorites$ | async"
  [itemsType]="'lists'"
  [loading]="loading$ | async"
  [options]="options"
  [recent]="recents$ | async"
  [suggested]="suggested$ | async"
  (markItemAsFavorite)="markItemAsFavorite($event)"
  (removeFromSuggested)="removeFromSuggested($event)"
  (unmarkItemAsFavorite)="unmarkItemAsFavorite($event)"></default-sub-navigation>
