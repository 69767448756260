<suggestion-drawer-content-card classes="sp-py-6 sp-px-10">
  <pi-loading-card-v2 *ngIf="isLoading" [instance]="tagsInstance" [isIconAvailable]="false" [suggestion]="feedbackSuggestion"></pi-loading-card-v2>

  <pi-clickable-error-card *ngIf="isError" [errorData]="errorData" [instance]="tagsInstance" [isRemoveBtnAvailable]="false" [suggestion]="feedbackSuggestion"> </pi-clickable-error-card>

  <pi-feedback-card
    *ngIf="!isLoading && !isError"
    [goal]="goal"
    [instance]="tagsInstance"
    [suggestion]="feedbackSuggestion"
    data-test-id="okr-suggestion-item-tags"
    suggestionFeedbackSubType="tags"
    suggestionFeedbackType="objective">
  </pi-feedback-card>
</suggestion-drawer-content-card>
