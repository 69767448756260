<ng-container *ngFor="let button of buttons">
  <button
    *ngIf="!button.isVisible$ || (button.isVisible$ | async)"
    [attr.aria-label]="button.ariaLabel"
    [attr.data-test-id]="button.dataTestId"
    [uiShape]="button.shape"
    [uiSize]="button.size"
    [uiType]="button.type"
    (click)="button.onClick()"
    ui-button>
    <i *ngIf="shouldDisplayIcon(button)" [uiSize]="getIconSize(button)" [uiTheme]="getIconTheme(button)" [uiType]="getIconType(button)" ui-icon></i>
    {{ button.key | localize }}
  </button>
</ng-container>

<gh-dropdown
  *ngIf="dropdown && (!dropdown.isVisible$ || (dropdown.isVisible$ | async))"
  [hasCustomButtonContent]="true"
  [menuItems]="dropdown.menuItems"
  [showNewDesign]="true"
  (dropdownToggled)="dropdownToggled($event)"
  placement="bottomRight">
  <button
    [attr.aria-expanded]="dropdown.isExpanded"
    [disabled]="dropdown.disabled"
    aria-haspopup="true"
    data-test-id="top-nav-bar-buttons-dropdown"
    gh-dropdown-custom-button-content
    ui-button
    uiShape="round"
    uiTrigger="click"
    uiType="primary">
    <span> {{ dropdownKey | localize }}</span>
    <i class="sp-ml-10" ui-icon uiSize="sm" uiType="chevron-down-small"></i>
  </button>
</gh-dropdown>
