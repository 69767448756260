<div *ngIf="notification.operationType === 'watcher_added'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.targetType === 'goal'">{{ "user_added_you_as_watcher_objective" | localize: { names: notification.notificationData.actor.names } }}</span>
    <span *ngIf="notification.targetType === 'metric'">{{ "user_added_you_as_watcher_kr" | localize: { names: notification.notificationData.actor.names } }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" *ngIf="notification.targetType === 'goal'" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'metric'" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'watcher_removed'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.targetType === 'goal'">{{ "user_removed_you_as_watcher_objective" | localize: { names: notification.notificationData.actor.names } }}</span>
    <span *ngIf="notification.targetType === 'metric'">{{ "user_removed_you_as_watcher_kr" | localize: { names: notification.notificationData.actor.names } }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" *ngIf="notification.targetType === 'goal'" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'metric'" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>
