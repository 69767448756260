<ng-template #containerTemplate>
  <ul>
    <li>
      <button [attr.aria-label]="locale.prev_page" [direction]="dir" [disabled]="isFirstIndex" [itemRender]="itemRender" (click)="prePage()" type="prev" ui-pagination-item></button>
    </li>
    <li class="ant-pagination-simple-pager" [attr.aria-label]="pageIndex + '/' + lastIndex">
      <input [disabled]="disabled" [value]="pageIndex" (keydown.enter)="jumpToPageViaInput($event)" size="3" />
      <span class="ant-pagination-slash">/</span>
      {{ lastIndex }}
    </li>
    <li>
      <button [attr.aria-label]="locale?.next_page" [direction]="dir" [disabled]="isLastIndex" [itemRender]="itemRender" (click)="nextPage()" type="next" ui-pagination-item></button>
    </li>
  </ul>
</ng-template>
