<div class="box-wrapper">
  <i class="info-img" a11yTitle="Info icon" ui-icon uiType="info"></i>
  <div>
    <div class="box-header">{{ heading }}</div>
    <div class="box-body">{{ description }}</div>
    <button class="box-btn" [disabled]="loading" [tabindex]="-1" (click)="automate.emit()" data-test-id="automate-button" ui-button uiShape="round" uiType="primary">
      {{ "automate" | localize }}
    </button>
  </div>
  <ui-loading-indicator class="loading-indicator" *ngIf="loading" uiType="linear"></ui-loading-indicator>
</div>
