<ui-button-split [uiButtonText]="'create_okr' | localize" (buttonClick)="openCreateObjectiveModal()">
  <ul ui-menu>
    <li *ngIf="isGuidedOKRsCreationVisible$ | async" ui-menu-item>
      <i ui-icon uiType="artificial-intelligence"></i>
      <a class="text-color-gray-13" (click)="openQuantivePlusWizard()" href="javascript:void(0);">{{ "suggest_team_okrs" | localize }}</a>
    </li>
    <li *editionFeature="'marketplace'" ui-menu-item>
      <i ui-icon uiType="add-from-marketplace"></i>
      <a class="text-color-gray-13" href="/marketplace/okrs-examples">{{ "choose_from_marketplace" | localize }}</a>
    </li>
    <li *ngIf="canCreateWhiteboards$ | async" ui-menu-item>
      <i ui-icon uiType="whiteboards"></i>
      <a class="text-color-gray-13" uiSref="gtmhub.whiteboards">{{ "draft_on_whiteboard" | localize }}</a>
    </li>
  </ul>
</ui-button-split>
