<div [formGroup]="objective">
  <div class="key-result" *ngFor="let kr of keyResults.controls; let index = index" formArrayName="keyResults" uiAlign="top">
    <div [formGroupName]="index">
      <div class="key-result" [uiAlign]="'middle'" ui-row>
        <div ui-col>
          <i class="fs-16px" ui-icon uiTheme="fill" uiType="metric"></i>
        </div>

        <div class="sp-ml-12" *ngIf="!kr.value.isEdit" ui-col uiFlex="462px">
          <div [attr.data-test-id]="'suggest-okrs-modal-key-result-for-objective-suggestion-' + objective.value.title">{{ kr.value.title }}</div>
        </div>

        <div class="sp-ml-12" *ngIf="kr.value.isEdit" ui-col uiFlex="1">
          <div ui-row>
            <input [uiFocus]="true" formControlName="title" type="text" ui-input ui-row />
          </div>
        </div>

        <div class="confirm-btns sp-ml-12" *ngIf="kr.value.isEdit" ui-row uiAlign="middle" uiJustify="space-between">
          <i class="sp-mr-12 text-color-gray-6" (click)="cancel(kr, index)" ui-icon uiType="close"></i>

          <button [disabled]="kr.invalid" (click)="confirm(kr)" ui-button uiShape="circle" uiSize="small" uiType="primary">
            <i class="text-color-gray-1 fs-16px" ui-icon uiType="check"></i>
          </button>
        </div>

        <div class="sp-ml-auto" *ngIf="showKeyResultActions && !kr.value.isEdit" ui-col>
          <div class="btns" ui-row uiJustify="space-between">
            <button class="sp-p-8" [uiTooltipTitle]="'edit_suggestion' | localize" (click)="openEditMode(kr)" ui-button ui-tooltip uiTooltipPlacement="topRight" uiType="link">
              <i class="fs-16px" ui-icon uiType="edit"></i>
            </button>

            <button class="sp-p-8" [uiTooltipTitle]="'remove_suggestion' | localize" (click)="onRemoveKeyResult(index)" ui-button ui-tooltip uiTooltipPlacement="topRight" uiType="link">
              <i class="fs-16px" ui-icon uiType="close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
