<div class="badge-mask" [style.background-color]="badge.background">
  <img alt="" src="/dist/img/badge_mask.svg" />
</div>
<div class="badge-circle"></div>
<ui-avatar class="badge" [fromBadge]="badge" uiSize="xxl"></ui-avatar>
<div class="awardees">
  <div class="awardee" *ngFor="let assigneeId of assignment.assigneeIds">
    <ui-assignee-avatar [fromAssigneeId]="assigneeId" [uiShowTooltip]="true" uiSize="md"></ui-assignee-avatar>
  </div>
</div>
<div class="badge-announcement-title">{{ "The " + badge.name + " badge" }}</div>
<rich-text-viewer *ngIf="assignment.comment" [text]="assignment.comment" textAlign="center"></rich-text-viewer>
<div class="badge-author" *ngIf="assignment.assignedById">
  <ui-assignee [fromAssigneeId]="assignment.assignedById" uiSize="sm"></ui-assignee>
</div>
