<ui-select
  [(ngModel)]="selectedValue"
  [a11yLabelledby]="a11yLabelledby"
  [a11yRequired]="a11yRequired"
  [contentOnly]="true"
  [readonly]="readonly"
  [uiAllowClear]="!a11yRequired"
  [uiCustomTemplate]="selectedTemplate"
  [uiDeselectOption]="deselectOption"
  [uiDisabled]="disabled"
  [uiDropdownMatchSelectWidth]="false"
  [uiId]="uiId"
  [uiOptionHeightPx]="36"
  [uiPlaceHolderTemplate]="placeHolderTemplate"
  [uiShowArrow]="false"
  (ngModelChange)="onSelection($event)"
  data-test-id="custom-dropdown-menu"
  uiDropdownClassName="cf-select-container"
  uiSize="small">
  <ui-option *ngFor="let option of options" [uiLabel]="option.value" [uiValue]="option" uiCustomContent>
    <div class="cf-select-list-option-container">
      <div class="cf-select-option" [ngStyle]="{ 'background-color': option.color }">
        <span>{{ option.value }}</span>
      </div>
    </div>
  </ui-option>
</ui-select>

<ng-template #selectedTemplate let-selected>
  <div class="cf-select-option" [ngStyle]="{ 'background-color': selected.nzValue.color }">
    <span>{{ selected.nzValue.value }}</span>
    <i class="cf-selected-option-down-arrow" ui-icon uiSize="xs" uiType="chevron-down-small"></i>
  </div>
</ng-template>

<ng-template #placeHolderTemplate>
  <div class="cf-select-option cf-select-empty-option">
    <span>{{ "select" | localize }}</span>
    <i ui-icon uiSize="xs" uiType="chevron-down-small"></i>
  </div>
</ng-template>
