<ng-container *ngIf="showPagination">
  <ng-container *ngIf="nzSimple; else defaultPagination.template">
    <ng-template [ngTemplateOutlet]="simplePagination.template"></ng-template>
  </ng-container>
</ng-container>
<ui-pagination-simple
  #simplePagination
  [disabled]="nzDisabled"
  [itemRender]="nzItemRender"
  [locale]="locale"
  [pageIndex]="nzPageIndex"
  [pageSize]="nzPageSize"
  [total]="nzTotal"
  (pageIndexChange)="onPageIndexChange($event)"></ui-pagination-simple>
<ui-pagination-default
  #defaultPagination
  [disabled]="nzDisabled"
  [itemRender]="nzItemRender"
  [locale]="locale"
  [pageIndex]="nzPageIndex"
  [pageSize]="nzPageSize"
  [pageSizeOptions]="nzPageSizeOptions"
  [showQuickJumper]="nzShowQuickJumper"
  [showSizeChanger]="nzShowSizeChanger"
  [showTotal]="nzShowTotal"
  [total]="nzTotal"
  [uiSize]="size"
  (pageIndexChange)="onPageIndexChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"></ui-pagination-default>
