<ui-select
  [a11yLabelledby]="a11yLabelledby"
  [a11yRequired]="a11yRequired"
  [ngModel]="selectedValues"
  [readonly]="readonly"
  [uiDisabled]="disabled"
  [uiDropdownMatchSelectWidth]="false"
  [uiId]="uiId"
  [uiMode]="'multiple'"
  [uiShowArrow]="!borderless"
  [uiShowEmptyDropdown]="false"
  (ngModelChange)="onSelection($event)"
  data-test-id="custom-field-multiselect"
  uiDropdownClassName="cf-multi-select-container"
  uiShowSearch
  uiSize="large">
  <ng-container>
    <ui-option *ngFor="let option of options" [uiHide]="selectedValuesSet?.has(option.value)" [uiLabel]="option.value" [uiValue]="option.value"></ui-option>
  </ng-container>
</ui-select>
