<div
  class="btn-group"
  #buttonContainer
  [(uiVisible)]="visible"
  [attr.id]="'gh-dropdown-' + dropdownButtonId"
  [class.btn-group--v2]="!isNavigationDropdownButtonClassNeeded"
  [uiDropdownMenu]="menu"
  [uiOverlayClassName]="uiOverlayClassName"
  (uiVisibleChange)="onVisibleChange($event)"
  ui-dropdown
  uiTrigger="click">
  <button
    *ngIf="!hasCustomButtonContent"
    [a11yTitle]="dropdownTitle"
    [attr.aria-controls]="currentActiveItemIndex !== null ? 'gh-dropdown-menu' : null"
    [attr.aria-expanded]="visible ? true : null"
    [class.latest-active-dropdown-button]="visible"
    [class.navigation-dropdown-button-v2]="!isNavigationDropdownButtonClassNeeded"
    [class.navigation-dropdown-button]="isNavigationDropdownButtonClassNeeded"
    aria-haspopup="true"
    type="button"
    ui-button>
    <ng-content select="[gh-dropdown-toggle]"></ng-content>
  </button>

  <ng-content *ngIf="hasCustomButtonContent" select="[gh-dropdown-custom-button-content]"></ng-content>

  <ui-dropdown-menu #menu>
    <ul id="gh-dropdown-menu" [ngClass]="dropdownMenuClass" (keydown)="onKeyDown($event)" role="menu">
      <ng-container *ngFor="let item of menuItems">
        <li
          *ngIf="!item.isVisible$ || (item.isVisible$ | async)"
          [activeDropdownItemChange$]="activeDropdownItemChange$"
          [class.active-dropdown-item]="isItemActive(item)"
          [class.destructive]="item.isDestructive"
          [class.gh-separator]="item.endOfSection"
          [class.navigation-dropdown-item-disabled]="item.disabled"
          [isActive]="isItemActive(item)"
          [item]="item"
          [showNewDesign]="showNewDesign"
          [withGap]="true"
          (click)="handleItemClick(item)"
          gh-dropdown-menu-item
          role="menuitem"></li>
      </ng-container>
    </ul>
  </ui-dropdown-menu>
</div>
