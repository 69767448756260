<div [class]="prefixCls + '-footer'">
  <div *ngIf="extraFooter" [class]="prefixCls + '-footer-extra'">
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="isTemplateRef(extraFooter)">
        <ng-container *ngTemplateOutlet="$any(extraFooter)"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="isNonEmptyString(extraFooter)">
        <span [innerHTML]="extraFooter"></span>
      </ng-container>
    </ng-container>
  </div>
  <a
    *ngIf="showToday"
    [class]="prefixCls + '-today-btn ' + (isTodayDisabled ? prefixCls + '-today-btn-disabled' : '')"
    [title]="todayTitle"
    (click)="isTodayDisabled ? null : onClickToday()"
    role="button">
    {{ locale.today }}
  </a>
  <ul *ngIf="hasTimePicker || rangeQuickSelector" [class]="prefixCls + '-ranges'">
    <ng-container *ngTemplateOutlet="rangeQuickSelector"></ng-container>
    <li *ngIf="showNow" [class]="prefixCls + '-now'">
      <a [class]="prefixCls + '-now-btn'" (click)="isTodayDisabled ? null : onClickToday()">
        {{ locale.now }}
      </a>
    </li>
    <li *ngIf="hasTimePicker" [class]="prefixCls + '-ok'">
      <button [disabled]="okDisabled" (click)="okDisabled ? null : clickOk.emit()" type="button" ui-button uiSize="small" uiType="primary">
        {{ locale.ok }}
      </button>
    </li>
  </ul>
</div>
