<div class="edit-insight-container sp-mb-32">
  <i class="neutral-warn" ui-icon uiType="neutral-warn"></i>
  <h3 class="sp-mb-16 fs-20px">{{ "edit_insight_modal_title" | localize }}</h3>
  <p class="@gray-color-9 sp-mb-8" data-test-id="edit-insight-modal-description-message">{{ "edit_insight_modal_description" | localize: { relatedItemsCount: relatedItemsCount } }}</p>

  <ui-radio-group [(ngModel)]="editInsightMethod">
    <label class="sp-mt-16" ui-radio uiValue="existing">{{ "edit_insight_modal_save_existing" | localize }}</label>

    <div class="sp-ml-24" *ngIf="editInsightMethod === 'existing'">
      <div *ngIf="relatedKRs.length > 0">
        <h4 class="fs-12px text-color-gray-7">{{ "key_results" | localize }}</h4>
        <div class="sp-mb-12">
          <item-name-with-icon-and-owner
            *ngFor="let kr of relatedKRs; let i = index"
            [hidden]="i > 2 && shouldHideKRs"
            [name]="kr.name"
            [ownerIds]="kr.ownerIds"
            data-test-id="item-name-in-edit-insight-modal"
            icon="metric">
          </item-name-with-icon-and-owner>
          <div class="load-more fs-12px sp-ml-4 sp-mt-6" *ngIf="relatedKRs.length > 3" (click)="shouldHideKRs = !shouldHideKRs">
            {{ shouldHideKRs ? getMoreRelatedItemsString(relatedKRs.length) : "less..." }}
          </div>
        </div>
      </div>

      <div *ngIf="relatedKPIs.length > 0">
        <h4 class="fs-12px text-color-gray-7">{{ "kpis" | localize }}</h4>
        <div class="sp-mb-12">
          <item-name-with-icon-and-owner
            *ngFor="let kpi of relatedKPIs; let i = index"
            [hidden]="i > 2 && shouldHideKPIs"
            [name]="kpi.name"
            [ownerIds]="kpi.ownerIds"
            data-test-id="item-name-in-edit-insight-modal"
            icon="kpi">
          </item-name-with-icon-and-owner>
          <div class="load-more fs-12px sp-ml-4 sp-mt-6" *ngIf="relatedKPIs.length > 3" (click)="shouldHideKPIs = !shouldHideKPIs">
            {{ shouldHideKPIs ? getMoreRelatedItemsString(relatedKPIs.length) : "less..." }}
          </div>
        </div>
      </div>

      <div *ngIf="relatedInsightboards.length > 0">
        <h4 class="fs-12px text-color-gray-7">{{ "insightboards" | localize }}</h4>
        <div class="sp-mb-12">
          <item-name-with-icon-and-owner
            *ngFor="let insightboard of relatedInsightboards; let i = index"
            [hidden]="i > 2 && shouldHideInsightboards"
            [name]="insightboard.name"
            data-test-id="item-name-in-edit-insight-modal"
            icon="insightboard">
          </item-name-with-icon-and-owner>
          <div class="load-more fs-12px sp-ml-4 sp-mt-6" *ngIf="relatedInsightboards.length > 3" (click)="shouldHideInsightboards = !shouldHideInsightboards">
            {{ shouldHideInsightboards ? getMoreRelatedItemsString(relatedInsightboards.length) : "less..." }}
          </div>
        </div>
      </div>
    </div>

    <div class="no-permission-items fs-12px text-color-gray-10 sp-ml-24 sp-mb-24" *ngIf="itemsWithoutPermissionsCount > 0 && editInsightMethod === 'existing'">
      <span class="gh-icon tiny gh-neutral-warn"></span>
      {{ "edit_insight_modal_no_permission_items" | localize: { itemsWithoutPermissionsCount: itemsWithoutPermissionsCount } }}
    </div>

    <label class="sp-mt-6" (click)="focusInput()" ui-radio uiValue="new">{{ "edit_insight_modal_save_as_new" | localize }}</label>
    <input class="sp-ml-24 sp-mb-16" #inputElement *ngIf="editInsightMethod === 'new'" [(ngModel)]="newInsightTitle" type="text" ui-input />
  </ui-radio-group>
</div>
