<pi-card-wrapper #cardWrapper [borderStyle]="borderStyle" [instance]="instance" [isToggleEnabled]="isToggleEnabled" [suggestion]="suggestion">
  <pi-text-subcard
    [isHovered]="cardWrapper.isHovered"
    [isRemoveBtnAvailable]="isRemoveBtnAvailable && isRemoveBtnAtHeader"
    [removeBtnTooltipTitle]="removeBtnTooltipTitle"
    [textToDisplay]="errorData.errorMessage"
    (remove)="errorRemove.emit()"
    fontColor="light"
    fontColorOnHover="dark"
    pi-subcard>
    <pi-card-icon [isHovered]="cardWrapper.isHovered" iconColor="light" iconColorOnHover="dark" iconTheme="outline" iconType="caution"></pi-card-icon>
  </pi-text-subcard>

  <pi-card-action-bar [instance]="instance" [isFeedbackActionsSectionAvailable]="false">
    <pi-error-action-bar-main-actions
      [isBackBtnAvailable]="errorData.isMaliciousInstructionError"
      [isRefreshBtnAvailable]="!errorData.isMaliciousInstructionError"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable && !isRemoveBtnAtHeader"
      [removeBtnTooltipTitle]="removeBtnTooltipTitle"
      (errorRefresh)="onRefreshErrorClicked()"
      (errorRemove)="errorRemove.emit()"
      (goBackToSuggestion)="onGoBackToSuggestionClicked()"
      pi-action-bar-main-actions>
    </pi-error-action-bar-main-actions>
  </pi-card-action-bar>
</pi-card-wrapper>
