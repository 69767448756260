<i class="sp-mr-10" [uiType]="icon" ui-icon uiSize="sm" uiTheme="fill"></i>
<div
  #nameElem
  *ngIf="isNameReadonly()"
  [attr.aria-describedby]="a11yDescribedby"
  [attr.aria-haspopup]="ariaHasPopup"
  [attr.aria-label]="ariaLabel"
  [ngClass]="{ name: !isDisabled, disabled: isDisabled, bold: bold, obfuscated: obfuscated }"
  [tabindex]="tabIndex"
  [tooltipIfOverflow]="showTooltipWhenTextHidden ? 'hover' : null"
  [uiTooltipTitle]="name || placeholder"
  role="link"
  ui-tooltip>
  {{ name || placeholder }}
</div>

<input class="input" *ngIf="!name && isEditable" [(ngModel)]="newName" [placeholder]="placeholder" (blur)="inputChanged()" (keyup.enter)="inputChanged()" ui-input />
