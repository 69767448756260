<pi-card-wrapper #cardWrapper [borderStyle]="isInline ? 'dashed' : borderStyle" [instance]="instance" [isAskAiVisible]="isAskAiVisible" [suggestion]="suggestion">
  <ng-container *ngIf="!isInline; else subcardWithInlineStyle" pi-subcard>
    <pi-text-subcard [isIconAvailable]="isIconAvailable" [isSql]="isSql" [textToDisplay]="suggestion?.text" fontColor="dark">
      <pi-card-icon *ngIf="isIconAvailable" [iconTheme]="iconTheme" [iconType]="iconType"></pi-card-icon>
    </pi-text-subcard>
  </ng-container>

  <ng-template #subcardWithInlineStyle pi-subcard>
    <pi-text-subcard [isHovered]="cardWrapper.isHovered" [isRemoveBtnAvailable]="isRemoveBtnAvailable && isRemoveBtnAtHeader" [textToDisplay]="suggestion?.text" (remove)="removeBtnClicked()">
      <pi-card-icon [isHovered]="cardWrapper.isHovered" iconColor="light" iconColorOnHover="dark" iconTheme="outline" iconType="platform-intelligence-default"></pi-card-icon>
    </pi-text-subcard>
  </ng-template>

  <ng-container *ngIf="isAskAiVisible" ask-ai-to>
    <input #input [placeholder]="'ask_ai_to' | localize" [uiFocus]="true" (keydown.enter)="askAi(input.value)" data-test-id="pi-card-ask-ai-to-input-field" type="text" ui-input ui-row />
    <div class="gap-4px" ui-row>
      <button (click)="askAi(input.value)" data-test-id="pi-card-ask-ai-to-btn" ui-button uiShape="round" uiType="primary">{{ "ask_ai" | localize }}</button>
      <button (click)="isAskAiVisible = false" data-test-id="pi-card-cancel-ask-ai-to-btn" ui-button uiShape="round" uiType="link">{{ "cancel" | localize }}</button>
    </div>
  </ng-container>

  <pi-card-action-bar
    *ngIf="cardWrapper.isSelected"
    [entityId]="entityId"
    [goal]="goal"
    [instance]="instance"
    [isFeedbackActionsSectionAvailable]="isFeedbackActionsSectionAvailable"
    [suggestion]="suggestion"
    [suggestionFeedbackSubType]="suggestionFeedbackSubType"
    [suggestionFeedbackType]="suggestionFeedbackType">
    <pi-suggestion-action-bar-main-actions
      [copyBtnTooltipTitle]="copyBtnTooltipTitle"
      [improveBtnSpecificDropdownItems]="improveBtnSpecificDropdownItems"
      [instance]="instance"
      [isCopyBtnAvailable]="isCopyBtnAvailable"
      [isEditBtnAvailable]="isEditBtnAvailable"
      [isImproveBtnAvailable]="isImproveBtnAvailable"
      [isRefreshBtnAvailable]="isRefreshBtnAvailable"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable && !isRemoveBtnAtHeader"
      [primaryButtonText]="primaryButtonText"
      [suggestion]="suggestion"
      (askAiClick)="askAiTo()"
      pi-action-bar-main-actions>
    </pi-suggestion-action-bar-main-actions>
  </pi-card-action-bar>
</pi-card-wrapper>
