<span
  [class.ant-radio-button-checked]="isChecked && isRadioButton"
  [class.ant-radio-button-disabled]="nzDisabled && isRadioButton"
  [class.ant-radio-button]="isRadioButton"
  [class.ant-radio-checked]="isChecked && !isRadioButton"
  [class.ant-radio-disabled]="nzDisabled && !isRadioButton"
  [class.ant-radio]="!isRadioButton">
  <input
    #inputElement
    [attr.aria-checked]="isChecked"
    [attr.autofocus]="nzAutoFocus ? 'autofocus' : null"
    [attr.name]="name"
    [attr.tabindex]="isChecked ? '0' : '-1'"
    [checked]="isChecked"
    [class.ant-radio-button-input]="isRadioButton"
    [class.ant-radio-input]="!isRadioButton"
    [disabled]="nzDisabled"
    type="radio" />
  <span [class.ant-radio-button-inner]="isRadioButton" [class.ant-radio-inner]="!isRadioButton"></span>
</span>
<span [ngStyle]="hasCustomRadioContent && { display: 'block', width: '100%', padding: '0' }"><ng-content></ng-content></span>
