<div class="skeleton">
  <ui-list [uiSplit]="false">
    <ui-list-item class="sp-mb-32" *ngFor="let i of [1, 2, 3, 4]">
      <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 1, width: '100%' }" [uiSectionHeight]="{ title: 'h-sm', paragraph: 'h-sm' }"></ui-skeleton>
    </ui-list-item>
  </ui-list>

  <div [uiGutter]="[16, 16]" ui-row>
    <div *ngFor="let i of [1, 2, 3]" ui-col uiSpan="8">
      <ui-card [uiSm]="12" style="min-width: 100%" ui-col>
        <ui-skeleton [uiActive]="true" [uiLoading]="true" [uiParagraph]="{ width: '100%' }" [uiSectionHeight]="{ title: 'h-sm', paragraph: 'h-md' }"></ui-skeleton>
      </ui-card>
    </div>
    <div *ngFor="let i of [1, 2, 3]" ui-col uiSpan="8">
      <ui-card [uiSm]="12" style="min-width: 100%" ui-col>
        <ui-skeleton [uiActive]="true" [uiLoading]="true" [uiParagraph]="{ width: '100%' }" [uiSectionHeight]="{ title: 'h-sm', paragraph: 'h-md' }"></ui-skeleton>
      </ui-card>
    </div>
  </div>
</div>
