import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IAiRecommendationsNotification } from "@gtmhub/notifications/models/notifications";
import { AiRecommendationRepository } from "@webapp/ai-recommendations/ai-recommendations-repository.service";
import { InboxTemplateComponent } from "../inbox-template.component";

interface KeyValuePair {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Key: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Value: string;
}

interface ReflectionObject {
  questionText?: string;
  text?: string;
  [key: string]: string | undefined;
}

type ReflectionArray = KeyValuePair[][];

@Component({
  selector: "ai-recommendations-inbox-template",
  templateUrl: "./ai-recommendations-inbox-template.component.html",
  styleUrls: ["./ai-recommendations-inbox-template.component.less", "../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiRecommendationsInboxTemplateComponent extends InboxTemplateComponent<IAiRecommendationsNotification> {
  constructor(private aiRecommendationsRepository: AiRecommendationRepository) {
    super();
  }

  public applyRecommendation(): void {
    const recommendationId = this.notification.notificationData.item.recommendationId;
    this.aiRecommendationsRepository
      .patch$({
        id: recommendationId,
        patchModel: { suggestionState: "accepted", actionType: "execute_recommendation" },
      })
      .subscribe();
  }

  public rejectRecommendation(): void {
    const recommendationId = this.notification.notificationData.item.recommendationId;
    this.aiRecommendationsRepository
      .patch$({
        id: recommendationId,
        patchModel: { suggestionState: "rejected", actionType: "execute_recommendation" },
      })
      .subscribe();
  }

  // Helper function to get the keys of the item changes
  public getChangeKeys(): string[] {
    const changes = this.notification.notificationData.item.itemChanges?.items || {};
    return Object.keys(changes);
  }

  // Utility function to extract values based on the new structure
  private extractValue(value: string | number | ReflectionObject | ReflectionArray | null | undefined): string {
    if (value === null || value === undefined) {
      return "N/A";
    }

    if (typeof value === "number") {
      return value.toString();
    }

    if (this.isReflectionTargetType()) {
      return this.processReflectionValue(value);
    }

    return String(value);
  }

  private isReflectionTargetType(): boolean {
    return this.notification.notificationData.item.targetType === "reflection";
  }

  private processReflectionValue(value: string | ReflectionObject | ReflectionArray): string {
    if (Array.isArray(value)) {
      return this.processReflectionArray(value as ReflectionArray);
    }

    if (typeof value === "object") {
      return this.processReflectionObject(value as ReflectionObject);
    }

    return String(value);
  }

  private processReflectionArray(arrayValue: ReflectionArray): string {
    return arrayValue
      .map((answerGroup, index) => {
        const answerObject = this.convertToKeyValueObject(answerGroup);
        return this.formatReflection(answerObject, index + 1);
      })
      .join("\n\n");
  }

  private processReflectionObject(objectValue: ReflectionObject): string {
    return this.formatReflection(objectValue);
  }

  private convertToKeyValueObject(answerGroup: KeyValuePair[]): ReflectionObject {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return answerGroup.reduce<ReflectionObject>((acc, { Key, Value }) => {
      acc[Key] = Value;
      return acc;
    }, {});
  }

  private formatReflection(reflection: ReflectionObject, index?: number): string {
    const questionText = reflection.questionText || "N/A";
    const answerText = reflection.text || "N/A";
    const questionLabel = index !== undefined ? `**Question ${index}**` : `**Question**`;
    const answerLabel = index !== undefined ? `**Answer ${index}**` : `**Answer**`;

    return `${questionLabel}: ${questionText}\n${answerLabel}: ${answerText}`;
  }

  // Helper function to get the old value of a specific change key
  public getOldValue(changeKey: string): string {
    const change = this.notification.notificationData.item.itemChanges?.items?.[changeKey];
    return this.extractValue(change?.oldValue);
  }

  // Helper function to get the new value of a specific change key
  public getNewValue(changeKey: string): string {
    const change = this.notification.notificationData.item.itemChanges?.items?.[changeKey];
    return this.extractValue(change?.newValue);
  }
}
