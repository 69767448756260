import { Injectable } from "@angular/core";
import { ColumnLockedChangeEvent, ColumnReorderEvent, ColumnResizeArgs } from "@progress/kendo-angular-grid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";

// gridActions -> "sort" | "wrap" | "freeze" | "reorder" | "resize" | "show-column" | "hide-column"
// bulkActions -> "bulk-delete" | "bulk-assign" | "bulk-tag"
// topNavBarActions -> "save" | "save-as" | "export" | "share"

export enum ColumnVisibility {
  Show = "show",
  Hide = "hide",
}
const OKRS_GRID_EVENT = "Okrs Grid Event";

type Meta = {
  type: "gridActions" | "topNavBarActions" | "bulkActions";
  info: Record<string, unknown>;
};

@Injectable({
  providedIn: "root",
})
export class OkrsGridTrackingService {
  constructor(private analyticsService: AnalyticsService) {}

  public trackGridEvent(meta: Meta): void {
    this.analyticsService.track(OKRS_GRID_EVENT, meta);
  }

  public trackColumnFreeze(event: ColumnLockedChangeEvent): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: "freeze-column",
        event,
      },
    });
  }

  public trackColumnResize(event: ColumnResizeArgs[]): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: "resize-column",
        event,
      },
    });
  }

  public trackColumnReorder(event: ColumnReorderEvent): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: "reorder-column",
        event,
      },
    });
  }

  public trackSortColumn(event: SortDescriptor[]): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: "sort-column",
        event,
      },
    });
  }

  public trackGridShowOrHideColumns(columnTitle: string, visibility: ColumnVisibility): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: visibility === ColumnVisibility.Show ? "show-column" : "hide-column",
        columnTitle,
      },
    });
  }

  public trackGridWrap(event: boolean): void {
    this.trackGridEvent({
      type: "gridActions",
      info: {
        action: "wrap-grid",
        event,
      },
    });
  }

  public trackSaveNewView(okrViewId: string): void {
    this.trackGridEvent({
      type: "topNavBarActions",
      info: {
        action: "save-new-okr-view-button",
        okrViewId,
      },
    });
  }

  public trackSaveView(okrViewId: string): void {
    this.trackGridEvent({
      type: "topNavBarActions",
      info: {
        action: "save-button",
        okrViewId,
      },
    });
  }

  public trackShareLink(): void {
    this.trackGridEvent({
      type: "topNavBarActions",
      info: {
        action: "share-link-button",
      },
    });
  }

  public trackExportToExcel(): void {
    this.trackGridEvent({
      type: "topNavBarActions",
      info: {
        action: "export-excel-button",
      },
    });
  }
}
