<ul class="options" [class.options--settings]="isSettings">
  <ng-container *ngFor="let option of options">
    <li class="options__item" *ngIf="(option.showOptionCondition$ | async) || !option.showOptionCondition$">
      <button class="options__btn" [attr.data-test-id]="'sub-nav-tab'" [ngClass]="{ 'options__btn--active': isActive(option) }" (click)="view(option)" tabindex="0">
        <i class="options__icon" *ngIf="option.icon" [class.options__icon--settings]="isSettings" [uiType]="option.icon" ui-icon uiTheme="outline"></i>
        <p class="options__label">{{ option.label | localize }}</p>
        <ui-label *ngIf="option.tag" [uiColor]="option.tag.color">{{ option.tag.text | localize }}</ui-label>
      </button>
    </li>
  </ng-container>
</ul>
