<ng-template #contentTemplate let-content="content">
  <ng-content *ngIf="!content"></ng-content>
  {{ content }}
</ng-template>
<ng-container *ngIf="!editing">
  <ng-container *ngIf="expanded || (!hasOperationsWithEllipsis && nzEllipsisRows === 1 && !hasEllipsisObservers) || canCssEllipsis || (nzSuffix && expanded); else jsEllipsis">
    <ng-template [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ content: nzContent }"></ng-template>
    <ng-container *ngIf="nzSuffix">{{ nzSuffix }}</ng-container>
  </ng-container>
  <ng-template #jsEllipsis>
    <span #ellipsisContainer></span>
    <ng-container *ngIf="isEllipsis">{{ ellipsisStr }}</ng-container>
    <ng-container *ngIf="nzSuffix">{{ nzSuffix }}</ng-container>
    <a class="ant-typography-expand" #expandable *ngIf="nzExpandable && isEllipsis" (click)="onExpand()">
      {{ locale?.expand }}
    </a>
  </ng-template>
</ng-container>

<ui-text-edit *ngIf="nzEditable" [icon]="nzEditIcon" [text]="nzContent" [tooltip]="nzEditTooltip" (endEditing)="onEndEditing($event)" (startEditing)="onStartEditing()"></ui-text-edit>

<ui-text-copy *ngIf="nzCopyable && !editing" [icons]="nzCopyIcons" [text]="copyText" [tooltips]="nzCopyTooltips" (textCopy)="onTextCopy($event)"></ui-text-copy>
