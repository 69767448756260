<div class="sp-p-24 card-container" *ngFor="let card of cards; let index = index" [class.bg-color-gray-2]="card.isPrimary" ui-row>
  <div ui-col>
    <i class="card-icon" [uiType]="card.icon" ui-icon></i>
  </div>

  <div ui-col>
    <div class="sp-mb-12" ui-row>
      <h3 class="text-color-gray-13" ui-col>{{ card.title }}</h3>
    </div>

    <div class="sp-mb-12" ui-row>
      <div class="text-color-gray-9">{{ card.description }}</div>
    </div>

    <div ui-row>
      <a *ngIf="card.isPrimary && (hasGranularPIFeaturePermissionToView$ | async)" (click)="this.openCard(card)" ui-button uiShape="round" uiSize="default" uiType="primary">
        <i ui-icon uiType="artificial-intelligence"></i>
        <span class="sp-ml-12">{{ "suggest_okrs" | localize }}</span>
      </a>

      <button *ngIf="!card.isPrimary" (click)="this.openCard(card)" ui-button uiShape="round" uiSize="default" uiType="default">{{ "select" | localize }}</button>
    </div>
  </div>
</div>
