<ng-container *ngIf="selectedAssigneesMenu">
  <ng-template [ngTemplateOutlet]="selectedAssigneesMenu"></ng-template>
</ng-container>

<div ui-row>
  <ui-select
    [a11yDescription]="a11yDescription"
    [a11yLabelledby]="a11yLabelledby"
    [a11yRequired]="a11yRequired"
    [errorOcurred]="errorOcurred"
    [hideSelectedTags]="hideSelectedTags"
    [ngModel]="uiSelectedValue"
    [problematicSelectItems]="problematicSelectItems"
    [readonly]="readonly"
    [renderRemoveChipIcon]="false"
    [uiAllowClear]="allowClear"
    [uiClearIcon]="clearIcon"
    [uiCustomTemplate]="selectedTemplate"
    [uiDisabled]="disabled"
    [uiDropdownStyle]="uiDropdownStyle"
    [uiId]="uiId"
    [uiMode]="mode"
    [uiOptionHeightPx]="37"
    [uiPlaceHolder]="placeholderKey | localize"
    [uiRemoveIcon]="null"
    [uiShowArrow]="uiShowArrow"
    [uiSuffixIcon]="arrowTemplate"
    (ngModelChange)="onSelected($event)"
    (uiBlur)="onBlur()"
    (uiFocus)="emitFocus()"
    (uiOnSearch)="search($event)"
    (uiOpenChange)="emitOpenChange($event)"
    (uiScrollToBottom)="onScrollToBottom()"
    data-test-id="custom-user-team-selector"
    ui-col
    uiDropdownClassName="people-selector-ddl"
    uiServerSearch
    uiShowSearch
    uiSize="large"
    uiXs="24">
    <ng-template #arrowTemplate>
      <i class="ui-arrow-custom fs-16px" ui-icon uiType="chevron-down-small"></i>
    </ng-template>
    <ui-option *ngIf="isLoading" uiCustomContent uiDisabled>
      <gh-indicator class="people-indicator" [ghValue]="{ progress: isLoading }"></gh-indicator>
    </ui-option>
    <ng-container *ngFor="let assignee of assigneeOptions">
      <ui-option [uiDisabled]="!!assignee.invalid" [uiHide]="isLoading || (hideSelected && uiSelectedIdMap[assignee.id])" [uiLabel]="assignee.name" [uiValue]="assignee.id" uiCustomContent>
        <ui-assignee [uiAssignee]="assignee" [uiNameEllipsis]="true" data-test-id="assignee-option-item" uiSize="sm"></ui-assignee>
      </ui-option>
    </ng-container>
    <ui-option *ngIf="isLazyLoading" uiCustomContent uiDisabled>
      <gh-indicator class="people-indicator" [ghValue]="{ progress: isLazyLoading }"></gh-indicator>
    </ui-option>
  </ui-select>
</div>

<ng-template #selectedTemplate let-selected>
  <ui-chip
    [attr.data-test-id]="'remove-owner-' + assigneesIdMap?.[selected.nzValue]?.name"
    [chipAssignee]="assigneesIdMap?.[selected.nzValue] || deletedAssigneesIdMap?.[selected.nzValue] || deletedAssignee(selected.nzValue)"
    [class.error-select-item]="problematicSelectItems.includes(selected.nzValue)"
    [uiCloseable]="showCloseIcon"
    (uiOnClose)="removeAssignee(selected.nzValue)"
    tabindex="-1">
    <ui-assignee-name [uiAssignee]="assigneesIdMap?.[selected.nzValue] || deletedAssigneesIdMap?.[selected.nzValue] || deletedAssignee(selected.nzValue)" />
  </ui-chip>
</ng-template>

<ng-template #clearIcon>
  <i ui-icon uiType="close-big"></i>
</ng-template>
