import { Injectable } from "@angular/core";
import { Observable, catchError, combineLatest, from, map, of, switchMap } from "rxjs";
import { MetricService } from "@gtmhub/okrs/metrics/services/metric.service";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { IAllowedActionsMap } from "@webapp/sessions/models/sessions.model";
import { GranularTasksPermissionsService } from "./granular-tasks-permissions.service";

type NegateParam = { negate: boolean | undefined };

@Injectable({ providedIn: "root" })
export class TasksPermissionsService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private editionFeatureService: EditionFeatureService,
    private granularTasksPermissionsService: GranularTasksPermissionsService,
    private goalsFacade: GoalsFacade,
    private metricService: MetricService,
    private permissionsFacade: PermissionsFacade
  ) {}

  public hasPermission$(
    permissionCheck: () => Observable<boolean>,
    action: keyof IAllowedActionsMap,
    options: {
      negate?: boolean;
      parentType?: string;
      parentId?: string;
      ownerId?: string;
    } = {}
  ): Observable<boolean> {
    const currentUserId = getCurrentUserId();

    return combineLatest([this.isTasksFeatureEnabledForAccount$(), permissionCheck(), this.permissionsFacade.hasPermission$("AccessGoals")]).pipe(
      switchMap(([isTasksFeatureEnabled, hasPermission, hasAccessGoals]) => {
        return combineLatest([
          of(isTasksFeatureEnabled),
          of(hasPermission),
          of(hasAccessGoals),
          options.parentType === "goal" && hasAccessGoals ? this.goalsFacade.getGoal$(options.parentId) : of(null),
          options.parentType === "metric" && hasAccessGoals ? from(this.metricService.getMetric(options.parentId)) : of(null),
        ]);
      }),
      map(([isTasksFeatureEnabled, hasPermission, hasAccessGoals, goal, metric]) => {
        const isOwner = currentUserId === options.ownerId;

        if (options.parentType && !hasAccessGoals && !isOwner) {
          return !!options.negate;
        }

        const authorizationResult =
          hasPermission &&
          isTasksFeatureEnabled &&
          (!goal || goal.currentUserAllowedActions.includes(action) || isOwner) &&
          (!metric || metric.currentUserAllowedActions.includes(action) || isOwner);

        return options.negate ? !authorizationResult : authorizationResult;
      }),
      catchError(() => of(false))
    );
  }

  public hasPermissionToView$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isTasksFeatureEnabledForAccount$(), this.granularTasksPermissionsService.hasPermissionToView$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }
  public hasPermissionToOwn$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isTasksFeatureEnabledForAccount$(), this.granularTasksPermissionsService.hasPermissionToOwn$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToCreate$(options: { negate?: boolean; parentType?: string; parentId?: string; ownerId?: string } = {}): Observable<boolean> {
    return this.hasPermission$(this.granularTasksPermissionsService.hasPermissionToCreate$.bind(this.granularTasksPermissionsService), "create", options);
  }

  public hasPermissionToEdit$(options: { negate?: boolean; parentType?: string; parentId?: string; ownerId?: string } = {}): Observable<boolean> {
    return this.hasPermission$(this.granularTasksPermissionsService.hasPermissionToEdit$.bind(this.granularTasksPermissionsService), "update", options);
  }

  public hasPermissionToUpdate$(options: { negate?: boolean; parentType?: string; parentId?: string; ownerId?: string } = {}): Observable<boolean> {
    return this.hasPermission$(this.granularTasksPermissionsService.hasPermissionToUpdate$.bind(this.granularTasksPermissionsService), "update", options);
  }

  public hasPermissionToDelete$(options: { negate?: boolean; parentType?: string; parentId?: string; ownerId?: string } = {}): Observable<boolean> {
    return this.hasPermission$(this.granularTasksPermissionsService.hasPermissionToDelete$.bind(this.granularTasksPermissionsService), "delete", options);
  }

  private isTasksFeatureEnabledForAccount$(): Observable<boolean> {
    return combineLatest([this.featureModuleService.isTaskModuleEnabled$(), this.editionFeatureService.hasFeature$("hub.tasks")]).pipe(
      map((conditions) => conditions.every((condition) => condition))
    );
  }

  private negateIfNecessary$({ hasPermission, negate }: { hasPermission: boolean; negate: boolean }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;
    return of(hasPermission !== shouldNegate);
  }
}
