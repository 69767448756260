<div ui-row uiAlign="middle" uiJustify="start">
  <button class="error-card-action" *ngIf="isBackBtnAvailable" (click)="onGoBackToSuggestionClicked($event)" ui-button uiShape="round" uiType="link">
    <i class="fs-16px" ui-icon uiType="back"></i>
    <span class="sp-ml-4">{{ "back" | localize }}</span>
  </button>
  <button class="error-card-action" *ngIf="isRefreshBtnAvailable" (click)="onRefreshErrorClicked($event)" ui-button uiShape="round" uiType="link">
    <i class="fs-16px" ui-icon uiType="refresh"></i>
    <span class="sp-ml-4">{{ "suggest_again" | localize }}</span>
  </button>
  <button
    class="sp-ml-4 error-card-action action-icon-only"
    *ngIf="isRemoveBtnAvailable"
    [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
    [uiTooltipTitle]="removeBtnTooltipTitle"
    (click)="onRemoveErrorClicked($event)"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomLeft">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="close-big"></i>
  </button>
</div>
