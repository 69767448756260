<div
  class="ant-message-notice"
  [attr.aria-describedby]="'ui-toast-content-' + instance.messageId"
  [attr.aria-label]="generateToastAriaLabel(instance.type)"
  [attr.aria-live]="instance.type === 'error' ? 'assertive' : 'polite'"
  [attr.role]="toastActionsTemplateRef ? 'alertdialog' : 'alert'"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()">
  <div class="ant-message-notice-content ui-toast-notice-content" [ngClass]="'ui-toast-' + instance.type">
    <div class="ant-message-custom-content ui-toast-custom-content" [ngClass]="'ant-toast-' + instance.type">
      <div class="ui-toast-leading-icon-wrapper" *ngIf="!uiHideIcon" [ngSwitch]="instance.type">
        <i class="ui-toast-icon" *ngSwitchCase="'success'" [a11yTitle]="generateToastAriaLabel('success')" role="img" ui-icon uiType="check-circle"></i>
        <i class="ui-toast-icon" *ngSwitchCase="'info'" [a11yTitle]="generateToastAriaLabel('info')" role="img" ui-icon uiType="info-circle"></i>
        <i class="ui-toast-icon" *ngSwitchCase="'warning'" [a11yTitle]="generateToastAriaLabel('warning')" role="img" ui-icon uiType="exclamation-circle"></i>
        <i class="ui-toast-icon" *ngSwitchCase="'error'" [a11yTitle]="generateToastAriaLabel('error')" role="img" ui-icon uiType="close-circle"></i>
        <i class="ui-toast-icon" *ngSwitchCase="'loading'" [a11yTitle]="generateToastAriaLabel('loading')" role="img" ui-icon uiType="loading"></i>
      </div>
      <div class="ui-toast-content-wrapper" *nzStringTemplateOutlet="instance.content">
        <span class="ui-toast-content" [id]="'ui-toast-content-' + instance.messageId" [innerHTML]="instance.content"></span>
      </div>

      <ng-container *ngIf="!toastActionsTemplateRef">
        <div class="vertical-spacer"></div>
      </ng-container>
      <ng-container *ngIf="toastActionsTemplateRef">
        <div class="ui-toast-actions-wrapper">
          <ng-container *ngTemplateOutlet="toastActionsTemplateRef"></ng-container>
        </div>
      </ng-container>

      <ng-template #dismissAction>
        <div class="ui-toast-actions">
          <button [a11yTitle]="'Toast.dismiss' | uiI18n" (click)="removeToast()" ui-button uiType="link">
            <i class="ui-toast-icon ui-toast-closeable sp-p-0" ui-icon uiType="close-big"></i>
          </button>
        </div>
      </ng-template>

      <ng-template #singleAction>
        <div class="vertical-spacer"></div>
        <div class="vertical-divider"></div>
        <div class="ui-toast-actions ui-toast-actions-single">
          <button (click)="onPrimaryAction()" ui-button uiSize="large">{{ uiPrimaryCTA }}</button>
        </div>
      </ng-template>

      <ng-template #multipleActions>
        <div class="vertical-spacer"></div>
        <div class="vertical-divider"></div>
        <div class="ui-toast-actions ui-toast-actions-multiple">
          <button (click)="onPrimaryAction()" ui-button uiSize="large">{{ uiPrimaryCTA }}</button>
          <div class="horizontal-divider"></div>
          <button (click)="onSecondaryAction()" ui-button uiSize="large">{{ uiSecondaryCTA }}</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
