import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { ITeam } from "@gtmhub/teams";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IViewHistoryItemResponse, IViewHistoryItemsGroupedResponse, IViewHistoryPayload } from "@gtmhub/view-history";
import { ViewHistoryFactory } from "@gtmhub/view-history/view-history-factory";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { KpiUiGroup } from "@webapp/kpis/models/kpi-group.models";
import { INavItem, NavItemsType } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavItemsModel, buildNavItemFromModel, getNavItemsType, viewHistoryGroupedReponseToNavItems } from "@webapp/navigation/utils/nav-items.util";
import { ViewHistoryPayloadBuilder } from "@webapp/navigation/utils/view-history-payload.builder";
import { RecentsApiService } from "./recents.api.service";
import { IRecentsModel, RecentsDtoModel } from "./recents.models";
import { RecentsState } from "./recents.state";

type NavItemsMap = { [key in UXCustomizationType]?: ICollection<INavItem> };

@Injectable()
export class RecentsFacade extends BaseFacade<IRecentsModel, RecentsDtoModel, RecentsState, RecentsApiService> {
  private recents$: BehaviorSubject<NavItemsMap> = new BehaviorSubject({});

  public constructor(
    state: RecentsState,
    private api: RecentsApiService,
    private viewHistoryFactory: ViewHistoryFactory,
    private viewHistoryPayloadBuilder: ViewHistoryPayloadBuilder
  ) {
    super(state, api);
  }

  public getAll$<IViewHistoryItemsGroupedResponse>(filter?: RequestPaging, config?: RequestConfig): Observable<IViewHistoryItemsGroupedResponse> {
    return super.getAll$<IViewHistoryItemsGroupedResponse>(filter, config);
  }

  public getRecentsOfType(entityNavType: string): Observable<ICollection<INavItem>> {
    return this.recents$.asObservable().pipe(map((value) => value[entityNavType]));
  }

  public getAllOfType(entityTypes: UXCustomizationType[], navItemsType: NavItemsType): void {
    if (this.recents$.value[navItemsType]) return;
    const requestPaging: RequestPaging = this.viewHistoryFactory.buildNavigationViewHistoryQueryParams(entityTypes);
    this.api.getAll$<IViewHistoryItemsGroupedResponse>(requestPaging).subscribe((recents: IViewHistoryItemsGroupedResponse) => {
      this.recents$.next({
        ...this.recents$.value,
        [navItemsType]: viewHistoryGroupedReponseToNavItems(recents, navItemsType),
      });
    });
  }

  private getViewHistoryPayloadForUXCType(uxcType: UXCustomizationType, item: SubNavItemsModel): IViewHistoryPayload | null {
    const payloadBuilder = this.viewHistoryPayloadBuilder.forType(uxcType).forSource("navigation");

    switch (uxcType) {
      case "user":
        payloadBuilder.withEmployee({ id: item.id });
        break;
      case "team":
        payloadBuilder.withTeam(item as ITeam);
        break;
      case "dashboard":
        payloadBuilder.withInsightboard({ id: item.id });
        break;
      case "session":
        payloadBuilder.withSession({ id: item.id });
        break;
      case "whiteboard":
        payloadBuilder.withWhiteboard(item as IWhiteboard);
        break;
      case "task":
        payloadBuilder.withTask({ id: item.id });
        break;
      case "list":
        payloadBuilder.withList({ id: item.id });
        break;
      case "kpigroup":
        payloadBuilder.withKpiGroup(item as KpiUiGroup);
        break;
      case "goal":
        payloadBuilder.withGoal({ id: item.id });
        break;
      case "metric":
        payloadBuilder.withMetric({ id: item.id });
        break;
      case "filter":
        payloadBuilder.withFilter({ id: item.id });
        break;
      case "kpi":
        payloadBuilder.withKpi({ id: item.id });
        break;
      case "kpiview":
        payloadBuilder.withKpiView({ id: item.id });
        break;
    }
    return payloadBuilder.generate();
  }

  public addItemToViewHistory(uxcType: UXCustomizationType, item: SubNavItemsModel): Observable<IViewHistoryItemResponse<UXCustomizationType>> {
    const payload = this.getViewHistoryPayloadForUXCType(uxcType, item);
    if (payload === null) return;

    return this.api.post$<IViewHistoryItemResponse<UXCustomizationType>>(payload).pipe(
      tap(() => {
        const navItemType = getNavItemsType(uxcType);

        // If the item is a KPI, we don't want to add it to the recents section
        if (!this.recents$.value[navItemType] || uxcType === "kpi") return;

        const newNavItem = buildNavItemFromModel(uxcType, item);

        const itemInRecentsCollection = this.recents$.value[navItemType].items.find((item) => item.id === newNavItem.id);
        this.recents$.next({
          ...this.recents$.value,
          [navItemType]: {
            ...this.recents$.value[navItemType],
            items: [newNavItem, ...this.recents$.value[navItemType].items.filter((item) => item.id !== newNavItem.id)],
            totalCount: this.recents$.value[navItemType].totalCount + (itemInRecentsCollection ? 0 : 1),
          },
        });
      })
    );
  }

  public updateItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    const navItemType = getNavItemsType(uxcType);
    if (!this.recents$.value[navItemType]) return;

    const newNavItem = buildNavItemFromModel(uxcType, item);
    if (newNavItem !== null) {
      this.recents$.next({
        ...this.recents$.value,
        [navItemType]: {
          ...this.recents$.value[navItemType],
          items: this.recents$.value[navItemType].items.map((oldValue) => {
            return oldValue.id === item.id ? { ...oldValue, ...newNavItem } : oldValue;
          }),
        },
      });
    }
  }

  public deleteItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    const navItemType = getNavItemsType(uxcType);
    if (!this.recents$.value[navItemType]) return;

    const newNavItem = buildNavItemFromModel(uxcType, item);
    const itemToBeDeleted = this.recents$.value[navItemType].items.find((recentItem) => recentItem.id === item.id);
    if (newNavItem !== null && itemToBeDeleted) {
      this.recents$.next({
        ...this.recents$.value,
        [navItemType]: {
          ...this.recents$.value[navItemType],
          items: this.recents$.value[navItemType].items.filter((oldItem) => oldItem.id !== item.id),
          totalCount: this.recents$.value[navItemType].totalCount - 1,
        },
      });
    }
  }

  public enrichEntitiesWithFavorites(favoritesCollection: ICollection<INavItem>, navItemType: NavItemsType): void {
    if (this.recents$.value && this.recents$.value[navItemType]) {
      this.recents$.next({
        ...this.recents$.value,
        [navItemType]: {
          ...this.recents$.value[navItemType],
          items: this.recents$.value[navItemType].items.map((item) => {
            const favoriteItem = favoritesCollection?.items.find((favItem) => favItem.id === item.id) ?? { favoriteId: undefined };
            return {
              ...item,
              favoriteId: favoriteItem.favoriteId,
            };
          }),
        },
      });
    }
  }
}
