<button
  *ngIf="isModuleGifsEnabled$ | async"
  [ngClass]="{ opened: showGifMenu }"
  [uiContent]="contentTemplate"
  [uiPlacement]="['bottom', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom']"
  [uiTrigger]="null"
  [uiVisible]="showGifMenu"
  (mousedown)="toggleGifSearchModal($event)"
  aria-label="add gif"
  tabindex="-1"
  ui-popover>
  <rich-text-editor-icon [icon]="gifIcon" [outOfFocus]="outOfFocus"></rich-text-editor-icon>
</button>

<ng-template #contentTemplate>
  <div class="add-gif-wrapper" [ngClass]="{ outOfFocus: outOfFocus }">
    <div class="gif-menu">
      <ui-input-text-field
        class="gif-input"
        [(value)]="searchQuery"
        [debounceTime]="1000"
        [focusMe]="showGifMenu"
        [uiPlaceholder]="'search_tenor_gifs' | localize"
        (valueChange)="searchGifs()"
        updateOn="change"></ui-input-text-field>
      <div class="gifs-container">
        <img class="gif" *ngFor="let gif of gifs" [src]="gif.url" (click)="selectGif(gif, $event)" alt="Quantive" />
        <img class="power-by-tenor-icon" alt="Quantive" src="/dist/img/tenor/PB_tenor_logo_grey_horizontal.svg" />
      </div>

      <div class="gh-modal-close-button close-btn" (click)="closeGifSearchModal()">
        <span class="nc-icon-outline icon ui-1_simple-remove"></span>
      </div>
    </div>
  </div>
</ng-template>
