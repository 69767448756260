<ng-template>
  <multi-selector
    [borderless]="borderless"
    [formControl]="formControl"
    [nodes]="nodes"
    [nodesLoading]="nodesLoading"
    [searchFunctionPlaceholder]="searchFn"
    [sessionIds]="sessionIds"
    [sortFunctionPlaceholder]="sortFn"
    [treeOffset]="1"
    [uiId]="controlId"
    (openChange)="this.openChange.emit($event)"
    (selectedItemChipClick)="selectedParentItemClicked.emit()">
  </multi-selector>
</ng-template>
