import { Ng2StateDeclaration } from "@uirouter/angular";
import { Ng1StateDeclaration } from "@uirouter/angularjs";
import { getHomeActiveScreenState, modal } from "@gtmhub/core/routing/util";
import { attachNg1StateDependency, featureAvailableWithRedirect, featureToggleDisabledWithRedirect, featureToggleEnabled } from "@gtmhub/resolve-guards";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { goalFutureStates, metricFutureStates, taskFutureStates } from "@webapp/okrs/okrs.future-states";
import { employeeFutureStates, teamFutureStates } from "@webapp/people/people.future-states";

class HomeStates {
  public home(): Ng1StateDeclaration {
    return attachNg1StateDependency(
      {
        name: "gtmhub.home",
        data: {
          requiresLogin: true,
          titleKey: "home",
          trackingPage: "Home Page",
        },
        url: "/home/",
        redirectTo: () => getHomeActiveScreenState("gtmhub.home"),
      },
      featureToggleDisabledWithRedirect(ModuleFlag.Feed, "gtmhub.home.dashboard")
    );
  }

  public dashboard(base: string): Ng1StateDeclaration {
    return {
      name: `${base}.dashboard`,
      data: {
        requiresLogin: true,
        titleKey: "dashboard",
        trackingPage: "Dashboard",
        pageBackgroundColor: "#e8ecf2",
        newLayout: true,
      },
      url: "dashboard/",
      component: "homeDashboard",
    };
  }

  public feed(base: string): Ng1StateDeclaration {
    return attachNg1StateDependency(
      {
        name: `${base}.feed`,
        data: {
          requiresLogin: true,
          titleKey: "feed",
          trackingPage: "Feed",
          pageBackgroundColor: "#fff",
        },
        url: "feed/",
        template: require("./views/feed.html"),
        controller: "FeedCtrl",
      },
      featureAvailableWithRedirect("feed", "gtmhub.home.dashboard"),
      featureToggleEnabled(ModuleFlag.Feed)
    );
  }

  public msTeamsHome(): Ng1StateDeclaration {
    return {
      name: "gtmhub.msTeamsHome",
      data: {
        requiresLogin: true,
        titleKey: "home",
        trackingPage: "Home Page",
      },
      url: "/msteams/home/",
      redirectTo: () => getHomeActiveScreenState("gtmhub.msTeamsHome"),
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _goals(base: string): Ng1StateDeclaration {
    return {
      name: `${base}._goals`,
      url: "goals/",
      abstract: true,
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _comment(base: string): Ng1StateDeclaration {
    return modal({
      name: `${base}._comment`,
      view: "commentsview",
      windowClass: "sidebar-modal feed-comment-sidebar-modal",
      abstract: true,
    });
  }

  public thread(base: string): Ng1StateDeclaration {
    return {
      name: `${base}.thread`,
      url: ":targetId/comments",
      data: {
        trackingPage: "Comments",
      },
      views: {
        "commentsview@": {
          template: require("../feed/views/feed-comments-thread.html"),
          controller: "FeedCommentsThreadCtrl",
        },
      },
    };
  }
}
const homeStates = new HomeStates();

const states: (Ng1StateDeclaration | Ng2StateDeclaration)[] = [
  homeStates.home(),

  homeStates.dashboard("gtmhub.home"),
  ...teamFutureStates("gtmhub.home.dashboard"), // needed for reflections to open the team details modal
  ...employeeFutureStates("gtmhub.home.dashboard"), // needed for the new navigation

  homeStates.feed("gtmhub.home"),

  homeStates.msTeamsHome(),
  homeStates.dashboard("gtmhub.msTeamsHome"),
  homeStates.feed("gtmhub.msTeamsHome"),
];

const homeScreens = ["home", "msTeamsHome"];

for (const screen of homeScreens) {
  states.push(homeStates._goals(`gtmhub.${screen}.dashboard`));
  states.push(homeStates._goals(`gtmhub.${screen}.feed`));
  states.push(...goalFutureStates(`gtmhub.${screen}.dashboard._goals`));
  states.push(...goalFutureStates(`gtmhub.${screen}.feed._goals`));
  states.push(...metricFutureStates(`gtmhub.${screen}.dashboard`));
  states.push(...taskFutureStates(`gtmhub.${screen}.dashboard`));

  states.push(homeStates._comment(`gtmhub.${screen}.feed`));
  states.push(homeStates.thread(`gtmhub.${screen}.feed._comment`));
  states.push(...metricFutureStates(`gtmhub.${screen}.feed`));
}

export { states };
