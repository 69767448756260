<ul class="bottom-section-nav">
  <li class="bottom-section-nav__item" *ngIf="showAppsSwitcherMenu" [attr.aria-label]="'Quantive ' + 'apps' | localize" [uiTooltipTitle]="'apps' | localize" ui-tooltip uiTooltipPlacement="right">
    <apps-dropdown [withoutBorderOnFocus]="true" data-test-id="new-navigation-apps-dropdown-button" iconType="apps"></apps-dropdown>
  </li>

  <li class="bottom-section-nav__item" *ngIf="showMarketplace$ | async">
    <a
      class="bottom-section-nav__link"
      [attr.aria-label]="'marketplace' | localize"
      [uiTooltipTitle]="'marketplace' | localize"
      (click)="setMarketplaceCookie()"
      aria-describedby="marketplace_aria_description"
      data-test-id="new-nav-icon-for-marketplace"
      gh-dropdown-toggle
      href="/marketplace"
      ui-tooltip
      uiTooltipPlacement="right">
      <i class="bottom-section-nav__icon" ui-icon uiSize="md" uiType="add-from-marketplace"></i>
    </a>
    <span class="a11y-description-hide" id="marketplace_aria_description">{{ "marketplace" | localize }} - {{ "this_links_leads_to_currently_inaccessible_content" | localize }}</span>
  </li>
  <li class="bottom-section-nav__item" *ngIf="settingsScreen.showScreenCondition$ | async">
    <a
      class="bottom-section-nav__link"
      [attr.aria-label]="'settings' | localize"
      [class.bottom-section-nav__link--active]="activeScreen === settingsScreen.key"
      [uiSref]="settingsScreen.state"
      [uiTooltipTitle]="settingsScreen.key | localize"
      ui-tooltip
      uiTooltipPlacement="right">
      <i class="bottom-section-nav__icon" ui-icon uiType="manage"></i>
    </a>
  </li>
  <li class="bottom-section-nav__item" [uiTooltipTitle]="'support_center' | localize" ui-tooltip uiTooltipPlacement="right">
    <help-dropdown class="bottom-section-nav__help-dropdown" [withoutBorderOnFocus]="true" iconType="help"></help-dropdown>
  </li>
  <li class="bottom-section-nav__item">
    <button
      class="bottom-section-nav__notif-btn"
      [attr.aria-label]="unreadNotificationsCountAriaLabel"
      [uiTooltipTitle]="'notifications' | localize"
      (click)="openNotificationsModal()"
      ui-tooltip
      uiTooltipPlacement="right">
      <ui-badge *ngIf="{ count: unreadNotificationsCount } as context" [uiCount]="context.count" [uiOffset]="[-2, 0]" [uiStyle]="{ right: '2px', 'font-size': '10px' }" uiSize="small">
        <i class="bottom-section-nav__icon" ui-icon uiType="notifications"></i>
      </ui-badge>
    </button>
  </li>
  <li class="bottom-section-nav__item">
    <profile-dropdown class="bottom-section-nav__profile" [withoutBorderOnFocus]="true"></profile-dropdown>
  </li>
</ul>
