<div class="suggestion-card-content" ui-row uiAlign="top">
  <div class="icon-container" *ngIf="isIconAvailable" ui-col>
    <ng-content select="pi-card-icon"></ng-content>
  </div>
  <div class="sp-py-6" *ngIf="isStringText" data-test-id="suggestion-text-content" ui-col uiFlex="1">
    <span class="text-content" #codeEle [class.language-sql]="isSql" [ngClass]="fontColorClass">{{ textToDisplay }}</span>
  </div>

  <div class="sp-py-4 multi-text" *ngIf="!isStringText" data-test-id="suggestion-tags-text-content" ui-col uiFlex="1">
    <span class="text-content" *ngFor="let val of textToDisplay">{{ val }}</span>
  </div>

  <button
    class="sp-ml-4"
    *ngIf="isRemoveBtnAvailable"
    [uiTooltipOverlayStyle]="actionBtnBottomRightTooltipCustomStyle"
    [uiTooltipTitle]="removeBtnTooltipTitle"
    (click)="removeBtnClicked($event)"
    data-test-id="suggestion-decline-button"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomRight">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="close-big"></i>
  </button>
</div>
