<ui-skeleton class="sp-p-16 sp-pr-32" *ngIf="indicators.loading" [uiLoading]="true" [uiParagraph]="{ rows: 3, width: '100%' }" [uiTitle]="false"></ui-skeleton>
<ng-container *ngIf="!indicators.loading">
  <default-sub-navigation [loading]="indicators.loading" [options]="options"></default-sub-navigation>
  <nav-items-list
    *ngIf="hasUserAccessToGoals$ | async"
    [collection]="sessions$ | async"
    [config]="{ sectionLabelKey: 'sessions', itemsType: 'sessions', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="okrs$ | async"
    [config]="{ sectionLabelKey: 'okrs', itemsType: 'okrs', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="kpis$ | async"
    [config]="{ sectionLabelKey: 'kpis', itemsType: 'kpis', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="whiteboards$ | async"
    [config]="{ sectionLabelKey: 'whiteboards', itemsType: 'whiteboards', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="lists$ | async"
    [config]="{ sectionLabelKey: 'reports', itemsType: 'lists', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="insightboards$ | async"
    [config]="{ sectionLabelKey: 'insightboards', itemsType: 'insightboards', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <nav-items-list
    [collection]="people$ | async"
    [config]="{ sectionLabelKey: 'people', itemsType: 'people', itemsGroupType, visibleItemsCount, broadcastConfig }"
    (unmarkItemAsFavorite)="onUnmarkItemAsFavorite($event)"></nav-items-list>
  <ng-container *ngIf="homeHasNoFavorites">
    <home-empty-sub-nav></home-empty-sub-nav>
  </ng-container>
</ng-container>
