<div *ngIf="notification.operationType === 'objective_user_assigned' || notification.operationType === 'objective_team_assigned' || notification.operationType === 'objective_team_assigned_create'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.notificationData.item.isTeamObjective">{{ "user_assigned_objective_to_your_team" | localize: { names: notification.notificationData.actor.names } }}</span>
    <span *ngIf="!notification.notificationData.item.isTeamObjective">{{
      "user_assigned_you_objective"
        | localize
          : {
              names: notification.notificationData.actor.names,
            }
    }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'objective_user_deleted' || notification.operationType === 'objective_team_deleted'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_deleted_okr" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "objective" | localize }}
        </div>
        : {{ notification.notificationData.item.name }}
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "owner_cap" | localize }}
        </div>
        : {{ notification.notificationData.item.assignee.names }}
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.parent">
        <div class="heading inline-notifications">
          {{ "aligned_under" | localize }}
        </div>
        : {{ notification.notificationData.item.parent }}
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.tags">
        <div class="heading inline-notifications">
          {{ "tags" | localize }}
        </div>
        : {{ notification.notificationData.item.tags }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'objective_user_modified' || notification.operationType === 'objective_team_modified'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_modified_objective" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.name?.oldValue">
        <div class="heading">{{ "title" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}</div>
          : {{ notification.notificationData.item.changes.name.oldValue }}
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}</div>
          : {{ notification.notificationData.item.changes.name.newValue }}
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.assignees?.oldValue || notification.notificationData.item.changes.assignees?.newValue">
        <div class="heading">{{ "owners_cap" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          <span>
            <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees.oldValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
            >&nbsp;
          </span>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees.newValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
          >&nbsp;
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.parent?.oldValue || notification.notificationData.item.changes.parent?.newValue">
        <div class="heading">{{ "aligned_under" | localize }}:</div>
        <div>
          <div class="list-item">{{ "old_parent" | localize }}:</div>
          <i class="fs-16px" [uiType]="notification.notificationData.item.changes.parentType?.oldValue" ui-icon uiTheme="fill"></i>
          {{ notification.notificationData.item.changes.parent.oldValue || "N/A" }}
        </div>
        <div>
          <div class="list-item">{{ "new_parent" | localize }}:</div>
          <i class="fs-16px" [uiType]="notification.notificationData.item.changes.parentType.newValue" ui-icon uiTheme="fill"></i>
          {{ notification.notificationData.item.changes.parent.newValue || "N/A" }}
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.tags?.oldValue || notification.notificationData.item.changes.tags?.newValue">
        <div class="heading">{{ "tags" | localize }}:</div>
        <div>
          <div class="list-item" *ngIf="notification.notificationData.item.changes.tags?.oldValue">{{ "removed" | localize }} : {{ notification.notificationData.item.changes.tags.oldValue }}</div>
        </div>
        <div>
          <div class="list-item" *ngIf="notification.notificationData.item.changes.tags?.newValue">{{ "added" | localize }} : {{ notification.notificationData.item.changes.tags.newValue }}</div>
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.description?.oldValue || notification.notificationData.item.changes.description?.newValue">
        <div class="heading">{{ "description" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.description.oldValue || 'N/A'"></rich-text-viewer>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.description.newValue || 'N/A'"></rich-text-viewer>
        </div>
      </div>
      <div *ngFor="let custField of notification.notificationData.item.changes.customFields">
        <div class="inbox-section" *ngIf="custField?.oldValue || custField?.newValue">
          <div class="heading">{{ "custom_field_updated" | localize: { custFieldName: custField.label } }}:</div>
          <div [ngSwitch]="custField.visualizationKind">
            <div class="list-item">{{ "from" | localize }}:</div>
            <div *ngSwitchCase="'gtmhub.selectassignee'">
              <span> <ui-assignee *ngFor="let assignee of $any(custField.oldValue)" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee>&nbsp; </span>
            </div>

            <div *ngSwitchCase="'gtmhub.multiselect'">
              <rich-text-viewer *ngFor="let value of $any(custField?.oldValue)" [text]="$any(value) || 'N/A'"></rich-text-viewer>
            </div>
            <div *ngSwitchCase="'gtmhub.datepicker'">
              {{ $any(custField?.oldValue) | date: "dd MMM, yyyy" }}
            </div>
            <div *ngSwitchDefault>
              <rich-text-viewer [text]="$any(custField?.oldValue) || 'N/A'"></rich-text-viewer>
            </div>

            <div class="list-item">{{ "to_change_state" | localize }}:</div>
            <div *ngSwitchCase="'gtmhub.selectassignee'">
              <span> <ui-assignee *ngFor="let assignee of $any(custField?.newValue)" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee>&nbsp; </span>
            </div>
            <div *ngSwitchCase="'gtmhub.multiselect'">
              <rich-text-viewer *ngFor="let value of $any(custField?.newValue)" [text]="$any(value) || 'N/A'"></rich-text-viewer>
            </div>
            <div *ngSwitchCase="'gtmhub.datepicker'">
              {{ $any(custField?.newValue) | date: "dd MMM, yyyy" }}
            </div>
            <div *ngSwitchDefault>
              <rich-text-viewer [text]="$any(custField?.newValue) || 'N/A'"></rich-text-viewer>
            </div>
          </div>
        </div>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'workflow_status_changed_draft_to_in_review' || notification.operationType === 'workflow_reviewers_added'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "asking_for_approval_of_objective" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'draft_created'">
  <img class="avatar" [attr.alt]="'draft_objective' | localize" src="/dist/img/icons/emails/draft.png" />
  <div class="title">{{ "status_objective_set_draft" | localize }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "to_be_finalized_status_objective_approve" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'workflow_status_changed_to_draft'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "objective_was_declined" | localize }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "status_objective_now_set_draft" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'okr_approved'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_approved_your_objective" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'okr_declined'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_declined_your_objective" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'okr_fully_approved'">
  <img class="avatar" [attr.alt]="'objective_approved' | localize" src="/dist/img/icons/emails/approved.png" />
  <div class="title">
    {{ "status_of_objective_now_approved" | localize }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'okr_withdrawn'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_withdrew_from_review_objective_status_draft" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "to_be_finalized_they_resend_for_review_and_get_obj_approved" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<!-- workflow_reviewers_change kept for backwards compatibility -->
<div *ngIf="notification.operationType === 'workflow_reviewers_change' || notification.operationType === 'workflow_reviewers_removed'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_removed_you_from_objective_reviewers" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "you_are_done_n_have_one_less_thing_on_desk" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'okr_back_to_in_review'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_changed_reviewers_status_obj_now_in_review" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "to_be_finalized_objective_needs_full_approved" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'workflow_status_changed_to_reviewers_not_enough'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "status_objective_set_to_need_reviewers" | localize }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="notification.targetType | localize" [uiType]="notification.targetType" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        {{ "your_admin_increase_number_approvals_needed" | localize }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>
