<div class="select-list-container-wrapper" [ngClass]="{ 'wrapper-loading': loading }">
  <ui-loading-indicator class="loading-indicator-position" [uiSpinning]="loading" [uiTip]="'loading' | localize"></ui-loading-indicator>
  <ng-container *ngIf="!loading">
    <div class="ant-select-item-empty" *ngIf="listOfContainerItem.length === 0">
      <nz-embed-empty [specificContent]="notFoundContent!" nzComponentName="select"></nz-embed-empty>
    </div>
    <cdk-virtual-scroll-viewport
      [class.full-width]="!matchWidth"
      [class.scrollbar-space-reserved]="reserveScrollbarSpace"
      [itemSize]="itemSize"
      [maxBufferPx]="maxBufferPx"
      [minBufferPx]="(itemSize + itemMarginTop) * maxItemLength"
      [style.height.px]="listOfContainerItem.length * (itemSize + itemMarginTop)"
      [style.max-height.px]="itemSize * maxItemLength"
      (scrolledIndexChange)="onScrolledIndexChange($event)">
      <ng-template [cdkVirtualForOf]="listOfContainerItem" [cdkVirtualForTemplateCacheSize]="0" [cdkVirtualForTrackBy]="trackValue" cdkVirtualFor let-item>
        <ng-container [ngSwitch]="item.type">
          <ui-option-item-group *ngSwitchCase="'group'" [uiLabel]="item.groupLabel"></ui-option-item-group>
          <ui-option-item
            *ngSwitchCase="'item'"
            [activatedValue]="activatedValue"
            [compareWith]="compareWith"
            [customContent]="item.nzCustomContent"
            [disabled]="item.nzDisabled"
            [grouped]="!!item.groupLabel"
            [icon]="menuItemSelectedIcon"
            [id]="item.nzValue | valueToID"
            [keyboardActivatedValue]="keyboardActivatedValue"
            [label]="item.nzLabel"
            [listOfSelectedValue]="listOfSelectedValue"
            [showState]="mode === 'tags' || mode === 'multiple'"
            [style.min-height.px]="itemSize"
            [template]="item.template"
            [value]="item.nzValue"
            (itemClick)="onItemClick($event)"
            (itemHover)="onItemHover($event)"></ui-option-item>
        </ng-container>
      </ng-template>
    </cdk-virtual-scroll-viewport>
    <ng-template [ngTemplateOutlet]="dropdownRender"></ng-template>
  </ng-container>
</div>
