<div *ngIf="suggestionsStatus?.isLoading">
  <div class="sp-mt-4" *ngFor="let _ of [].constructor(maxTasksToSuggest - taskList?.length)">
    <pi-loading-card-v2 *ngIf="isInline" [isSelected]="false" borderStyle="dashed"></pi-loading-card-v2>
    <pi-loading-card-v2 *ngIf="!isInline" [isCustomIcon]="true" [isSelected]="false" iconTheme="fill" iconType="task"></pi-loading-card-v2>
  </div>
</div>

<div *ngIf="suggestionsStatus?.isError">
  <div class="sp-mt-4">
    <pi-clickable-error-card
      [errorData]="suggestionsStatus.errorData"
      [instance]="tasksInstance"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"></pi-clickable-error-card>
  </div>
</div>

<div *ngIf="tasks && tasks.length > 0 && !suggestionsStatus?.isLoading && !suggestionsStatus?.isError">
  <div *ngFor="let task of tasks; let i = index; let first = first" [class.sp-mt-4]="!first" data-test-id="okr-suggestion-item-tasks">
    <pi-feedback-card
      *ngIf="((isInline && !task.isHidden) || !isInline) && !task.isLoading && !task.isError"
      [borderStyle]="borderStyle"
      [goal]="item"
      [iconTheme]="iconTheme"
      [iconType]="iconType"
      [instance]="tasksInstance"
      [isIconAvailable]="true"
      [isInline]="isInline"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      suggestionFeedbackSubType="title"
      suggestionFeedbackType="task">
    </pi-feedback-card>

    <pi-loading-card-v2
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isLoading && isInline"
      [instance]="tasksInstance"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      borderStyle="dashed"></pi-loading-card-v2>

    <pi-loading-card-v2
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isLoading && !isInline"
      [instance]="tasksInstance"
      [isCustomIcon]="true"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      iconTheme="fill"
      iconType="task"></pi-loading-card-v2>

    <pi-clickable-error-card
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isError"
      [errorData]="task.errorData"
      [instance]="tasksInstance"
      [isRemoveBtnAvailable]="false"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      (errorRefresh)="onSuggestAnotherTask(task, i)"
      (goBackToSuggestion)="onGoBackToSuggestionClicked(i)">
    </pi-clickable-error-card>
  </div>
</div>
