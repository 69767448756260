<header>
  <home-top-nav-bar />
</header>

<article>
  <div class="dashboard-container">
    <user-details [teamProfileSref]="'gtmhub.home.dashboard.team'" />
    <ng-container *ngIf="!isLoading; else skeletonTemplate">
      <container-empty-state *ngIf="showNoPermissions" [heading]="'ready_to_get_started' | localize" [message]="'contact_admin_adjust_permissions' | localize" pictureUrl="/dist/img/no-results.svg" />
      <ui-dashboard [uiOptions]="dashboardOptions" [uiWidgets]="dashboardWidgets" (uiChange)="onChange($event)" (uiDragStop)="onDragStop($event)" />
    </ng-container>
    <ng-template #skeletonTemplate>
      <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 4, width: '100%' }" [uiRound]="true"></ui-skeleton>
    </ng-template>
  </div>
</article>
