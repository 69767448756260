import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { KpiEventsMediatorService } from "@webapp/kpis/components/kpis-grid-page/kpis-grid/services/kpi-events.mediator.service";
import { KpiIdSnapshotsMap, KpiSnapshot, KpiSnapshotDTO } from "@webapp/kpis/models/kpi-snapshot.models";
import { KpiSnapshotsApiService } from "./kpi-snapshots-api.service";
import { KpiSnapshotsState } from "./kpi-snapshots-state.service";

@Injectable({
  providedIn: "any",
})
export class KpiSnapshotsFacade extends BaseFacade<KpiSnapshot, KpiSnapshotDTO, KpiSnapshotsState, KpiSnapshotsApiService> {
  constructor(
    state: KpiSnapshotsState,
    api: KpiSnapshotsApiService,
    private kpiEventsMediator: KpiEventsMediatorService
  ) {
    super(state, api);
  }

  public getKpiSnapshots$(kpiId: string, queryParams?: QueryParams): Observable<KpiIdSnapshotsMap> {
    return this.getAll$<KpiIdSnapshotsMap>(
      { filter: { _id: { $in: [kpiId] } } },
      {
        ...new RequestConfig(),
        url: this.apiService.getGetKpisSnapshotsEndpoint(),
        queryParams: queryParams,
      }
    );
  }

  public getKpisSnapshots$(filter?: RequestPaging, queryParams?: QueryParams): Observable<KpiIdSnapshotsMap> {
    return this.getAll$<KpiIdSnapshotsMap>(filter, {
      ...new RequestConfig(),
      url: this.apiService.getGetKpisSnapshotsEndpointV1(),
      queryParams: queryParams,
    });
  }

  public getKpisSnapshotsV2$(filter?: RequestPaging, queryParams?: QueryParams): Observable<KpiIdSnapshotsMap> {
    return this.getAll$<KpiIdSnapshotsMap>(filter, {
      ...new RequestConfig(),
      url: this.apiService.getGetKpisSnapshotsEndpoint(),
      queryParams: queryParams,
    });
  }

  public createKpiSnapshot$(kpiId: string, payload: KpiSnapshotDTO): Observable<KpiSnapshot> {
    return this.post$(payload, {
      ...new RequestConfig(),
      url: this.apiService.getCreateKpiSnapshotEndpoint(kpiId),
    }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public deleteKpiSnapshot$(kpiId: string, snapshotId: string): Observable<unknown> {
    return this.delete$(null, {
      ...new RequestConfig(),
      url: this.apiService.getDeleteSnapshotEndpoint(kpiId, snapshotId),
    }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }
}
