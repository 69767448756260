<div class="main-summary-wrap" [class.summary-expanded]="expanded">
  <div class="summary-report-wrap" #summary *ngIf="expanded">
    <div class="loading-skelton-wrap" *ngIf="!summaryReport">
      <ui-skeleton [uiActive]="true" [uiAvatar]="{ size: 'large', shape: 'circle' }" [uiParagraph]="false" [uiTitle]="false"></ui-skeleton>

      <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 3, width: '100%' }" [uiSectionHeight]="{ title: 'h-sm', paragraph: 'h-sm' }" [uiTitle]="false"></ui-skeleton>
    </div>

    <div class="summary-report" #report *ngIf="summaryReport" [attr.aria-label]="ariaRoleDescription" [attr.role-description]="ariaRoleDescription" role="figure">
      <div class="progress-charts-wrap">
        <ui-progress
          class="company-progress"
          [uiPercent]="formatAttainment(summaryReport.company.attainment)"
          [uiShowInfo]="false"
          [uiStrokeTrailWidth]="2"
          [uiStrokeWidth]="6"
          [uiWidth]="96"
          uiStrokeColor="#0057D7"
          uiStrokeTrailColor="#c0cbdd"
          uiType="circle"></ui-progress>
        <ui-progress
          class="my-teams-progress"
          *ngIf="summaryReport.myTeams"
          [uiPercent]="formatAttainment(summaryReport.myTeams.attainment)"
          [uiShowInfo]="false"
          [uiStrokeTrailWidth]="2.5"
          [uiStrokeWidth]="7"
          [uiWidth]="80"
          uiStrokeColor="#003A7B"
          uiStrokeTrailColor="#c0cbdd"
          uiType="circle"></ui-progress>
        <ui-progress
          class="my-teams-progress"
          *ngIf="summaryReport.currentTeam"
          [uiPercent]="formatAttainment(summaryReport.currentTeam.attainment)"
          [uiShowInfo]="false"
          [uiStrokeTrailWidth]="2.5"
          [uiStrokeWidth]="7"
          [uiWidth]="80"
          uiStrokeColor="#003A7B"
          uiStrokeTrailColor="#c0cbdd"
          uiType="circle"></ui-progress>
        <ui-progress
          class="my-progress"
          *ngIf="summaryReport.my"
          [uiPercent]="formatAttainment(summaryReport.my.attainment)"
          [uiShowInfo]="false"
          [uiStrokeTrailWidth]="3"
          [uiStrokeWidth]="8"
          [uiWidth]="64"
          uiStrokeColor="#EEA000"
          uiStrokeTrailColor="#c0cbdd"
          uiType="circle"></ui-progress>
        <ui-progress
          class="my-progress"
          *ngIf="summaryReport.members"
          [uiPercent]="formatAttainment(summaryReport.members.attainment)"
          [uiShowInfo]="false"
          [uiStrokeTrailWidth]="3"
          [uiStrokeWidth]="8"
          [uiWidth]="64"
          uiStrokeColor="#EEA000"
          uiStrokeTrailColor="#c0cbdd"
          uiType="circle"></ui-progress>
      </div>

      <div class="session-report-details">
        <p>{{ "average_session_progress" | localize }}</p>

        <ul [ngClass]="{ 'has-special-reports-access': (hasReportPermissions | async) === true && reportState }">
          <li class="my-progress" *ngIf="summaryReport.my">
            <span>{{ "me" | localize }}</span>
            <metric-progress-bar [delta]="summaryReport.my.attainmentDelta" [progress]="summaryReport.my.attainment" [uiHideBar]="true"></metric-progress-bar>
          </li>
          <li class="my-progress" *ngIf="summaryReport.members">
            <span>{{ "team_members_cap" | localize }}</span>
            <metric-progress-bar [delta]="summaryReport.members.attainmentDelta" [progress]="summaryReport.members.attainment" [uiHideBar]="true"></metric-progress-bar>
          </li>
          <li class="teams-progress" *ngIf="summaryReport.myTeams">
            <span>{{ "my_teams" | localize }}</span>
            <metric-progress-bar [delta]="summaryReport.myTeams.attainmentDelta" [progress]="summaryReport.myTeams.attainment" [uiHideBar]="true"></metric-progress-bar>
          </li>
          <li class="teams-progress" *ngIf="summaryReport.currentTeam">
            <span>{{ "team_cap" | localize }}</span>
            <metric-progress-bar [delta]="summaryReport.currentTeam.attainmentDelta" [progress]="summaryReport.currentTeam.attainment" [uiHideBar]="true"></metric-progress-bar>
          </li>
          <li class="company-progress">
            <span>{{ "company" | localize }}</span>
            <metric-progress-bar [delta]="summaryReport.company.attainmentDelta" [progress]="summaryReport.company.attainment" [uiHideBar]="true"></metric-progress-bar>
          </li>
        </ul>

        <a
          class="ui-link"
          *ngIf="(hasReportPermissions | async) === true && reportState"
          [ghTrackMeta]="{ screen_name: trackMeta.name, report_type: 'progress', session_id: sessionId, user_id: currentUserId }"
          [uiParams]="reportState.params()"
          [uiSref]="reportState.name()"
          ghTrack="Report Clicked"
          >{{ "view_session_report" | localize }}</a
        >
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isDisabled; else disabledExpander">
    <div
      class="summary-expander"
      [attr.aria-label]="expanded ? 'Collapse graph' : 'Expand graph'"
      [ghTrackMeta]="getTrackingMetadata()"
      [uiTooltipTitle]="(expanded ? 'hide_summary' : 'show_summary') | localize"
      (click)="toggleExpanded()"
      (keyup.enter)="toggleExpanded()"
      ghTrack="Button Clicked"
      role="button"
      tabindex="0"
      ui-tooltip
      uiTooltipPlacement="leftTop">
      <i [attr.aria-hidden]="true" ui-icon uiType="install"></i>
    </div>
  </ng-container>
  <ng-template #disabledExpander>
    <div class="summary-expander-disabled"></div>
  </ng-template>
</div>
