<div class="global-search" #globalSearch>
  <ng-container *ngIf="!isSearchFocused && !searchTerm">
    <button (click)="handleButtonClick($event)" (focusin)="onSearchFocus()" (mousedown)="handleButtonMouseDown($event)" aria-label="Search" ui-button uiShape="round">
      <i data-test-id="global-search-icon" ui-icon uiType="search"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="!isSearchFocused && !!searchTerm">
    <div class="search-actions" (click)="handleButtonClick($event)" (focusin)="onSearchFocus()" (mousedown)="handleButtonMouseDown($event)">
      <i data-test-id="global-search-icon" ui-icon uiType="search"></i>
      <input class="search-input" id="search-input-readonly" [ngModel]="searchTerm" [ngStyle]="{ width: '100px' }" [uiBorderless]="true" ui-input />
    </div>
  </ng-container>
  <ng-container *ngIf="isSearchFocused">
    <div class="search-actions" [uiContent]="resultTemplate" [uiVisible]="shouldShowPopover" ui-popover uiOverlayClassName="without-arrow" uiPlacement="bottomRight" uiTrigger="click">
      <i class="fs-16px" data-test-id="global-search-icon" ui-icon uiType="search"></i>
      <input
        class="search-input"
        id="search-input"
        #searchInput
        [(ngModel)]="searchTerm"
        [modelChangeDebounce]="500"
        [placeholder]="'search' | localize"
        [uiBorderless]="true"
        (focusout)="focusout($event)"
        (keydown.arrowDown)="focusSearchItems()"
        (keydown.esc)="onEsc()"
        (ngModelChange)="onSearchTermChange()"
        autocomplete="off"
        data-test-id="nav-bar-global-search-input"
        role="searchbox"
        ui-input />
      <ui-divider uiType="vertical"></ui-divider>
      <search-facet-menu class="search-facet-menu-dropdown" (facetMenuFocusout)="focusout($event)"></search-facet-menu>
    </div>
    <ng-template #resultTemplate>
      <div class="search-results" #resultsPopover [ngStyle]="{ 'max-height.px': calculateMaxHeight(), 'overflow-y': 'auto' }">
        <search-results (escKeydown)="onEsc()" searchMode="global"></search-results>
      </div>
    </ng-template>
  </ng-container>
</div>
