<ng-container *ngIf="(isAutomated$ | async) === false">
  <no-automation-selected *ngIf="!showSuggestions" [isLoadingSuggestions]="!!indicators.loadingSuggestions" [itemType]="itemType" (automate)="onAutomate()"></no-automation-selected>

  <suggested-automations *ngIf="showSuggestions" [automationSuggestions]="automationSuggestions" [itemType]="itemType" [selectorData$]="selectorData$" [usedIn]="usedIn"></suggested-automations>
</ng-container>

<selected-automation
  *ngIf="isAutomated$ | async"
  [isAutomationDeletable]="isAutomationDeletable"
  [isAutomationEditable]="isAutomationEditable"
  [isLoadingSuggestions]="!!indicators.loadingSuggestions"
  [selectorData$]="selectorData$"
  [usedIn]="usedIn"></selected-automation>
