import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, first, forkJoin, map, of } from "rxjs";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IPermissionsStoreState } from "@gtmhub/users/redux/models";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollectionLike } from "@webapp/core/core.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import {
  IUsersWithAllowedActionPerItem,
  ItemWithAccessType,
  Permission,
  PermissionActionDTO,
  PermissionActionVM,
  PermissionDTO,
  QuantiveProduct,
} from "@webapp/permissions/models/permissions.model";
import { PermissionsState } from "@webapp/permissions/services/permissions-state.service";
import { IAccess, IAllowedActionsMap, IUserItemAllowedActions, UserAction } from "@webapp/sessions/models/sessions.model";
import { PermissionsApiService } from "./permissions-api.service";

@Injectable({
  providedIn: "root",
})
export class PermissionsFacade extends BaseFacade<Permission, PermissionDTO, PermissionsState, PermissionsApiService> {
  private permissionsSubject = new BehaviorSubject<Set<Permission>>(new Set<Permission>());

  constructor(state: PermissionsState, api: PermissionsApiService) {
    super(state, api);
    new ReduxStoreObserver(reduxStoreContainer.reduxStore)
      .whenFetched$<IPermissionsStoreState>("permissions")
      .pipe(first())
      .subscribe((state) => {
        this.permissionsSubject.next(state.permissions.items);
      });
  }

  public hasPermission$(permission: Permission): Observable<boolean> {
    return this.getPermissions$().pipe(map((permissions) => permissions.has(permission)));
  }

  public hasMultiplePermissions$(permissionsToCheck: Permission[]): Observable<boolean> {
    return this.getPermissions$().pipe(
      map((permissions) => {
        return permissionsToCheck.every((permission) => permissions.has(permission));
      })
    );
  }

  public getPermissions$(): Observable<Set<Permission>> {
    return this.permissionsSubject.asObservable();
  }

  public getCurrentUserAllowedActionsPerItem$(params: { targetId: string; targetType: string }): Observable<IUserItemAllowedActions> {
    return this.get$<IUserItemAllowedActions>(null, {
      ...new RequestConfig(),
      url: this.apiService.getCurrentUserAllowedActionsPerItemEndpoint(params.targetId),
      queryParams: { targetType: params.targetType },
    });
  }

  public getUsersWithAllowedActionPerItem$(params: {
    targetType: "goal" | "metric";
    targetId: string;
    action: keyof IAllowedActionsMap;
  }): Observable<IUsersWithAllowedActionPerItem> {
    return this.get$<IUsersWithAllowedActionPerItem>(null, {
      ...new RequestConfig(),
      url: this.apiService.getUsersWithAllowedActionPerItemEndpoint(params),
      queryParams: { action: params.action },
    });
  }

  public getDefaultAccessByType$(itemType: ItemWithAccessType): Observable<IAccess> {
    return this.get$<IAccess>(null, {
      ...new RequestConfig(),
      url: this.apiService.getDefaultAccessByTypeEndpoint(itemType),
    });
  }

  public getDefaultAccessByTypeAndId$(itemType: ItemWithAccessType, itemId: string): Observable<IAccess> {
    return this.get$<IAccess>(null, {
      ...new RequestConfig(),
      url: this.apiService.getDefaultAccessByTypeAndIdEndpoint(itemType, itemId),
    });
  }

  public getPermissionActionsV2$(): Observable<PermissionActionDTO[]> {
    return this.get$<ICollectionLike<PermissionActionDTO>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getPermissionActionsV2Endpoint(),
    }).pipe(map((result) => result.items));
  }

  public getPermissionsByPrincipal$(principalId: string): Observable<Permission[]> {
    return this.get$<Permission[]>(null, {
      ...new RequestConfig(),
      url: this.apiService.getPermissionsByPrincipalEndpoint(principalId),
    }).pipe(map((permissions) => permissions || []));
  }

  public getAvailableProducts$(): Observable<QuantiveProduct[]> {
    return this.get$<QuantiveProduct[]>(null, {
      ...new RequestConfig(),
      url: this.apiService.getAvailableProductsEndpoint(),
    });
  }

  public updatePermissionsByPrincipal$(principalId: string, allowedActions: (PermissionActionVM["name"] | "ManageAnnouncements")[]): Observable<void> {
    return this.put$<void>(null, allowedActions, {
      ...new RequestConfig(),
      url: this.apiService.updatePermissionsByPrincipalEndpoint(principalId),
    });
  }

  public getAllowedActionsForTargetType$(targetIds: string[], targetType: "session"): Observable<Record<string, UserAction[]>> {
    // copied from - webapp/packages/webapp-legacy/src/users/permissions-service.getCurrentUserAllowedActionsForItems
    if (!targetIds?.length) {
      return of({});
    }

    const maxIdsPerRequest = 100;
    const batchCount = Math.ceil(targetIds.length / maxIdsPerRequest);
    const requests: Observable<Record<string, UserAction[]>>[] = [];

    for (let i = 0; i < batchCount; i++) {
      const batchIds = targetIds.slice(i * maxIdsPerRequest, (i + 1) * maxIdsPerRequest);

      // weird, but api expects in format: /allowed-actions?targetIds=1&targetIds=2&targetIds=3 ..etc
      const queryParams: QueryParams = batchIds.reduce(
        (acc, id) => ({
          ...acc,
          targetIds: id,
        }),
        {}
      );

      requests.push(
        this.get$(null, {
          ...new RequestConfig(),
          url: this.apiService.getAllowedActionsForTargetType(targetType),
          queryParams,
        })
      );
    }

    return forkJoin(requests).pipe(
      map((responses) =>
        responses.reduce(
          (merged, response) => ({
            ...merged,
            ...response,
          }),
          {}
        )
      )
    );
  }
}
