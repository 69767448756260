<input
  class="ant-select-selection-search-input"
  #inputElement
  [attr.aria-activedescendant]="nzOpen ? activeDescendant : ''"
  [attr.aria-controls]="nzOpen ? listboxId : null"
  [attr.aria-expanded]="nzOpen"
  [attr.aria-haspopup]="popUpType || 'listbox'"
  [attr.aria-keyshortcuts]="'Enter'"
  [attr.aria-label]="a11yLabel"
  [attr.aria-labelledby]="a11yLabelledby"
  [attr.aria-owns]="nzOpen ? listboxId : null"
  [attr.aria-required]="a11yRequired"
  [attr.autofocus]="autofocus ? 'autofocus' : null"
  [attr.id]="nzId"
  [attr.placeholder]="placeholder"
  [attr.tabindex]="selectSearchInputTabIndex"
  [disabled]="disabled"
  [ngModel]="value"
  [readonly]="readonly || !showInput"
  [style.opacity]="showInput ? null : 0"
  (compositionend)="setCompositionState(false)"
  (compositionstart)="setCompositionState(true)"
  (ngModelChange)="onValueChange($event)"
  aria-autocomplete="list"
  autocomplete="off"
  role="combobox" />
<!-- The div below plays the role of aria-description since it still doesn't have full support for macOs and safari -->
<!-- Once we have full support we can simply remove it and attach back [attr.aria-description]="a11yDescription" to the input element -->
<div class="select-a11y-description" [attr.id]="nzId ? nzId + '-description' : null">{{ a11yDescription }}</div>
<span class="ant-select-selection-search-mirror" #mirrorElement *ngIf="mirrorSync"></span>
