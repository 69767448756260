import { StateService } from "@uirouter/angular";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { take } from "rxjs";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ClipboardService } from "@webapp/core/util/services/clipboard.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { NavigationEvents } from "@webapp/navigation/components/navigation/navigation.events";
import { INavItem, NavItemsContextMenuConfig } from "@webapp/navigation/models/nav-items-list.models";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { ItemState, getItemsScreenMap } from "../../config";

@Component({
  selector: "nav-list-item-context-menu",
  templateUrl: "./nav-list-item-context-menu.component.html",
  styleUrls: ["./nav-list-item-context-menu.component.less"],
})
export class NavListItemContextMenuComponent implements OnInit {
  @Output()
  public readonly menuToggled = new EventEmitter<boolean>();
  @Output()
  public readonly removeFromSuggested = new EventEmitter<INavItem>();
  @Output()
  public readonly markItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly unmarkItemAsFavorite = new EventEmitter<INavItem>();
  @Input()
  public config: NavItemsContextMenuConfig;
  @Input()
  public item: INavItem;
  @Input()
  public isNextNavActive = false;
  @Input()
  public isMenuOpened = false;

  @Input()
  public canMoveUp: boolean;

  @Input()
  public canMoveDown: boolean;

  @Output()
  public readonly moveUp = new EventEmitter<INavItem>();

  @Output()
  public readonly moveDown = new EventEmitter<INavItem>();

  public contextMenuItems: DropdownMenuItem[] = [];

  private isOkrGridEnabled: boolean = false;

  constructor(
    private stateService: StateService,
    private clipboardService: ClipboardService,
    private broadCastService: BroadcastService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.OkrGrid)
      .pipe(take(1))
      .subscribe((isOkrGridEnabled) => (this.isOkrGridEnabled = isOkrGridEnabled));

    this.contextMenuItems = this.getMenuItems();
  }

  public onMenuToggled(isOpen: boolean): void {
    if (isOpen) {
      this.contextMenuItems = this.getMenuItems();
    }

    this.menuToggled.emit(isOpen);
  }

  private getMenuItems(): DropdownMenuItem[] {
    const options = this.getOptions({
      favoritesAreEndOfSection: this.config.itemsGroupType === "suggested" || this.config.itemsGroupType === "favorites",
      shareLinkIsEndOfSection: this.config.itemsGroupType !== "taskgroups",
    });

    const items: DropdownMenuItem[] = [options.openInNewTab, ...(this.config.itemsGroupType !== "taskgroups" ? [options.shareLink] : [])];

    if (this.config.itemsGroupType !== "taskgroups") {
      if (this.config.itemsGroupType === "favorites" || this.item.favoriteId) {
        items.push(options.removeFromFavorites);
      } else {
        items.push(options.addToFavorites);
      }
    }

    if (this.config.itemsGroupType === "suggested") {
      items.push(options.removeFromSuggested);
    }

    if (this.config.itemsGroupType === "favorites") {
      items.push(options.moveUp, options.moveDown);
    }

    return items;
  }

  private getOptions(options: {
    favoritesAreEndOfSection: boolean;
    shareLinkIsEndOfSection: boolean;
  }): Record<"openInNewTab" | "shareLink" | "removeFromFavorites" | "addToFavorites" | "removeFromSuggested" | "moveUp" | "moveDown", DropdownMenuItem> {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const openInNewTab = menuItemBuilder
      .setKey("open_in_new_tab")
      .setUiType({ uiType: "icon", icon: "external-link" })
      .setAction({
        handler: () => this.openInNewTab(),
      })
      .build();

    const shareLink = menuItemBuilder
      .setKey("share_link")
      .setUiType({ uiType: "icon", icon: "link" })
      .setAction({
        handler: () => this.shareLink(),
      })
      .setEndOfSection(options.shareLinkIsEndOfSection)
      .build();

    const removeFromFavorites = menuItemBuilder
      .setKey("remove_from_favorites")
      .setUiType({ uiType: "uiIcon", iconType: "favorite", iconTheme: "fill" })
      .setAction({
        handler: () => {
          this.removeFromFavorites();
          this.broadCastService.emit(NavigationEvents.SUB_NAVIGATION_ITEM_REMOVED_FROM_FAVORITES, this.item);
        },
      })
      .setTrack("New Experience Remove from Favorites")
      .setTrackMetaData({ id: this.item.id, type: this.item.uxcType, source: `sub-nav-${this.config.itemsGroupType}` })
      .setEndOfSection(options.favoritesAreEndOfSection)
      .build();

    const addToFavorites = menuItemBuilder
      .setKey("add_to_favorites")
      .setUiType({ uiType: "uiIcon", iconType: "favorite", iconTheme: "outline" })
      .setAction({
        handler: () => {
          this.addToFavorites();
          this.broadCastService.emit(NavigationEvents.SUB_NAVIGATION_ITEM_ADDED_TO_FAVORITES, this.item);
        },
      })
      .setTrack("New Experience Add to Favorites")
      .setTrackMetaData({ id: this.item.id, type: this.item.uxcType, source: `sub-nav-${this.config.itemsGroupType}` })
      .setEndOfSection(options.favoritesAreEndOfSection)
      .build();

    const removeFromSuggested = menuItemBuilder
      .setKey("remove_from_suggested")
      .setUiType({ uiType: "uiIcon", iconType: "trash-bin", iconTheme: "outline" })
      .setAction({
        handler: () => this.removeItemFromSuggested(),
      })
      .setTrack("Remove from suggested")
      .setTrackMetaData({ id: this.item.id, type: this.item.uxcType, source: `sub-nav-${this.config.itemsGroupType}` })
      .setToBeDestructive()
      .build();

    const moveUp = menuItemBuilder
      .setKey("move_up")
      .setUiType({ uiType: "uiIcon", iconType: "up", iconTheme: "outline" })
      .setDisabledState(!this.canMoveUp)
      .setAction({
        handler: () => this.moveUp.emit(this.item),
      })
      .setTrack("Move favorite item up")
      .setTrackMetaData({ id: this.item.id, type: this.item.uxcType, source: `sub-nav-${this.config.itemsGroupType}` })
      .build();

    const moveDown = menuItemBuilder
      .setKey("move_down")
      .setUiType({ uiType: "uiIcon", iconType: "down", iconTheme: "outline" })
      .setDisabledState(!this.canMoveDown)
      .setAction({
        handler: () => this.moveDown.emit(this.item),
      })
      .setTrack("Move favorite item down")
      .setTrackMetaData({ id: this.item.id, type: this.item.uxcType, source: `sub-nav-${this.config.itemsGroupType}` })
      .build();

    return { openInNewTab, shareLink, removeFromFavorites, addToFavorites, removeFromSuggested, moveUp, moveDown };
  }

  private getStateAndScreenKey(item: INavItem): { itemState: ItemState } {
    // in the case where we have a heterogenic container of navitems, we need to find the proper subType and use its state definition to navigate
    const itemScreen = getItemsScreenMap({ [FeatureFlag.OkrGrid]: this.isOkrGridEnabled })[this.config.itemsType];
    let itemState = itemScreen.itemState;
    if (!itemState && itemScreen.subTypes) {
      itemState = itemScreen.subTypes[item.uxcType].itemState;
    }
    return { itemState };
  }

  private getLinkUrl(item: INavItem): string {
    const { itemState } = this.getStateAndScreenKey(item);
    if (itemState) {
      const params = itemState.paramsMap ? { [itemState.paramsMap.id]: item.id } : {};
      return this.stateService.href(itemState.name, params, { absolute: true });
    } else {
      return "";
    }
  }

  private openInNewTab(): void {
    const url = this.getLinkUrl(this.item);
    window.open(url, "_blank");
  }

  private shareLink(): void {
    if (this.config.broadcastConfig.shareLinkToNavItem?.(this.item.id)) {
      this.broadCastService.emit(NavigationEvents.SUB_NAVIGATION_ITEM_SHAREABLE_LINK_COPIED_TO_CLIPBOARD, this.item);
      return;
    }

    this.clipboardService.copyToClipboard(this.getLinkUrl(this.item));
  }

  private removeFromFavorites(): void {
    this.unmarkItemAsFavorite.emit(this.item);
  }

  private addToFavorites(): void {
    this.markItemAsFavorite.emit(this.item);
  }

  private removeItemFromSuggested(): void {
    this.removeFromSuggested.emit(this.item);
  }
}
