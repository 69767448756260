<button
  class="toggle-nav-btn"
  [attr.aria-label]="'toggle_subnavigation_heading' | localize: { activeScreen: activeScreen | localize }"
  [id]="uiId"
  [uiTooltipTitle]="tooltipTitle"
  (click)="expandNav(action === 'expand')"
  (focus)="onFocus()"
  tooltipPosition="bottomLeft"
  ui-tooltip>
  <i class="icon" *ngIf="action === 'collapse'" [attr.data-test-id]="'sub-nav-opening-arrow'" ui-icon uiTheme="outline" uiType="collapse-nav"></i>
  <i class="icon" *ngIf="action === 'expand'" [attr.data-test-id]="'sub-nav-opening-arrow'" ui-icon uiTheme="outline" uiType="parent-view"></i>
</button>
