import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, map, tap, zip } from "rxjs";
import { IReport } from "@gtmhub/reporting/models";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { InsightboardRepositoryService } from "@webapp/insightboards/services/insightboard/insightboard-repository.service";
import { hasAccessToSpecialReports } from "@webapp/navigation/components/navigation/sub-navigation/reports-sub-navigation/reports-sub-navigation.util";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import { NavigationReportsCacheService } from "@webapp/navigation/services/navigation-reports-cache.service";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { BaseSubNav } from "../services/base-sub-nav";

@UntilDestroy()
@Component({
  selector: "reports-sub-navigation",
  templateUrl: "./reports-sub-navigation.component.html",
})
export class ReportsSubNavigationComponent extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [{ label: "all_reports", state: "gtmhub.lists" }];
  public customReportOptions: SubNavOption[] = [];
  public hideAllReports$: Observable<boolean>;
  public showInsightReports$: Observable<boolean>;

  constructor(
    private navigationReportsCache: NavigationReportsCacheService,
    private insightboardRepositoryService: InsightboardRepositoryService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private stateService: StateService,
    private routerGlobals: UIRouterGlobals,
    public permissionsFacade: PermissionsFacade,
    protected navigationItemsMediator: NavigationItemsMediator,
    private editionFeatureService: EditionFeatureService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setInitialValues();

    this.showInsightReports$ = zip(
      this.permissionsFacade.hasPermission$("ManageData"),
      this.permissionsFacade.hasPermission$("AccessReportsAndDataSourceFilters"),
      this.permissionsFacade.hasPermission$("AccessInsightboards"),
      this.editionFeatureService.hasFeature$("setup.datasources")
    )
      .pipe(
        map(([canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable]) => {
          return hasAccessToSpecialReports({ canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable });
        })
      )
      .pipe(
        tap((willShowReports) => {
          const currentState = this.routerGlobals.current.name;

          if ((!willShowReports && currentState === "gtmhub.insightboard") || currentState.startsWith("gtmhub.insightboard.")) {
            this.stateService.go("gtmhub.lists");
          }
        })
      );

    this.hideAllReports$ = combineLatest([
      this.editionFeatureService.hasFeature$("customizable-report"),
      this.featureTogglesFacade.isFeatureAvailable$("hide-lists"),
    ]).pipe(
      map(([hasCustomizableReportFeature, hideListsFeatureFlag]) => {
        return !hasCustomizableReportFeature || hideListsFeatureFlag;
      }),
      untilDestroyed(this)
    );

    this.hideAllReports$.subscribe((enabled) => {
      if (!enabled) {
        super.ngOnInit();
      }
    });
  }

  private setInitialValues(): void {
    const performanceReportId = this.getPerformanceReportId();
    const processReportId = this.getProcessReportId();
    if (performanceReportId && processReportId) {
      this.customReportOptions.push(this.buildPerformanceReportOption(performanceReportId));
      this.customReportOptions.push(this.buildProcessReportOption(processReportId));
    } else {
      this.insightboardRepositoryService.getReports$().subscribe((reports: IReport[]) => {
        this.navigationReportsCache.set(reports);
        const performanceReport = reports.find((report) => report.name.toLowerCase() === "performance report");
        const processReport = reports.find((report) => report.name.toLowerCase() === "process report");
        this.customReportOptions.push(this.buildPerformanceReportOption(performanceReport.id));
        this.customReportOptions.push(this.buildProcessReportOption(processReport.id));
      });
    }
    this.setupEntityTypes(["list"], "lists");
  }

  private getReportId(reportName: string): string {
    if (!this.navigationReportsCache.get()) return null;
    const performanceReport = this.navigationReportsCache.get().find((report) => report.name.toLowerCase() === reportName);
    if (!performanceReport) return null;
    return performanceReport.id || null;
  }

  private getPerformanceReportId(): string {
    return this.getReportId("performance report");
  }

  private getProcessReportId(): string {
    return this.getReportId("process report");
  }
  private buildPerformanceReportOption(reportId: string): SubNavOption {
    return { label: "performance_report", state: "gtmhub.insightboard", icon: "performance", stateOptions: { dashboardId: reportId } };
  }

  private buildProcessReportOption(reportId: string): SubNavOption {
    return { label: "process_report", state: "gtmhub.insightboard", icon: "process", stateOptions: { dashboardId: reportId } };
  }
}
