<div class="sp-py-6 sp-px-10 suggestion-card" (mouseenter)="showActionButtons = true" (mouseleave)="showActionButtons = false">
  <div class="suggestion-card-content" ui-row uiAlign="top">
    <div class="sp-pt-7 sp-pb-5" *ngIf="isSuggestionIconAvailable" ui-col>
      <i class="fs-16px suggestion-icon" ui-icon uiType="artificial-intelligence"></i>
    </div>
    <div class="sp-py-2 tag-items-content" ui-col uiFlex="1">
      <div class="tag-items" ui-row>
        <div class="sp-my-2 tag-item" *ngFor="let tag of suggestion">
          <span>{{ tag.title }}</span>
        </div>
      </div>
    </div>
    <div [uiFlex]="isRemoveButtonAvailable ? '104px' : '68px'" ui-col>
      <div *ngIf="showActionButtons" ui-row uiAlign="middle" uiJustify="end">
        <button
          class="sp-p-6 suggestion-card-action"
          [uiTooltipTitle]="'suggest_another' | localize"
          (click)="onSuggestAnotherClicked()"
          data-test-id="suggestion-refresh-button"
          ui-button
          ui-tooltip
          uiSize="default"
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight">
          <i class="fs-16px" ui-icon uiType="suggestion-card-refresh"></i>
        </button>
        <button
          class="sp-ml-4 sp-p-6 suggestion-card-action"
          *ngIf="isRemoveButtonAvailable"
          [uiTooltipTitle]="'remove_suggestion' | localize"
          (click)="onRemoveSuggestionClicked()"
          data-test-id="suggestion-decline-button"
          ui-button
          ui-tooltip
          uiSize="default"
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight">
          <i class="text-color-gray-6 fs-16px" ui-icon uiType="suggestion-card-remove"></i>
        </button>
        <button
          class="sp-ml-4"
          [uiTooltipTitle]="'add_this_suggestion' | localize"
          (mousedown)="onAddSuggestionClicked()"
          data-test-id="suggestion-accept-button"
          ui-button
          ui-tooltip
          uiShape="circle"
          uiSize="default"
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight"
          uiType="primary">
          <i class="text-color-gray-1 fs-16px add-suggestion-icon" ui-icon uiType="plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
