<div
  class="ant-modal"
  #modalElement
  [class.content-position-centered]="customConfig.nzModalContentCentered"
  [class.content-position-default]="!customConfig.nzModalContentCentered"
  [ngClass]="config.nzClassName!"
  [ngStyle]="config.nzStyle!"
  [style.min-width]="config.nzWidth! | nzToCssUnit"
  role="document">
  <div class="ant-modal-content" [style.width]="config.nzWidth! | nzToCssUnit">
    <div class="header-wrapper" *ngIf="config.nzClosable || config.nzTitle">
      <button *ngIf="config.nzClosable" [attr.aria-label]="'Modal.closeText' | uiI18n" [title]="config.nzTitle ? config.nzTitle : ''" (click)="onCloseClick()" ui-modal-close></button>
      <div #modalTitle *ngIf="config.nzTitle" [attr.id]="modalTitleId" [uiTitle]="config.nzTitle" ui-modal-title></div>
      <div *ngIf="customConfig.nzDescription" [attr.id]="modalDescriptionId" [uiDescription]="customConfig.nzDescription" ui-modal-description></div>
      <div class="ui-modal-option-button" *ngIf="customConfig.nzModalOptionsButton" [uiOptionsButtonContent]="customConfig.nzModalOptionsButton" ui-options-button></div>
    </div>
    <div class="ant-modal-body" [ngStyle]="config.nzBodyStyle!">
      <div class="body-wrapper" [attr.aria-labelledby]="modalDescriptionId" [ui-scrollable-content]="hasScrollableContent" tabindex="-1">
        <ng-template cdkPortalOutlet></ng-template>
        <div *ngIf="isStringContent" [innerHTML]="config.nzContent"></div>
      </div>
    </div>
    <ui-alert *ngIf="customConfig.nzError" [uiCloseable]="true" [uiDescription]="customConfig.nzError" (uiOnClose)="customConfig.nzError = undefined" uiType="error" />
    <ui-loading-indicator *ngIf="customConfig.nzLoadingIndicator" uiType="linear" />
    <div #modalFooter *ngIf="config.nzFooter !== null" [modalRef]="modalRef" (cancelTriggered)="onCloseClick()" (okTriggered)="onOkClick()" ui-modal-footer></div>
  </div>
  <div class="ant-modal-side-content" *ngIf="customConfig.nzModalSideContent" [uiSideDrawer]="customConfig.nzModalSideContent" ui-modal-side-content></div>
</div>
