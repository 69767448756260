<ui-assignee-avatar [borderColor]="assignee.value" [fromAssigneeId]="assignee.key" [hasBorder]="true" [uiShowTooltip]="true" *ngFor="let assignee of visibleAssignees | keyvalue" uiSize="md" />

<ui-avatar
  class="view-more-avatars-element"
  [hasBorder]="true"
  [uiBackdrop]="true"
  [uiContent]="hiddenAvatarsTemplate"
  [uiOverlayStyle]="{ 'margin-right': '-8px', 'margin-top': '2px' }"
  [uiText]="'+' + hiddenAssignees.size"
  *ngIf="hiddenAssignees.size > 0"
  ui-popover
  uiPlacement="bottomRight"
  uiSize="md"
  uiTrigger="click" />

<ng-template #hiddenAvatarsTemplate>
  <div class="view-more-avatars-wrapper" [class.scroll]="hiddenAssignees.size >= 10">
    <div class="view-more-avatars-row" *ngFor="let assignee of hiddenAssignees | keyvalue">
      <ui-assignee-avatar class="sp-mr-6" [borderColor]="assignee.value" [fromAssigneeId]="assignee.key" [hasBorder]="true" uiSize="md" />
      <ui-assignee-name [fromAssigneeId]="assignee.key" />
    </div>
  </div>
</ng-template>
