<div class="content-holder">
  <div class="back-button-holder sp-mb-32">
    <button class="back-btn" (click)="goBack()" ui-button uiType="link">
      <i class="fs-18px sp-mr-10" ui-icon uiType="back"></i><span class="fs-13px">{{ "back" | localize }}</span>
    </button>
  </div>
  <gh-indicator [(ghValue)]="dataLoadingIndicator" [ghMessage]="'loading' | localize"></gh-indicator>
  <div class="title-holder" *ngIf="!dataLoadingIndicator">
    <img class="logo sp-mr-16" alt="Power BI logo" src="/dist/img/logos/powerbi.png" />
    <h2 class="fs-30px">{{ connectionData.title }}</h2>
  </div>
  <div class="insight-title-holder" *ngIf="daxQueryResult.insight && daxQueryResult.insight.title">
    <p class="fs-14px sp-mb-0">{{ daxQueryResult.insight.title }}</p>
  </div>
  <div class="result-holder text-color-gray-12" *ngIf="!dataLoadingIndicator">
    <p class="fs-20px sp-mb-0">
      {{ "result_is" | localize }}<span class="fs-25px sp-ml-16">{{ daxQueryResult.scalarResult }}</span>
    </p>
  </div>
  <div class="action-holder" *ngIf="!dataLoadingIndicator">
    <button class="confirm-btn" (click)="onConfirm()" ui-button uiShape="round" uiType="primary">{{ "looks_correct" | localize }}</button>
  </div>
</div>
