<button
  [attr.aria-expanded]="visible ? true : null"
  [attr.aria-haspopup]="true"
  [attr.aria-label]="'rearrange' | localize"
  [uiDropdownMenu]="menu"
  [uiVisible]="visible"
  (keydown)="onKeyDown($event)"
  (uiVisibleChange)="handleVisibleChange($event)"
  ui-button
  ui-dropdown
  uiPlacement="bottomRight"
  uiShape="round"
  uiTrigger="click">
  <i ui-icon uiType="menu-horizontal"></i>
</button>
<ui-dropdown-menu #menu="uiDropdownMenu">
  <ul role="menu" ui-menu>
    <li class="widget-action-item-header" *ngIf="canToggleFullWidth" role="menuitem" ui-menu-item>
      <span class="dropdown-row-item">
        <span class="dropdown-label-text" id="widgetActionsFullWidth">{{ "full_width" | localize }}</span>
        <ui-toggle
          [ghTrackMeta]="{ name: 'home_widget_full_width', entity: widgetId, state: isFullWidth, user_id: currentUserId }"
          [value]="isFullWidth"
          (toggled)="toggleFullWidth()"
          ghTrack="Toggle Set"
          labelledby="widgetActionsFullWidth"
          uiType="toggle-text"></ui-toggle>
      </span>
    </li>
    <li
      *ngFor="let menuItem of textMenuItems"
      [attr.aria-disabled]="menuItem.disabled ? 'true' : null"
      [hotkey]="visible ? menuItem.hotkey : null"
      [tabindex]="menuItem.disabled ? '-1' : '0'"
      [uiDisabled]="menuItem.disabled"
      (click)="menuItem.click()"
      role="menuitem"
      ui-menu-item>
      <span class="dropdown-row-item">
        <i [uiType]="menuItem.iconType" ui-icon></i>
        <span class="dropdown-label-text">{{ menuItem.titleKey | localize }}</span>
        <human-hotkey [combo]="menuItem.hotkey" />
      </span>
    </li>
    <li class="widget-action-item-footer" *ngIf="canRemove" (click)="remove.emit()" role="menuitem" ui-menu-item>
      <span class="dropdown-row-item">
        <i ui-icon uiType="trash-bin"></i>
        <span>{{ "remove" | localize }}</span>
      </span>
    </li>
  </ul>
</ui-dropdown-menu>
