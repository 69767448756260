<div class="top-nav-bar" [class.hide-buttons-and-avatars]="shouldHideButtonsAndAvatars" ui-row uiAlign="middle">
  <div class="top-nav-bar__toggle-nav" *ngIf="!isNavExpanded" ui-col>
    <toggle-nav action="expand" uiId="top-subnav-toggle" />
  </div>

  <div class="top-nav-bar__header-container" ui-col ui-row uiAlign="middle">
    <div class="top-nav-bar__title-container" data-test-id="page-title">
      <ng-container *ngIf="navSubTitle">
        <a
          class="top-nav-bar__subtitle"
          [uiTooltipTitle]="navSubTitle.text"
          (click)="actionHandler()"
          (keyup.enter)="actionHandler()"
          (keyup.space)="actionHandler()"
          data-test-id="top-nav-bar-subtitle"
          tabindex="0"
          tooltipIfOverflow="hover"
          ui-col
          ui-tooltip
          >{{ navSubTitle.text }}</a
        >
        <span class="top-nav-bar__title-divider" aria-hidden="true">/</span>
      </ng-container>
      <h2
        class="top-nav-bar__title"
        [attr.aria-label]="titleAriaLabel"
        [attr.tabindex]="navTitleEditable ? undefined : 0"
        [class.nav-title-read-only-container]="!navTitleEditable"
        [class.title-gray-background]="isGrayBackground"
        [uiTooltipTitle]="navTitle"
        tooltipIfOverflow="hover"
        ui-col
        ui-tooltip>
        @if (navTitleEditable) {
          <ui-input-text-field
            #input
            [a11yRequired]="true"
            [borderless]="true"
            [preventEmpty]="true"
            [uiTooltipTitle]="navTitle"
            [uiTooltipTrigger]="input?.inputField?.nativeElement.scrollWidth > input?.inputField?.nativeElement.clientWidth ? 'hover' : null"
            [value]="navTitle"
            (focusout)="resizeEditableTitleContainerDelayed()"
            (input)="resizeEditableTitleContainer()"
            (valueChange)="navTitleChange.emit($event)"
            e2eTestId="top-nav-bar-title-input"
            ui-tooltip
            uiSize="small"></ui-input-text-field>
          <div class="shadow-nav-title" #shadowNavTitle></div>
        } @else {
          <span class="nav-title-read-only" data-test-id="top-nav-bar-title">
            {{ navTitle }}
          </span>
        }
      </h2>
    </div>
    <ng-content select="[top-nav-bar-favorite]" />
    <ng-content select="[top-nav-bar-dropdown]" />
    <ng-content select="[top-nav-bar-loading-indicator]" />
    <top-nav-bar-tabs class="top-nav-bar__tabs-container" *ngIf="topNavBarTabsConfig" [initialActiveTabKey]="initialActiveTabKey" [isRelaxedMode]="relaxedModes.tabs" [tabs]="topNavBarTabsConfig" />
  </div>

  <div class="top-nav-bar__right-content" ui-col ui-row uiAlign="middle" uiJustify="end">
    <ng-container *ngIf="topNavBarAvatarsConfig">
      <top-nav-bar-avatars class="top-nav-bar__avatars-container" [config]="topNavBarAvatarsConfig" [visibleAvatarsCount]="visibleAvatarsCount" />
      <span class="top-nav-bar__avatars-divider"></span>
    </ng-container>

    <simple-permissions
      class="top-nav-bar__permissions-container sp-ml-12 sp-mr-12"
      *ngIf="simplePermissionsConfig"
      [access]="simplePermissionsConfig.access"
      [compactMode]="!relaxedModes.permissions"
      [isGrayBackground]="simplePermissionsConfig.isGrayBackground"
      [ownerId]="simplePermissionsConfig.ownerId"
      [shareableLink]="simplePermissionsConfig.shareableLink"
      [showPermissions]="simplePermissionsConfig.showPermissions"
      [triggerButtonAriaLabel]="simplePermissionsConfig.triggerButtonAriaLabel"
      [useShareLinkSplitButtonForTheNewGrid]="simplePermissionsConfig.useShareLinkSplitButtonForTheNewGrid"
      (accessChange)="simplePermissionsConfig.accessChangeHandler($event)"
      (toggle)="simplePermissionsConfig.toggleHandler($event)">
      <ng-template #alertTemplate *ngIf="simplePermissionsConfig.useShareLinkSplitButtonForTheNewGrid && simplePermissionsConfig.displayAlertIfGridNotEnabled">
        <ui-alert
          class="top-nav-bar__new-grid-alert-message"
          *ngIf="isAlertVisible"
          [uiCloseable]="true"
          [uiDescription]="'not_having_new_okr_module_warning' | localize"
          (uiOnClose)="closeAlert()"
          uiType="warning" />
      </ng-template>
    </simple-permissions>

    <ng-content select="[top-nav-bar-share-link]" />
    <ng-content select="[top-nav-bar-export-excel]" />

    <top-nav-bar-buttons
      class="top-nav-bar__buttons-container"
      *ngIf="topNavBarButtonsConfig"
      [buttonsConfig]="topNavBarButtonsConfig.buttonsConfig"
      [dropdownConfig]="topNavBarButtonsConfig.dropdownConfig"
      [isRelaxedMode]="relaxedModes.buttons"
      ui-col />

    <global-search class="sp-ml-12" *ngIf="!hideGlobalSearch" [facet]="searchFacet" (globalSearchToggle)="onGlobalSearchToggle($event)" ui-col />
  </div>
</div>
