<ui-card [uiBordered]="false" [uiExtra]="extraHeaderContentTemplate" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [canRemove]="true" [widgetId]="widgetId" (remove)="remove()" />
  </ng-template>

  <div class="boxes-container">
    <div class="boxes">
      <div class="box" *ngFor="let box of boxes" (keydown)="handleBoxKeyDown($event, box.link)" (mousedown)="handleBoxMouseDown($event, box.link)" tabindex="0">
        <i [uiType]="box.icon" ui-icon></i>
        <div class="type">{{ box.type }}</div>
        <div class="title">{{ box.title }}</div>
        <a class="ui-link" [href]="box.link" tabindex="-1" target="_blank" ui-external-link>{{ "learn_more" | localize }}</a>
      </div>
    </div>
  </div>
</ui-card>
