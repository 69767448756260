import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";

@Injectable({ providedIn: "root" })
export class GranularTasksPermissionsService {
  constructor(private permissionsFacade: PermissionsFacadeWithFeatureFlag) {}

  public hasPermissionToView$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "AccessTasks",
      permissionWhenFlagIsOff: true,
    });
  }

  public hasPermissionToOwn$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "tasks:own",
      permissionWhenFlagIsOff: "ManageTasks",
    });
  }

  public hasPermissionToCreate$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "tasks:create",
      permissionWhenFlagIsOff: "ManageTasks",
    });
  }

  public hasPermissionToEdit$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "tasks:edit",
      permissionWhenFlagIsOff: "ManageTasks",
    });
  }

  public hasPermissionToUpdate$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "tasks:update",
      permissionWhenFlagIsOff: "ManageTasks",
    });
  }

  public hasPermissionToDelete$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "tasks:delete",
      permissionWhenFlagIsOff: "ManageTasks",
    });
  }

  private hasPermissionTo$({
    negate,
    permissionWhenFlagIsOn,
    permissionWhenFlagIsOff,
  }: {
    negate: boolean | undefined;
    permissionWhenFlagIsOn: Permission | boolean;
    permissionWhenFlagIsOff: Permission | boolean;
  }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;

    return this.permissionsFacade.hasPermissionWithFeatureFlag$(FeatureFlag.ManageTasksGranularPermissions, permissionWhenFlagIsOn, permissionWhenFlagIsOff).pipe(
      map((hasPermission) => {
        return hasPermission !== shouldNegate;
      })
    );
  }
}
