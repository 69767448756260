<span
  class="current-facet"
  id="facet-menu-button"
  #currentFacet
  [(uiVisible)]="isFacetMenuOpen"
  [attr.aria-expanded]="isFacetMenuOpen"
  [uiDropdownMenu]="menucl"
  (focusout)="facetMenuFocusout.emit($event)"
  (keydown)="openFacetDropdown($event)"
  (uiVisibleChange)="onFacetMenuOpen()"
  aria-controls="menu"
  data-test-id="global-facet-dropdown"
  role="combobox"
  tabindex="0"
  ui-dropdown
  uiPlacement="bottomRight"
  uiTrigger="click">
  {{ currentFacet$ | async | localize }}
  <i ui-icon uiType="chevron-down-small"></i>
</span>
<ui-dropdown-menu #menucl="uiDropdownMenu">
  <ul class="facet-option-menu" #facetMenu data-test-id="global-facet-dropdown-menu" style="min-width: 140px" ui-menu>
    <li
      *ngFor="let facet of facetOptions$ | async | filterFacetOptions: facetsToHide"
      [attr.aria-selected]="(currentFacet$ | async) === facet"
      (click)="changeFacet(facet)"
      (keydown.arrowDown)="moveFocusTo('next')"
      (keydown.arrowUp)="moveFocusTo('prev')"
      (keydown.enter)="changeFacet(facet)"
      (keydown.esc)="focusCurrentFacet()"
      (keydown.space)="changeFacet(facet)"
      role="option"
      tabindex="0">
      <span class="facet-option">{{ facet | localize }}</span>
    </li>
  </ul>
</ui-dropdown-menu>
