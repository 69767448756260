import { IPromise, IRootScopeService } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/angularjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { ChargebeeService } from "@gtmhub/configuration/services/chargebee-service";
import { AccountType } from "@gtmhub/core";
import { IStateInit } from "@gtmhub/core/routing";
import { EditionPlanSelectorService, IPlan, PlanName, getPlanLabel } from "@gtmhub/edition-plan-change";
import { PricingEditionService } from "@gtmhub/edition-plan-change/services/pricing-edition.service";
import { IIndicator, UIErrorHandlingService } from "@gtmhub/error-handling";
import { AccountResolverService } from "@webapp/accounts";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { removeFullNavSkeleton } from "@webapp/shared/skeleton/skeleton.utils";
import { IAccountSubscriptionInfo } from "../models";

export interface IAccountSuspendedScope extends IModalScope {
  openChargebeeBillingPortal(): void;
  indicators: {
    loading?: IIndicator;
    getBillingToken?: IIndicator;
  };
  mode: string;
  noManageBilling: boolean;
  currentPlan: IPlan;
  currentPlanName: PlanName;
  subscriptionInfo: IAccountSubscriptionInfo;
  logout(): void;
  openChoosePlanModal(): void;
}

export class AccountSuspendedCtrl implements IStateInit {
  private accountType: AccountType;

  public static $inject = [
    "$scope",
    "$state",
    "AnalyticsService",
    "AuthenticationResolverService",
    "AccountService",
    "ChargebeeService",
    "AccountResolverService",
    "EditionPlanSelectorService",
    "UIErrorHandlingService",
    "PricingEditionService",
    "$rootScope",
  ];

  constructor(
    private $scope: IAccountSuspendedScope,
    private $state: StateService,
    private analyticsService: AnalyticsService,
    private authenticationResolverService: AuthenticationResolverService,
    private accountService: AccountService,
    private chargebeeService: ChargebeeService,
    private accountResolverService: AccountResolverService,
    private editionPlanSelectorService: EditionPlanSelectorService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private pricingEditionService: PricingEditionService,
    private $rootScope: IRootScopeService
  ) {
    this.$scope.indicators = { loading: { progress: true } };
    this.$scope.noManageBilling = false;

    this.$scope.logout = this.logout;
    this.$scope.openChargebeeBillingPortal = this.openChargebeeBillingPortal;
    this.$scope.openChoosePlanModal = this.openChoosePlanModal;
  }

  stateInit(): IPromise<unknown> {
    return this.init();
  }

  private init = (): IPromise<unknown> => {
    const account = this.accountResolverService.getAccountData();
    if (!account) {
      const accountResolvingState = this.accountResolverService.prepareForResolveAccount();
      this.$state.go(accountResolvingState.name());
    } else {
      this.accountType = account.type;
      return this.checkStatus().then(() => {
        removeFullNavSkeleton({ force: true });

        this.$rootScope.$broadcast("accountDataInitialized", account);
        return this.determineMode();
      });
    }
  };

  private onPlanChosen = (plan: IPlan): void => {
    this.$scope.currentPlan = plan;
    this.$scope.currentPlanName = getPlanLabel(this.$scope.currentPlan.id).name;

    this.$scope.subscriptionInfo.plan_unit_price = this.$scope.currentPlan.price * 100;
    if (this.$scope.currentPlan.billingPeriod === "annual") {
      this.$scope.subscriptionInfo.plan_unit_price *= 12;
    }
  };

  private determineMode = (): IPromise<unknown> => {
    const params = new URLSearchParams(window.location.search);

    if (params.get("state") === "succeeded" && params.has("id")) {
      delete this.$scope.indicators.loading;
      this.startVerifying();
    } else {
      return this.requestPayment();
    }
  };

  private requestPayment = (): IPromise<unknown> => {
    this.$scope.mode = "payment";

    const showNoManageBilling = () => {
      this.$scope.noManageBilling = true;
      delete this.$scope.indicators.loading;
    };

    return this.pricingEditionService.getAllowedTransitions(this.accountType, { skipPermissionCheck: true }).then(
      (plans) => {
        this.$scope.currentPlan = plans.find((plan) => plan.isSelected);

        if (this.$scope.currentPlan) {
          this.$scope.currentPlanName = getPlanLabel(this.$scope.currentPlan.id).name;
        }

        return this.accountService.getAccountSubscriptionInfo().then(
          (subscriptionInfo) => {
            this.$scope.subscriptionInfo = subscriptionInfo;
            delete this.$scope.indicators.loading;

            this.onPlanChosen(this.$scope.currentPlan);
          },
          () => showNoManageBilling()
        );
      },
      () => showNoManageBilling()
    );
  };

  private openChoosePlanModal = () => {
    const currentPlanId = this.$scope.currentPlan?.id || "";
    this.analyticsService.track("Current Plan Switched");
    this.editionPlanSelectorService
      .selectEditionPlan({
        isAccountActive: false,
        currentlySelectedPlanId: currentPlanId,
      })
      .then((selectedPlan) => {
        this.onPlanChosen(selectedPlan);
      });
  };

  private openChargebeeBillingPortal = () => {
    if (this.$scope.noManageBilling || !this.$scope.currentPlan) {
      return;
    }

    this.$scope.indicators.getBillingToken = { progress: true };
    this.accountService
      .getCheckoutPage({
        billingPeriod: this.$scope.currentPlan.billingPeriod,
        planGroupName: this.$scope.currentPlan.planGroupName,
        edition: this.$scope.currentPlan.editionName,
      })
      .then(
        (checkoutPageData) => {
          delete this.$scope.indicators.getBillingToken;
          this.chargebeeService.openCbCheckout(checkoutPageData.url);
        },
        (error) => (this.$scope.indicators.getBillingToken = { error })
      );
  };

  private startVerifying = () => {
    this.$scope.mode = "verifying";
    setInterval(() => {
      this.checkStatus();
    }, 4000);
  };

  private checkStatus = () => {
    return this.accountService.checkIfActive().then(
      (isAccountActive) => {
        if (isAccountActive) {
          window.location.href = "/";
        }
      },
      (err) => this.uiErrorHandlingService.handleModal(err)
    );
  };

  private logout = (): void => {
    this.authenticationResolverService.logout({ ssoLogoutIfEnabled: false });
  };
}
